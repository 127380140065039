

export default class WebUserClient {

    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    async ListOfCop(accessToken) {
        let url = this.baseUrl + 'ListOfCop';
        let data = {};
        if (accessToken) {
            data = {
                method: 'GET',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            };
        }
        else {
            data = {
                method: 'GET',
                mode: 'cors'
            };
        }

        try {
            const response = await fetch(url, data);
            if (response.ok) {
                const jsonListOfCop = await response.text();
                return jsonListOfCop;
            } else {
                throw response;
            }
        } catch (e) {
            console.log('ERROR: ' + e);
        }

        return [
            {
                Code: "code",
                DisplayName: "displayname",
                Description: "description"
            },
        ];
    }
}