import React, { useMemo } from 'react';
import HistoryTable from '../HistoryTable/HistoryTable';
import HistoryTableSymbol from '../HistoryTableSymbol/HistoryTableSymbol';
import Alert from 'react-bootstrap/Alert';
import copyTextToClipboard from '../..//Libraries/Utilities/Clipboard';

const FxLibElements = (props) => {
    const { filteredData } = props;
    
    const handleSymbolClicked = (text) => { copyTextToClipboard(text); }

    const columns = useMemo(
        () => [
            {
                // first group - Element definition
                Header: "Elements",
                // First group columns
                columns: [
                    {
                        Header: "Symbol",
                        accessor: "element.symbol",
                        Cell: ({ cell: { value } }) => <HistoryTableSymbol onSymbolClicked={handleSymbolClicked} key={value} symbol={value} />
                    },
                    {
                        Header: "Description",
                        accessor: "element.description"
                    }
                ]
            },
            {
                // Second group - 2017 Meta data
                Header: "CoP 2017 - 2021",
                // Second group columns
                columns: [
                    {
                        Header: "Number",
                        accessor: "cop2017.number"
                    },
                    {
                        Header: "DV",
                        accessor: "cop2017.difficultyValue"
                    },
                ]
            },
            {
                // Third group - 2022 Meta data
                Header: "CoP 2022 - 2024",
                // Third group columns
                columns: [
                    {
                        Header: "Number",
                        accessor: "cop2022.number"
                    },
                    {
                        Header: "DV",
                        accessor: "cop2022.difficultyValue"
                    },
                    {
                        Header: "Changes",
                        accessor: "cop2022.changes"
                    },
                ]
            }
        ],
        []
    );

    return (
        <>
            <Alert variant="primary">Click the symbol to copy it to the clipboard for use in your own documents. Make sure you have downloaded and installed the latest versions of the WAG fonts.</Alert>
            <HistoryTable columns={columns} data={filteredData} />
        </>
    );
}

export default FxLibElements;