import './WagCanvasFx.css';
import React, { useRef, useEffect, useState } from 'react';
import useWindowSize from '../../Services/useWindowSize';
import useWagml from '../../Services/Wagml/useWagml';
import WagmlCanvas from '../../Libraries/WagmlExerciseModel/WagmlCanvas';
import Size from '../../Libraries/WindowsBase/Size';
import Point from '../../Libraries/WindowsBase/Point';

const WagCanvasFx = props => {
    const { ...htmlProps } = props;
    const canvasRef = useRef(null);
    const { wagml, renderExerciseToImageRequested, dispatchWagml } = useWagml();
    const [windowWidth] = useWindowSize();
    const [wagmlCanvas] = useState(() => new WagmlCanvas(dispatchWagml));

    /** When the window is resized, redraw the canvas */
    useEffect(() => {
        if (!canvasRef)
            return;
        if (!canvasRef.current)
            return;

        canvasRef.current.width = windowWidth;

        if (0 !== windowWidth) {
            wagmlCanvas.updateLayout();
        }

    }, [windowWidth, wagmlCanvas]);

    // React to a change in the renderExerciseToImageRequested property
    useEffect(() => {
        if (renderExerciseToImageRequested) {
            if (canvasRef && canvasRef.current) {
                // Render the contents of the exercise to an image
                const data = canvasRef.current.toDataURL("image/png");

                dispatchWagml({ type: 'RENDERED_EXERCISE_AS_IMAGE', renderedImage: data });
            }
        }

    }, [renderExerciseToImageRequested, dispatchWagml]);

    // React to a change in the wagml
    useEffect(() => {
        wagmlCanvas.ctx = null;

        if (!canvasRef)
            return;

        if (!canvasRef.current)
            return;

        if (!wagml || wagml === '')
            return;

        // The WagmlCanvas class will deal with the new wagml. Drawing onto the canvas is its job. 
        wagmlCanvas.ctx = canvasRef.current.getContext('2d');
        wagmlCanvas.canvasSize = new Size(canvasRef.current.width, canvasRef.current.height);
        wagmlCanvas.wagmlText = wagml;
        //wagmlCanvas.canvasExercise.logComponents();
        wagmlCanvas.render();

        setFocus();

    }, [wagml, canvasRef, wagmlCanvas]);

    const setFocus = () => {
        if (canvasRef && canvasRef.current) {
            canvasRef.current.focus();
        }
    }

    /** Returns the exact position of the mouse on the canvas. */
    const getMousePosition = (e) => {
        // Get the mouse coordinates relative to the canvas control.
        const domRect = canvasRef.current.getBoundingClientRect();
        const canvasOffset = { left: domRect.left, top: domRect.top };
        const offsetX = canvasOffset.left;
        const offsetY = canvasOffset.top;
        const mouseX = parseInt(e.clientX - offsetX);
        const mouseY = parseInt(e.clientY - offsetY);

        return new Point(mouseX, mouseY);
    }

    const onMouseMove = (e) => {
        if (!canvasRef.current)
            return;

        const mousePosition = getMousePosition(e);

        // Deal with the mouse being over the canvas. If parts need to be redrawn because of the mouse
        // being over an object, then this function will take care of it.
        wagmlCanvas.onMouseMove(mousePosition, e);

        // // Check if we need to change the mouse cursor style
        // if (hoveredElements && 0 < hoveredElements) {
        //     e.target.style.cursor = 'pointer';
        // }
        // else {
        //     // Default mouse pointer
        //     e.target.style.cursor = 'text';
        // }
    }

    const onMouseClick = (e) => {
        if (!canvasRef.current)
            return;

        const mousePosition = getMousePosition(e);

        if (e.button === 0) {
            wagmlCanvas.onMouseLeftButtonClick(mousePosition);
        } else if (e.button === 1) {
            wagmlCanvas.onMouseMiddleButtonClick(mousePosition);
        } else if (e.button === 2) {
            wagmlCanvas.onMouseRightButtonClick(mousePosition);
        }
    }

    const onKeyDown = (e) => {
        switch (e.key) {
            case 'ArrowLeft':
                dispatchWagml({ type: 'MOVE_CURSOR_LEFT' });
                break;
            case 'ArrowRight':
                dispatchWagml({ type: 'MOVE_CURSOR_RIGHT' });
                break;
            default:
                break
        }
    }

    const onFocus = (e) => {
        wagmlCanvas.setFocus(true);
    }

    const onBlur = (e) => {
        wagmlCanvas.setFocus(false);
    }

    return (
        <>
            <canvas
                ref={canvasRef}
                tabIndex='0'
                contentEditable='true'
                focusable='true'

                onMouseMove={(e) => onMouseMove(e)}
                onClick={(e) => onMouseClick(e)}
                onKeyDown={(e) => onKeyDown(e)}
                onFocus={(e) => onFocus(e)}
                onBlur={(e) => onBlur(e)}

                {...htmlProps}>

            </canvas>
        </>
    );
};

export default WagCanvasFx;