/** Implements a structure that is used to describe the Size of an object. */
export default class Size {
    constructor(width, height) {
        this._width = width;
        this._height = height;
    }

    get width() { return this._width; }
    set width(width) { this._width = width; }

    get height() { return this._height; }
    set height(height) { this._height = height; }

    static get empty() { return new Size(Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER); }
    get isEmpty() { return this.width === Size.empty.width && this.height === Size.empty.height; }
}
