import CanvasUIElement from './CanvasUIElement';
import * as Colors from '../Utilities/Colors';
import { Visibility } from '../WindowsBase/Visibility';
import Size from '../WindowsBase/Size';

/** Represents the cursor.  */
export default class Cursor extends CanvasUIElement {
    measure(ctx) {
        this.constraintSize = new Size(0, 0);
    }

    draw(ctx) {
        if (!ctx)
            return;

        if (this.actualSize.isEmpty)
            return;

        if (this.visibility !== Visibility.Visible)
            return;

        ctx.lineWidth = 2;
        ctx.strokeStyle = Colors.Black;
        ctx.beginPath();
        ctx.moveTo(this.position.x, this.position.y);
        ctx.lineTo(this.position.x, this.position.y + this.actualSize.height);
        ctx.stroke();
    }

    drawComponentBounds(ctx) {

    }
}
