

/**
 * Handles how that state changes in the `useWebUser` hook.
 */
export const reducer = (oldState, action) => {
    switch (action.type) {
        case 'AuthStateChanged':
            let name = 'Anonymous';
            let email = null;
            let fullName = null;
            let firstName = null;
            let lastName = null;
            let picture = null;
            let nickname = null;

            if (action && action.authState && action.authState.authState && action.authState.authState.user) {
                name = action.authState.authState.user.name;
                email = action.authState.authState.user.email;
                fullName = action.authState.authState.user.fullName;
                firstName = action.authState.authState.user.name;
                lastName = action.authState.authState.user.name;
                nickname = action.authState.authState.user.nickname;
                picture = action.authState.authState.user.picture;
            }

            return {
                ...oldState,
                name,
                email,
                fullName,
                firstName,
                lastName,
                nickname,
                picture,
            };
        case 'COPS':
            const cops = action.cops;
            const listOfCops = action.listOfCops;
            const initialSelectedCop = cops[0];

            return {
                ...oldState,
                cops,
                listOfCops,
                selectedCop: initialSelectedCop,
            };
        case 'UPDATE_SELECTED_COP':
            const selectedCopCode = action.selectedCopCode;

            const selectedCop = oldState.cops.filter(f => f.Code === selectedCopCode)[0];
            return {
                ...oldState,
                selectedCop: selectedCop,
            };
        case 'UPDATE_SHOPPING_CART':
            return {
                ...oldState,
                shoppingCart: action.shoppingCart,
            }
        case 'ERROR':
            return {
                ...oldState,
                name: null,
                email: null,
                fullName: null,
                firstName: null,
                lastName: null,
                nickname: null,
                picture: null,
                packages: null,
                cops: null,
                error: 'ERROR'
            };
        default:
            return {
                ...oldState,
                isLoading: false,
                error: "Undefined action in reducer",
            };
    }
};
