import React from 'react';
import useWebUser from '../Services/WebUser/useWebUser';
import Package from '../Components/Package/Package';


const Account = () => {
    const { name, nickname, email, fullName, picture, packages, cops } = useWebUser();

    const Packages = packages ?
        packages.map(p => { return (<Package key={p.code} data={p} />); })
        : <></>;

    const ListOfCops = cops ?
        cops.map(c => { return (<><strong>COP: </strong>{c.DisplayName}<br /><i>{c.Description}</i><br /></>); })
        : <></>;

    return (<>
        <h1>Account</h1>
        <strong>User name: </strong>{name} <br />
        <strong>Nickname: </strong>{nickname} <br />
        <strong>Email: </strong>{email} <br />
        <strong>Full name: </strong>{fullName}<br />
        <strong>Picture:</strong><img src={picture} height="32" width="32" alt={name} /><br />
        <strong>Packages:</strong><br />
        {Packages}
        <strong>Code of Points:</strong><br />
        {ListOfCops}
    </>
    );
}

export default Account;