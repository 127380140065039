import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FigCopClient from '../Services/FigCop/FigCopClient';
import UbLibFilter from '../Components/UbLibFilter/UbLibFilter';
import UbLibElements from '../Components/UbLibElements/UbLibElements';

const baseUrlWebUserApi = process.env.REACT_APP_API_BASE_URL;

const FigWagUbLibrary = () => {
    const {
        getAccessTokenSilently,
        isAuthenticated,
    } = useAuth0();
    const [accessToken, setAccessToken] = useState(null);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filterSettings, setFilterSettings] = useState();

    // React to changes in the authenticated state in Auth0
    useEffect(
        () => {
            async function getAccessToken() {
                const token = await getAccessTokenSilently();
                setAccessToken(token);
            }

            if (isAuthenticated) {
                getAccessToken();
            }
            else {
                setAccessToken(null);
            }

        },
        [isAuthenticated, getAccessTokenSilently]
    );

    // Get the client for the WebUser API
    const [client] = useState(
        () => new FigCopClient(baseUrlWebUserApi)
    );

    const [showLogonAlert, setShowLogonAlert] = useState(true);

    // (Re)load data from Azure when authentication changes
    useEffect(
        () => {
            (async () => {
                const json = await client.GetUbLibrary(accessToken);
                const fxData = JSON.parse(json);
                setData(fxData);
            })();
        }
        , [client, accessToken]);

    // React to changes in data and/or filter settings
    useEffect(
        () => {
            const ft = filterSettings;

            /** Filter the list of elements using the selected filter options */
            const filterData = (data) => {
                if (!ft)
                    return data;

                const filterByGroup = ft.showGroup0 || ft.showGroup1 || ft.showGroup2 || ft.showGroup3 || ft.showGroup4
                    || ft.showGroup5 || ft.showGroup6 || ft.showGroupAll;

                const filterByDv = ft.showDvNone || ft.showDvA || ft.showDvB || ft.showDvC || ft.showDvD || ft.showDvE
                    || ft.showDvF || ft.showDvG || ft.showDvAll;

                const filterByType = ft.showTypeKip || ft.showTypeCast || ft.showTypeClearHip || ft.showTypeGiant
                    || ft.showTypeStalder || ft.showTypePike || ft.showTypeShapo || ft.showTypeTkatchev || ft.showTypeAll;

                const filterByRootSkill = ft.showRootClearHipBwd || ft.showRootClearHipFwd || ft.showRootStalderHipBwd || ft.showRootStalderHipFwd
                    || ft.showRootSoleCircleBwd || ft.showRootSoleCircleFwd || ft.showRootClearPikeBwd || ft.showRootClearPikeFwd || ft.showRootAdlerBwd || ft.showRootAdlerFwd || ft.showRootAll;

                const filterByRotation = ft.showRotation0 || ft.showRotation180 || ft.showRotation360 || ft.showRotation540
                    || ft.showRotation720 || ft.showRotation900 || ft.showRotation1080 || ft.showForward || ft.showBackward || ft.showRotationAll;

                let source = data;
                let filtered = null;

                if(ft.showDiffOnly) {
                    filtered = source.filter(d => (d.cop2022.changes && (d.cop2022.changes !== 'identical')));

                    source = filtered;
                }

                if (filterByGroup) {
                    filtered = source.filter(d =>
                        d.element.group === (filterByGroup && (ft.showGroup0 ? '0' : '')) ||
                        d.element.group === (filterByGroup && (ft.showGroup1 ? '1' : '')) ||
                        d.element.group === (filterByGroup && (ft.showGroup2 ? '2' : '')) ||
                        d.element.group === (filterByGroup && (ft.showGroup3 ? '3' : '')) ||
                        d.element.group === (filterByGroup && (ft.showGroup4 ? '4' : '')) ||
                        d.element.group === (filterByGroup && (ft.showGroup5 ? '5' : '')) ||
                        d.element.group === (filterByGroup && (ft.showGroup6 ? '6' : ''))
                    );

                    source = filtered;
                }

                if (filterByDv) {
                    if (ft.cop === '2017') {
                        filtered = source.filter(d =>
                            (ft.showDvNone && (d.cop2017.difficultyValue === undefined || d.cop2017.difficultyValue === null || d.cop2017.difficultyValue === '')) ||
                            d.cop2017.difficultyValue === (ft.showDvA ? 'A' : '') ||
                            d.cop2017.difficultyValue === (ft.showDvB ? 'B' : '') ||
                            d.cop2017.difficultyValue === (ft.showDvC ? 'C' : '') ||
                            d.cop2017.difficultyValue === (ft.showDvD ? 'D' : '') ||
                            d.cop2017.difficultyValue === (ft.showDvE ? 'E' : '') ||
                            d.cop2017.difficultyValue === (ft.showDvF ? 'F' : '') ||
                            d.cop2017.difficultyValue === (ft.showDvG ? 'G' : '')
                        );
                    } else {
                        filtered = source.filter(d =>
                            (ft.showDvNone && (d.cop2022.difficultyValue === undefined || d.cop2022.difficultyValue === null || d.cop2022.difficultyValue === '')) ||
                            d.cop2022.difficultyValue === (ft.showDvA ? 'A' : '') ||
                            d.cop2022.difficultyValue === (ft.showDvB ? 'B' : '') ||
                            d.cop2022.difficultyValue === (ft.showDvC ? 'C' : '') ||
                            d.cop2022.difficultyValue === (ft.showDvD ? 'D' : '') ||
                            d.cop2022.difficultyValue === (ft.showDvE ? 'E' : '') ||
                            d.cop2022.difficultyValue === (ft.showDvF ? 'F' : '') ||
                            d.cop2022.difficultyValue === (ft.showDvG ? 'G' : '')
                        );
                    }

                    source = filtered;
                }

                if (filterByType) {
                    filtered = source.filter(d =>
                        (d.element.elementType && (d.element.elementType === (ft.showTypeKip ? 'Kip' : ''))) ||
                        (d.element.elementType && (d.element.elementType === (ft.showTypeCast ? 'Cast to handstand' : ''))) ||
                        (d.element.elementType && (d.element.elementType === (ft.showTypeClearHip ? 'Clear hip circle' : ''))) ||
                        (d.element.elementType && (d.element.elementType === (ft.showTypeGiant ? 'Giant' : ''))) ||
                        (d.element.elementType && (d.element.elementType === (ft.showTypeStalder ? 'Stalder circle' : ''))) ||
                        (d.element.elementType && (d.element.elementType === (ft.showTypePike ? 'Pike circle' : ''))) ||
                        (d.element.elementType && (d.element.elementType === (ft.showTypeShapo ? 'Shaposhnikova' : ''))) ||
                        (d.element.isTkatchevType && (d.element.isTkatchevType === (ft.showTypeTkatchev ? 'true' : 'false')))
                    );

                    source = filtered;
                }

                if (filterByRootSkill) {
                    filtered = source.filter(d =>
                        (d.element.rootSkillEntry && (d.element.rootSkillEntry === (ft.showRootClearHipBwd ? '' : ''))) ||
                        (d.element.rootSkillEntry && (d.element.rootSkillEntry === (ft.showRootClearHipFwd ? '' : ''))) ||
                        (d.element.rootSkillEntry && (d.element.rootSkillEntry === (ft.showRootStalderHipBwd ? '' : ''))) ||
                        (d.element.rootSkillEntry && (d.element.rootSkillEntry === (ft.showRootStalderHipFwd ? '' : ''))) ||
                        (d.element.rootSkillEntry && (d.element.rootSkillEntry === (ft.showRootSoleCircleBwd ? '' : ''))) ||
                        (d.element.rootSkillEntry && (d.element.rootSkillEntry === (ft.showRootSoleCircleFwd ? '' : ''))) ||
                        (d.element.rootSkillEntry && (d.element.rootSkillEntry === (ft.showRootClearPikeBwd ? '' : ''))) ||
                        (d.element.rootSkillEntry && (d.element.rootSkillEntry === (ft.showRootClearPikeFwd ? '' : ''))) ||
                        (d.element.rootSkillEntry && (d.element.rootSkillEntry === (ft.showRootAdlerBwd ? '' : ''))) ||
                        (d.element.rootSkillEntry && (d.element.rootSkillEntry === (ft.showRootAdlerFwd ? '' : '')))
                    );

                    source = filtered;
                }

                if (filterByRotation) {
                    filtered = source.filter(d =>
                        (d.element.laRotation && (d.element.laRotation === (ft.showRotation0 ? '0' : ''))) ||
                        (d.element.laRotation && (d.element.laRotation === (ft.showRotation180 ? '180' : ''))) ||
                        (d.element.laRotation && (d.element.laRotation === (ft.showRotation360 ? '360' : ''))) ||
                        (d.element.laRotation && (d.element.laRotation === (ft.showRotation540 ? '540' : ''))) ||
                        (d.element.laRotation && (d.element.laRotation === (ft.showRotation720 ? '720' : ''))) ||
                        (d.element.laRotation && (d.element.laRotation === (ft.showRotation900 ? '900' : ''))) ||
                        (d.element.laRotation && (d.element.laRotation === (ft.showRotation1080 ? '1080' : ''))) ||
                        (d.element.directionOfRotation && (d.element.directionOfRotation === (ft.showForward ? 'F' : ''))) ||
                        (d.element.directionOfRotation && (d.element.directionOfRotation === (ft.showBackward ? 'B' : '')))
                    );

                    source = filtered;
                }

                if (!filtered)
                    filtered = data;

                return filtered;
            }

            setFilteredData(filterData(data));
        }
        , [data, filterSettings]
    );

    // Show 'login for full access to license'
    let ShowLogonAlert = null;
    if (!isAuthenticated && showLogonAlert) {
        ShowLogonAlert = <Alert variant="info" onClose={() => setShowLogonAlert(false)} dismissible>Logon to get access to licensed functionality.</Alert>
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md="auto">
                        <UbLibFilter filterChanged={setFilterSettings} />
                    </Col>
                    <Col>
                        <h1><span style={{ fontFamily: "WAG Symbols", fontSize: "72px" }}></span>Uneven Bars Library</h1>
                        {ShowLogonAlert}
                        <UbLibElements filteredData={filteredData} />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default FigWagUbLibrary;