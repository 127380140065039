import React from 'react';

// Define a default UI for filtering
const HistoryTableDefaultColumnFilter = (props) => {
    const { column: { filterValue, preFilteredRows, setFilter }, } = props;
    const count = preFilteredRows.length

    let style = {};
    if("element.symbol" === props.column.id)
        style={ fontFamily: "WAG Symbols", fontSize: "16px"};

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            style={style}
            placeholder={`Search ${count} records...`}
        />
    )
}

export default HistoryTableDefaultColumnFilter;