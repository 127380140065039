import React from 'react';
import useWebUser from '../Services/WebUser/useWebUser';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

const Checkout = () => {
    const { shoppingCart } = useWebUser();
    /*
    {
      "detail": {
        "invoice_number": "#12345a",
        "reference": "deal-ref",
        "currency_code": "USD",
        "note": "Thank you for your business.",
        "term": "No refunds after 30 days.",
        "memo": "This is a long contract",
        "payment_term": {
          "term_type": "NET_10"
        }
      },
      "invoicer": {
        "name": {
          "given_name": "Tom",
          "surname": "Van Hauwaert"
        },
        "address": {
          "address_line_1": "Beauvoislaan 100",
          "admin_area_2": "Kalmthout",
          "admin_area_1": "",
          "postal_code": "2920",
          "country_code": "BE"
        },
        "phones": [{
          "country_code": "32",
          "national_number": "475945940",
          "phone_type": "MOBILE"
        }],
        "website": "www.test.com",
        "tax_id": "ABcNkWSfb5ICTt73nD3QON1fnnpgNKBy- Jb5SeuGj185MNNw6g",
        "logo_url": "https://example.com/logo.png",
        "additional_notes": "2-4"
      },
      "primary_recipients": [{
        "billing_info": {
          "name": {
            "given_name": "Stephanie",
            "surname": "Meyers"
          },
          "address": {
            "address_line_1": "1234 Main Street",
            "admin_area_2": "Anytown",
            "admin_area_1": "CA",
            "postal_code": "98765",
            "country_code": "US"
          },
          "email_address": "tom.van.hauwaert@telenet.be",
          "phones": [{
            "country_code": "001",
            "national_number": "4884551234",
            "phone_type": "HOME"
          }],
          "additional_info_value": "add-info"
        },
        "shipping_info": {
          "name": {
            "given_name": "Marleen",
            "surname": "Van Dooren"
          },
          "address": {
            "address_line_1": "1234 Main Street",
            "admin_area_2": "Anytown",
            "admin_area_1": "CA",
            "postal_code": "98765",
            "country_code": "US"
          }
        }
      }],
    }
    */
    return (
        <>
            <Form>
                <h1>Selected product</h1>
                <p>{shoppingCart.items}</p>
                <h1>Billing Info</h1>
                <Form.Group as={Form.Row} controlId="email_address">
                    <Form.Label column sm={2}>Email address</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="email" placeholder="Enter email" />
                        <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
                    </Col>
                </Form.Group>
                <Form.Group as={Form.Row} controlId="given_name">
                    <Form.Label column sm={2}>First name:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="First name" />
                    </Col>
                </Form.Group>
                <Form.Group as={Form.Row} controlId="surname">
                    <Form.Label column sm={2}>Last name:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="Last name" />
                    </Col>
                </Form.Group>
                <Form.Group as={Form.Row} controlId="address_line_1">
                    <Form.Label column sm={2}>Street + number:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="Street + number" />
                    </Col>
                </Form.Group>
                <Form.Group as={Form.Row} controlId="admin_area_2">
                    <Form.Label column sm={2}>City:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="City" />
                    </Col>
                </Form.Group>
                <Form.Group as={Form.Row} controlId="admin_area_1">
                    <Form.Label column sm={2}>State:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="State" />
                    </Col>
                </Form.Group>
                <Form.Group as={Form.Row} controlId="postal_code">
                    <Form.Label column sm={2}>Postal code:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="Postal code" />
                    </Col>
                </Form.Group>
                <Form.Group as={Form.Row} controlId="country_code">
                    <Form.Label column sm={2}>Country:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="Country" />
                    </Col>
                </Form.Group>
            </Form>
        </>
    );
};

export default Checkout;