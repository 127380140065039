import CanvasUIElement from './CanvasUIElement';
import RenderStyle from './RenderStyle';
import * as Colors from '../Utilities/Colors';
import { Visibility } from '../WindowsBase/Visibility';
import Size from '../WindowsBase/Size';

/** Represents the tooltip window.  */
export default class Tooltip extends CanvasUIElement {
    constructor(id) {
        super(id);

        this._symbolText = "";
        this._description = "Straddle pike jump with 1/1 turn (360°)";
        this._number = "#1.307";
        this._dv = "C";
    }

    get symbolText() { return this._symbolText; }
    set symbolText(value) { this._symbolText = value; }

    get description() { return this._description; }
    set description(value) { this._description = value; }

    get number() { return this._number; }
    set number(value) { this._number = value; }

    get dv() { return this._dv; }
    set dv(value) { this._dv = value; }

    measure(ctx) {
        this.constraintSize = new Size(250, 50);
    }

    draw(ctx) {
        if (!ctx)
            return;

        if (this.actualSize.isEmpty)
            return;

        if (this.visibility !== Visibility.Visible)
            return;

        ctx.lineWidth = 2;
        ctx.strokeStyle = Colors.Black;
        ctx.fillStyle = Colors.Black;
        ctx.beginPath();

        ctx.rect(this.position.x, this.position.y, this.actualSize.width, this.actualSize.height);
        ctx.fill();
        ctx.stroke();

        ctx.font = RenderStyle.exerciseFont;
        ctx.textBaseline = "alphabetic";
        ctx.fillStyle = Colors.White;
        ctx.fillText(this.symbolText, this.position.x , this.position.y + 50);
        
    }

    drawComponentBounds(ctx) {

    }
}
