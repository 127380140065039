import './Cop.css';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';

const FigWagVtCop = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-3 sidenav position-fixed">
                        <div id="toc_container">
                            <p className="toc_title">Contents</p>
                            <p className="toc_title"><a href="#CoP_2022_2024">CoP 2022 - 2024</a></p>
                            <ul className="toc_list">
                                <li><a href="#10_1_General">10.1 General</a>
                                </li>
                                <li><a href="#10_2_Run_Approaches">10.2 Run Approaches</a>
                                </li>
                                <li><a href="#10_3_Vault_Groups">10.3 Vault Groups</a>
                                </li>
                                <li><a href="#10_4_Requirements">10.4 Requirements</a>
                                    <ul>
                                        <li><a href="#10_4_1_Corridor_Markings">10.4.1 Corridor Markings</a></li>
                                        <li><a href="#10_4_2_Specific_Apparatus_Deductions">10.4.2 Specific Apparatus Deductions</a></li>
                                        <li><a href="#10_4_3_Performance_of_Invalid_Vaults">10.4.3 Performance of Invalid Vaults</a></li>
                                    </ul>
                                </li>
                                <li><a href="#10_5_Method_of_Scoring">10.5 Method of Scoring</a>
                                </li>
                                <li><a href="#10_6_Specific_Apparatus_Deductions">10.6 Specific Apparatus Deductions</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-9 offset-3">

                        <h1><span style={{ fontFamily: "WAG Symbols", fontSize: "72px" }}></span>Code of Points Vault</h1>
                        <h2 id="CoP_2022_2024">CoP 2022 - 2024, Section 10 - Vault</h2>
                        <Alert variant="danger">All vaults have been lowered in value. Check the <a href="/fig-wag-vt-library"> new values here</a>.</Alert>
                        <Alert variant="warning">The important changes to section 10 (Vault) are in chapter 10.6 (specific apparatus deductions for the E-panel).<br />
            All other changes are in grammar, spelling and style.</Alert>
                        <h3 id="10_1_General">10.1 General</h3>
                        <p>Depending on the requirements for the competition phase, the gymnast is required to perform one or two vaults from the Table of Vaults.</p>
                        <p>Run distance is a maximum of 25 meters, measured from the front edge of the table to the inner side of the block attached at the end of the vault run up mat.</p>
                        <ul>
                            <li>The vault begins with a run, an arrival and take-off from the springboard with two feet, from either a:
                    <ul>
                                    <li>forward position or</li>
                                    <li>backward position</li>
                                </ul>
                            </li>
                            <li>No vault with sideward landing may be submitted.</li>
                            <li>All vaults must be performed with repulsion from both hands off the vaulting table.</li>
                            <li>The gymnast is required to properly use the “safety collar” supplied by the Organising Committee for round-off entry vaults.</li>
                            <li>A hand placement mat may be used only for Yurchenko vaults from groups 1, 4 and 5.</li>
                            <li>All vaults are illustrated with a number.</li>
                            <li>The gymnast is responsible for flashing the intended vault number prior to the beginning of each vault <i>(manually or electronically)</i>.</li>
                            <li>After receiving the green light or signal from the D1 Judge, the gymnast executes the 1st vault and then returns to the end of the runway to post the number for her 2nd vault.</li>
                            <li>Beginning with the take-off, the vault phases are evaluated:
                    <ul>
                                    <li>pre-flight <i>(1st flight)</i></li>
                                    <li>repulsion</li>
                                    <li>flight <i>(2nd flight)</i> and landing</li>
                                </ul>
                            </li>
                        </ul>
                        <h3 id="10_2_Run_Approaches">10.2 Run Approaches</h3>
                        <p>Additional run approaches are permitted as follows, with deduction of <span className="deduction"> – 1.00 </span> for empty run (if gymnast has not touched the springboard or the apparatus).</p>
                        <ul>
                            <li>When 1 vault is required, a second run approach is permitted with deduction.</li>
                            <li>Third approach <b><u>not</u></b> permitted.</li>
                            <li>When 2 vaults are required, a third run approach is permitted with deduction.</li>
                            <li>Fourth approach <b><u>not</u></b> permitted.</li>
                        </ul>
                        The D-Panel will take the deduction from the Final Score of the vault performed.
                    <h3 id="10_3_Vault_Groups">10.3 Vault Groups</h3>
                        <p>The vaults are classified into the following groups:</p>
                        <Table>
                            <tbody>
                                <tr>
                                    <td><b>Group 1</b></td>
                                    <td> – </td>
                                    <td>Vault without salto <i>(Handspring, Yamashita, Round-off)</i> with or without LA turn in 1st and/or 2nd flight phase.</td>
                                </tr>
                                <tr>
                                    <td><b>Group 2</b></td>
                                    <td> – </td>
                                    <td>Handspring fwd with or without 1/1 turn (360°) in 1st flight phase – salto fwd or bwd with or without twist in 2nd flight phase.</td>
                                </tr>
                                <tr>
                                    <td><b>Group 3</b></td>
                                    <td> – </td>
                                    <td>Handspring with ¼ - ½ turn (90° - 180°) in 1st flight phase <i>(Tsukahara)</i> – salto bwd with or without twist in 2nd flight phase.</td>
                                </tr>
                                <tr>
                                    <td><b>Group 4</b></td>
                                    <td> – </td>
                                    <td>Round-off <i>(Yurchenko)</i> with or without ¾ turn (270°) in 1st flight phase – salto bwd with or without twist in 2nd flight phase.</td>
                                </tr>
                                <tr>
                                    <td><b>Group 5</b></td>
                                    <td> – </td>
                                    <td>Round-off with ½ turn (180°) in 1st flight phase – salto fwd or bwd with or without twist in 2nd flight phase.</td>
                                </tr>
                            </tbody>
                        </Table>
                        <h3 id="10_4_Requirements">10.4 Requirements</h3>
                        <ul>
                            <li>The <b>intended</b> vault number to be flashed <i>(manually or electronically)</i> before the vault is performed.</li>
                            <li>
                                In the <b>Qualifying, Team Final and All-Around</b>: One vault must be performed.
                                <ul>
                                    <li>In <b>Qualifying</b>, the 1st vault score counts toward the <b>Team</b> and/or <b>All-Around</b> Total.</li>
                                    <li>The gymnast who wishes to qualify for the <b>Apparatus Final</b> must perform <b>2</b> vaults as per the <b>Apparatus Finals</b> rules below.</li>
                                </ul>
                            </li>
                            <li><b>Apparatus Finals</b></li>
                            <ul>
                                <li>The gymnast must perform 2 vaults, which will be averaged for the Final Score.</li>
                                <li>The 2 vaults must be from <b>different</b> Vault Groups.</li>
                                <li>Must show a different 2nd flight phase.</li>
                            </ul>
                            <b><i>Example:</i></b>
                            <ol>
                                <li>If the 1st vault is from Group 4 <i>Round-off, flic-flac on – stretched salto backward with 2½ twist (900°) off</i> <span className="wag"></span> then the choices for the 2nd vault would be:
                                    <ul>
                                        <li><i>Tsukahara stretched with 2/1 twist (720°) off</i> <span className="wag"></span> <br />or</li>
                                        <li><i>Handspring fwd on – 1/1 turn off</i> <span className="wag"></span></li>
                                    </ul>
                                </li>
                                <li>If the 1st vault is from Group 2 <i>Handspring fwd on – tucked salto fwd off</i> <span className="wag"></span> then the choices for the 2nd vault would be:
                                    <ul>
                                        <li><i>Round off, flic-flac on – stretched salto bwd off</i> <span className="wag"></span> <br />or</li>
                                        <li><i>Tsukahara piked</i> <span className="wag"></span></li>
                                    </ul>
                                </li>
                            </ol>
                        </ul>
                        <Table>
                            <tr>
                                <td><b><i>Note:</i></b></td>
                                <td><i>Handspring fwd on – tucked double salto fwd off:</i> <span className="wag"></span> <i>If the 2nd salto is not completed because the gymnast lands on the feet and any other body part simultaneously, then the vault will be recognised as Handspring fwd on – tucked fwd salto off</i> <span className="wag"></span></td>
                            </tr>
                        </Table>
                        <h3 id="10_4_1_Corridor_Markings">10.4.1 Corridor Markings</h3>
                        <p>As an orientation for directional deductions there will be a marked corridor on the landing mat. The gymnast must land and finish to a stable stand in this zone.</p>
                        <p>The D1 Judge <i>(with written notification from the Line Judge)</i> will deduct from the Final Score for touching the mat with any part of the body outside of the marked corridor as follows:</p>
                        <Table size="sm">
                            <tr>
                                <td>
                                    <ul>
                                        <li>Land or step outside with one foot/hand (part of foot/hand)</li>
                                    </ul>
                                </td>
                                <td><span className="deduction"> – 0.10 </span></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>Land or step outside with two feet/hands <i>(part of feet/hands)</i> or body part</li>
                                    </ul>
                                </td>
                                <td><span className="deduction"> – 0.30 </span></td>
                                <td></td>
                            </tr>
                        </Table>
                        <h3 id="10_4_2_Specific_Apparatus_Deductions">10.4.2 Specific Apparatus Deductions – D-Panel</h3>
                        <p>Taken from the Final Score of the vault performed:</p>
                        <Table size="sm">
                            <tr>
                                <td>
                                    <ul>
                                        <li>More than 25 meters run distance</li>
                                    </ul>
                                </td>
                                <td><span className="deduction"> – 0.50 </span></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>Run approach without performing vault</li>
                                    </ul>
                                </td>
                                <td><span className="deduction"> – 1.00 </span></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>Support <i>(repulsion phase)</i> with only one hand</li>
                                    </ul>
                                </td>
                                <td><span className="deduction"> – 2.00 </span></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <ul>
                                        <li>
                                            In the Qualification to and in Apparatus Finals:
                                <ul>
                                                <li>When 1 of 2 vaults receives “0” points <i>(10.4.3)</i></li>
                                                <b>Evaluation:</b><br />
                                    Score of the vault performed divided by 2 = Final Score (FS)
                                    <li>When the 2 vaults are <b><u>not</u></b> from different groups or do <b><u>not</u></b> show a different second flight phase</li>
                                                <b>Evaluation:</b><br />
                                                <b>[</b>(FS of the 1st Vault) + (FS of the 2nd Vault, minus 2.00)<b>]</b>/2 = Final Score
                                </ul>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </Table>
                        <h3 id="10_4_3_Performance_of_Invalid_Vaults">10.4.3 Performance of Invalid Vaults (0.00 P.) *</h3>
                        <ul>
                            <li>The vault is executed without a support phase, i.e. neither hand touches the table.</li>
                            <li>Failure to properly use the safety collar for round-off entry vaults.</li>
                            <li>Spotting assistance during the vault.</li>
                            <li>Failure to land on feet first.</li>
                            <li>The vault is so poorly executed that the intended vault cannot be recognised, or the gymnast pushes from the table with her feet.</li>
                            <li>The gymnast performs a prohibited vault (straddled legs, prohibited pre-element before the vaulting board, intentionally lands in side position).</li>
                            <li>The first vault is repeated for the second vault in Qualification for Vault Final or in Vault Final.</li>
                        </ul>
                        <p><i><b>*Note:</b> “0.00” score recorded by D-Panel. No evaluation by E-Panel.</i></p>
                        <p>A video review by the D-Panel and Apparatus Supervisor will automatically occur for every vault that receives an Invalid 0.00 Score, or vaults performed with support of one hand only.</p>
                        <h3 id="10_5_Method_of_Scoring">10.5 Method of Scoring</h3>
                        <h4>D-Panel:</h4>
                        <p>Enters the value of the performed vault and shows on the board to the E-Judges the symbol of the vault recognised (if different from flashed number). The score of the 1st vault must be flashed before the gymnast executes the 2nd vault.</p>
                        <h4>E-Panel:</h4>
                        <p>Execution Deductions (Section 8) must be taken in addition to Specific Apparatus Deductions.</p>
                        <h3 id="10_6_Specific_Apparatus_Deductions">10.6 Specific Apparatus Deductions - E-Panel</h3>
                        <Alert variant="warning">1) For missing degrees of LA turn during flight phase: added deduction for group 3 with ¼ turn<br />
            2) Excessive snap in second flight phase: now the degrees of the snap angle are specified instead of just the 'X'</Alert>
                        <Table className="fit" striped bordered hover>
                            <tr className="headerRow">
                                <th>Faults</th>
                                <th className="center">0.10</th>
                                <th className="center">0.30</th>
                                <th className="center">0.50</th>
                            </tr>
                            <tr className="headerRow">
                                <th colSpan="4">First Flight Phase</th>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>For missing degrees of LA turn during flight phase:</li></ul></td>
                                <td />
                                <td />
                                <td />
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Gr. 1 with ½ (180°) turn</li></ul></td>
                                <td className="narrowCol center">&lt;= 45°</td>
                                <td className="narrowCol center">&lt;= 90°</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Gr. 3 with ¼ turn</li></ul></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center">&lt;= 45°</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Gr. 4 with ¾ (270°) turn</li></ul></td>
                                <td className="narrowCol center">&lt;= 45°</td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Gr. 1 or 2 with 1/1 turn (360°)</li></ul></td>
                                <td className="narrowCol center">&lt;= 45°</td>
                                <td className="narrowCol center">&lt;= 90°</td>
                                <td className="narrowCol center">&gt; 90°</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Poor technique:</li></ul></td>
                                <td />
                                <td />
                                <td />
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Hip angle</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Arch</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Bent knees</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Leg or knee separations</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="headerRow">
                                <th colSpan="4">Repulsion Phase</th>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Poor technique:</li></ul></td>
                                <td />
                                <td />
                                <td />
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Staggered hand placement Gr. 1, 2 &amp; 5</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Bent arms</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Shoulder angle</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Failure to pass through vertical</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Prescribed LA turn begun too early <i>(on the table)</i></li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="headerRow">
                                <th colSpan="4">Second Flight Phase</th>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Excessive snap</li></ul></td>
                                <td className="narrowCol center">&gt;= 90°</td>
                                <td className="narrowCol center">&lt; 90°</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Height</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Exactness of LA turn <i>(includes Cuervo)</i></li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Body position</li></ul></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Exactness of tuck/pike position in salto</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Exactness of tuck/pike position in salto with twist</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Body alignment in stretched salto</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Body alignment in stretched salto with twist</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Failure to maintain stretched body position<br /><i>(piking too early)</i></li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Insufficient and/or late extension <i>(tuck/pike vaults)</i></li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Bent knees</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Leg or knee separations</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Under-rotation of salto without a fall</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>With a fall</li></ul></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Distance <i>(insufficient length)</i></li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Deviation from a straight direction</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Dynamics</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="headerRow">
                                <th >Landing deductions</th>
                                <th colSpan="3">See Sec.8</th>
                            </tr>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FigWagVtCop;