import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Row, Col } from "reactstrap";

// Declaring outside component to avoid recreation on each render
const emptyUserInfo = {
    wagJudgeCategory: "",
    wagWtcMember: false,
};

const Profile = () => {
    const { user } = useAuth0();
    const [userInfo, setUserInfo] = useState(emptyUserInfo);

    if (!user)
        return (<p>Loading ...</p>);;

    const wagJudgeCategory = user['https://schemas.gymnastics.software.com/wag_judge_category'];
    if (wagJudgeCategory && (wagJudgeCategory !== userInfo.wagJudgeCategory)) {
        setUserInfo((currentUserInfo) => {
            return {
                ...currentUserInfo,
                wagJudgeCategory: wagJudgeCategory,
            }
        });
    }

    function handleChange(event) {
        event.persist();
        setUserInfo((currentUserInfo) => {
            return {
                ...currentUserInfo,
                [event.target.id]: event.target.value,
            }
        })
    }

    return (
        <Container className="mb-5">
            <Row className="align-items-center profile-header mb-5 text-center text-md-left">
                <Col md={2}>
                    <img
                        src={user.picture}
                        alt="Profile"
                        className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                    />
                </Col>
                <Col md>
                    <h2>{user.name}</h2>
                    <p className="lead text-muted">{user.email}</p>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <label htmlFor="wagJudgeCategory">WAG Judge category: </label>
                    <select name="wagJudgeCategory" id="wagJudgeCategory" value={userInfo.wagJudgeCategory} onChange={handleChange}>
                        <option value="international_category_1">International judge - category 1</option>
                        <option value="international_category_2">International judge - category 2</option>
                        <option value="international_category_3">International judge - category 3</option>
                        <option value="international_category_4">International judge - category 4</option>
                        <option value="national">National judge (all local categories)</option>
                        <option value="none">I am not a WAG judge</option>
                    </select>
                </Col>
                <p>: {user['https://schemas.gymnastics.software.com/wag_judge_category']}</p>
            </Row>
            <Row>
                <p>Member of Women's Artistic Gymnastics Technical Committee: {user['https://schemas.gymnastics.software.com/wag_judge_category']}</p>
            </Row>
            <Row>
                <p>{JSON.stringify(user, null, 2)}</p>
            </Row>
        </Container>
    );
}

export default Profile;