import './Cop.css';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';

const FigWagUbCop = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-3 sidenav position-fixed">
                        <div id="toc_container">
                            <p className="toc_title">Contents</p>
                            <p className="toc_title"><a href="#CoP_2022_2024">CoP 2022 - 2024</a></p>
                            <ul className="toc_list">
                                <li><a href="#11_1_General">11.1 General</a>
                                    <ul>
                                        <li><a href="#11_1_General_mounts">a) Mounts</a></li>
                                        <li><a href="#11_1_General_fall_timing">b) Fall Timing</a></li>
                                    </ul>
                                </li>
                                <li><a href="#11_2_Content_and_Construction_of_the_Exercise">11.2 Content and Construction of the Exercise</a>
                                </li>
                                <li><a href="#11_3_Composition Requirements">11.3 Composition Requirements (CR)</a>
                                </li>
                                <li><a href="#11_4_Connection_Value">11.4 Connection Value (CV)</a>
                                </li>
                                <li><a href="#11_5_Composition_Deductions">11.5 Composition Deductions</a>
                                </li>
                                <li><a href="#11_6_Specific_Apparatus_Deductions">11.6 Specific Apparatus Deductions</a>
                                </li>
                                <li><a href="#11_7_Notes">11.7 Notes</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-9 offset-3">
                        <h1><span style={{ fontFamily: "WAG Symbols", fontSize: "72px" }}></span>Code of Points Uneven Bars</h1>
                        <h2 id="CoP_2022_2024">CoP 2022 - 2024, Section 11 - Uneven Bars</h2>
                        <h3 id="11_1_General">11.1 General</h3>
                        <p>The evaluation of the exercise begins with the take-off from the springboard or the mat. Additional supports under the springboard <i>(i.e. an extra board)</i> are <u>not</u> permitted.</p>
                        <ol type="a">
                            <li>
                                <b id="11_1_General_mounts">Mounts</b>
                                <ul>
                                    <li><a href="/fig-wag-ub-library?filter=mounts">See: Mount elements</a></li>
                                    <li>
                                        If the gymnast on her first attempt touched the springboard, the apparatus, or ran underneath the apparatus:
                                        <ul>
                                            <li>Deduction <span className="deduction"> – 1.00 </span></li>
                                            <li>She must start her exercise</li>
                                            <li>No value will be awarded for the mount</li>
                                        </ul>
                                    </li>
                                    <li>
                                        A gymnast is permitted a second attempt to mount <i>(with a penalty)</i> if she has <u>NOT</u> touched the springboard, the apparatus, or run underneath the apparatus:
                                        <ul>
                                            <li>Deduction <span className="deduction"> – 1.00 </span></li>
                                        </ul>
                                    </li>
                                    <li>A third attempt is not permitted.</li>
                                </ul>
                                Gymnast may <u>NOT</u> run/walk under low bar to perform mount <i>(see 2.4)</i>.<br />
                                D-Panel will take the deduction from the Final Score.
                            </li>
                            <li>
                                <b id="11_1_General_fall_timing">Fall Timing:</b><br />
                                <p>For interruption of the exercise due to a fall from the apparatus an intermediate time period of 30 seconds is allowed.</p>
                                <p>If the gymnast exceeds the allowable time to resume her exercise
                    a <span className="deduction"> - 0.30 P.</span> neutral deduction for excessive time will be applied if the gymnast continues her exercise.</p>
                                <ul>
                                    <li>The timing starts when the gymnast is on her feet after the fall.</li>
                                    <li>The elapsed time during the fall will be displayed in seconds on the scoreboard.</li>
                                    <li>A warning signal <i>(gong)</i> will be communicated at
                            <ul>
                                            <li>10 seconds</li>
                                            <li>20 seconds and again at the</li>
                                            <li>30 second time limit</li>
                                        </ul>
                                    </li>
                                    <li>The exercise officially resumes when the feet leave the floor.</li>
                                    <li>If the gymnast has not resumed the exercise within the 60 second time limit, the exercise will be terminated.</li>
                                </ul>
                                <p>No salute is necessary to resume an exercise after a fall.</p>
                            </li>
                        </ol>
                        <h3 id="11_2_Content_and_Construction_of_the_Exercise">11.2 Content and Construction of the Exercise</h3>
                        <Alert variant="warning">Changes: Only one “Tkatchev” type element without turn from each line in the Table of Elements will be recognised.</Alert>
                        <p>The maximum 8 highest difficulties including the dismount are counted for DV.</p>
                        <ul>
                            <li>No DMT <span className="deduction">– 0.50</span> from Final Score (D-Panel).</li>
                            <li>
                                Only 3 elements in chronological order from the same root skill(*) will be counted for DV, CR and CV
                    <ul><li>except: <a href='/fig-wag-ub-library?filter=root_skill_excemptions'>Kips, Giants (fwd/bwd) and Casts to HSTD</a></li></ul>
                    (*) The root skill is determined by the entry into the skill and the direction of the rotation (bwd or fwd)
                    <p>Example 1: <span className="wag"></span> == B + D + E + (no value)</p>
                                <p>Example 2: <span className="wag"></span> == D + D + D + B + B + D</p>
                                <p>Example 3: <span className="wag"></span> == D + B + C + (no value)</p>
                            </li>
                            <li>Mounts (e.g. <span className="wag"></span>) and dismounts (e.g. <span className="wag"></span>) will be counted in the root skill number.</li>
                            <li>Elements with no DV (due to failure to meet the technical requirement) will not be counted in the root skill number.</li>
                        </ul>
                        <p>Only one “Tkatchev” type element without turn from each line in the Table of Elements will be recognised.</p>
                        <p>The value parts should represent a variety of the following categories of movement:
                <ol type="a">
                                <li>Circle and Swings
                        <ul>
                                        <li>giant circles backward</li>
                                        <li>giant circles forward</li>
                                        <li>swings &amp; clear hip circles</li>
                                        <li>stalders forward/backward</li>
                                        <li>pike circles forward/backward</li>
                                    </ul>
                                </li>
                                <li>
                                    Flights
                        <ul>
                                        <li>flight from HB to grasp on LB <i>(or reverse)</i></li>
                                        <li>counter flight <i>(over the bar)</i></li>
                                        <li>vaults</li>
                                        <li>hechts</li>
                                        <li>saltos</li>
                                    </ul>
                                </li>
                            </ol>
                        </p>
                        <h3 id="11_3_Composition Requirements">11.3 Composition Requirements (CR) – D-Panel 2.00</h3>
                        <ol>
                            <li>Flight element from HB to LB - <span className="award">award 0.50</span></li>
                            <li>Flight element on the same bar - <span className="award">award 0.50</span></li>
                            <li>Different grips <i>(not cast, MT or DMT) - <span className="award">award 0.50</span></i></li>
                            <li>Non-flight element with min. 360º turn <i>(not MT)</i> - <span className="award">award 0.50</span></li>
                        </ol>
                        <h3 id="11_4_Connection_Value">11.4 Connection Value (CV) – D-Panel</h3>
                        <Alert variant="warning">Changes: CV (D + E) replaced by (E + E) and (F + D)<br />
            Definition of a CV in relation to <b>empty swing</b> or <b>intermediate swing</b> has been updated<br />
                            <span className="wag"></span> is added to the list of elements that are allowed to be followed by an empty swing</Alert>
                        <ol type="a">
                            <li>CV can be awarded for direct connections</li>
                            <li>The CV will be added into the D-Score.</li>
                        </ol>
                        <strong>Formulas for direct connections</strong>
                        <Table className="center fit" bordered>
                            <tr>
                                <th>0.10</th>
                                <th>0.20</th>
                            </tr>
                            <tr>
                                <td>D + D<br />
                                    <i>(or more)</i>
                                </td>
                                <td>
                                    D (<i>flight</i> - same bar or LB to HB) + C <i>or more</i><br />
                        (<b>on HB</b> and must be performed in this order)<br />
                                    <br />
                                    <span className="changed">E + E <i>(one must be flight)</i><br />
                        F + D <i>(both flights)</i></span>
                                </td>
                            </tr>
                        </Table>
                        <p><i>Note: C/D element must have flight or min. ½ turn (180°)</i></p>
                        <ol type="a">
                            <li>An element can be performed 2 times within the same direct connection for CV, but may <i><b><u>NOT</u></b></i> receive DV a 2nd time.</li>
                            <li>Flight elements include elements with visible flight:
                                <ul>
                                    <li>From HB to grasp on LB <i>(or reverse)</i></li>
                                    <li>With counter flight <i>(over the bar)</i>, vault, hecht or salto followed by regrasp on same bar or the other bar</li>
                                    <li>Performed as dismounts</li>
                                </ul>
                                <i>Note: Hop-grip changes with/without 180° - 360° turns do <b>NOT</b> constitute flight.</i>
                            </li>
                            <li>These direct connections can be performed as a:
                                <ul>
                                    <li>Mount connection<br />
                                        <i>(Gr. 1 elements are not considered flight elements)</i><br />
                                        <i>Example:</i> <span className="wag"></span> D + D = 0.10</li>
                                    <li>Connection within the exercise</li>
                                    <li>Dismount connection</li>
                                </ul>
                            </li>
                            <li>If an <b>empty swing</b> or <b>intermediate swing</b> is performed between 2 elements, <span className="changed">or after the second/last element, </span> CV can <i><b><u>NOT</u></b></i> be awarded.<br />
                                <ul>
                                    <li><b>Empty swing</b> = swing fwd or bwd without the execution of an element in the Table, before the swing reverses to the opposite direction.<br />
                        Exception: “Shaposhnikova” type elements with/without 1/1 turn (360°) and the following elements:<br />
                                        <span className="wag changed"></span><span className="wag"></span><br />
                                        <i><b>Note:</b> if kip performed after Shaposhnikova type elements with/without 1/1 turn <span className="changed">and hecht ½ turn </span> - apply deduction for empty swing.</i>
                                    </li>
                                    <li><b>Intermediate swing</b> = pump swing from a front support and/or a long swing not needed in order to perform the next element.</li>
                                </ul>
                            </li>
                        </ol>
                        <h3 id="11_5_Composition_Deductions">11.5 Composition Deductions – E-Panel</h3>
                        <Table className="fit" bordered>
                            <tr className="headerRow center">
                                <th>Faults</th>
                                <th>0.10</th>
                                <th>0.30</th>
                                <th>0.50</th>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Jump from LB to HB</li></ul></td>
                                <td />
                                <td></td>
                                <td className="narrowCol center">X</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Hang on HB, put feet on LB, grasp LB</li></ul></td>
                                <td />
                                <td></td>
                                <td className="narrowCol center">X</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>More than 2 of the same elements directly connected to the dismount</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </Table>
                        <h3 id="11_6_Specific_Apparatus_Deductions">11.6 Specific Apparatus Deductions – E-Panel</h3>
                        <Alert variant="warning">Changes: Brush on mat is back!</Alert>
                        <Table className="fit" bordered>
                            <tr className="headerRow center">
                                <td><strong>Faults</strong><br />
                                    <i>If there is no fall the maximum execution deduction<br />
                       may not exceed 0.80 per element</i></td>
                                <th>0.10</th>
                                <th>0.30</th>
                                <th>0.50<br />
                    or more</th>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Body alignment in HSTD and cast to HSTD</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Adjusted grip position</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Brush on mat</li></ul></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Hit on apparatus with feet</li></ul></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center">0.50</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Hit on mat with feet <i>(fall)</i></li></ul></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center">1.00</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Uncharacteristic element<br />
                                    <i>(elements with take-off 2 feet or thighs)</i></li></ul></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center">0.50</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Poor rhythm in elements</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Insufficient height of flight elements</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Under rotation of flight elements</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Insufficient extension in kips</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Intermediate swing</li></ul></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center">0.50</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Empty swing</li></ul></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center">0.50</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Angle of completion of elements</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                            </tr>
                            <tr className="">
                                <td className=""><strong>Amplitude of:</strong><br />
                                    <ul className="level1Indent">
                                        <li>Swings fwd or bwd under horizontal</li>
                                        <li>Casts</li></ul>
                                </td>
                                <td className="narrowCol center"><br />X<br />X</td>
                                <td className="narrowCol center"><br /><br />X</td>
                                <td className="narrowCol center"></td>
                            </tr>

                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Excessive flexion of hip joint in the leg tap<br />
                                    <i>(DMT)</i></li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                        </Table>
                        <h3 id="11_7_Notes">11.7 Notes</h3>
                        <h4>Falls</h4>
                        <h5>Flight Elements</h5>
                        <ol type="a">
                            <li><b><i>with</i></b> grasp of both hands <li>(momentary hang or support)</li> on the bar
                <ul><li>the DV is awarded.</li></ul></li>
                            <li><b><i>without</i></b> grasp of both hands on the bar
                <ul><li><b><u>No</u></b> DV is awarded<br />
                                    <i>(element may be performed again to receive DV).</i></li></ul>
                            </li>
                        </ol>
                        <h4>Dismounts</h4>
                        <ol type="a">
                            <li>No attempt to DMT at all:<br />
                Evaluation:
                    <ul>
                                    <li>No DV: count 7 elements only (D-Panel)</li>
                                    <li>No DMT: <span className="deduction">– 0.50 (D-Panel)</span></li>
                                    <li>Fall: <span className="deduction">- 1.00 (E-panel)</span></li>
                                    <li>If she remounts to perform DMT, no deduction for “No DMT”</li>
                                </ul>
                            </li>
                            <li>If the dismount has begun:<br />
                                <b>Example 1:</b><span className="wag"></span> with initiation of salto (failure to land feet first)<br />
                Evaluation:<br />
                                <ul>
                                    <li>No DV: count 7 elements only (D-Panel)</li>
                                    <li>Fall: <span className="deduction">- 1.00 (E-panel)</span></li>
                                </ul>
                            </li>
                        </ol>
                        <h4>Mounts</h4>
                        <ol type="a">
                            <li>Can fulfil CR # 1 for flight from HB to LB.</li>
                            <li>Elements performed as mounts can be performed in the exercise (or vice versa) but receive DV only once.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FigWagUbCop;