import React from 'react';
import Button from 'react-bootstrap/Button';

const HistoryTableSymbol = (props) => {
    const { symbol} = props;

    return (
        <Button key={symbol} onClick={() => props.onSymbolClicked(symbol)} style={{ fontFamily: "WAG Symbols", fontSize: "36px", minWidth:"175px" }} variant="outline-dark">{symbol}</Button>
    );
};

export default HistoryTableSymbol;