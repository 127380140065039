import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

const GsNav = () => {
    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();

    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin,
        });

    return (
        <Navbar bg="dark" variant="dark" sticky="top">
            <Navbar.Brand as={Link} to="/">
                <img
                    src=".\gymnastics-software.ico"
                    alt=""
                    loading="lazy"
                    height="30s"
                />
                <span style={{ color: 'rgb(223, 219, 0)' }}>Gymnastics</span>&nbsp;<span style={{ color: 'rgb(135, 136, 138)' }}>Software</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="mainNavBarMenu">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                    {/* <Nav.Link as={Link} to="/products">Products</Nav.Link> */}
                    <NavDropdown title="FIG" id="fig-nav-dropdown">
                        <NavDropdown.Header>Exercise Editors</NavDropdown.Header>
                        <NavDropdown.Item as={Link} to="/wag-vt" disabled>Vault</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/wag-ub" disabled>Uneven Bars</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/wag-bb" disabled>Balance Beam</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/fig-wag-fx" disabled>Floor Exercise</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Header>Element libraries</NavDropdown.Header>
                        <NavDropdown.Item as={Link} to="/fig-wag-vt-library">Library Vault</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/fig-wag-ub-library">Library Uneven Bars</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/fig-wag-bb-library" disabled>Library Balance Beam</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/fig-wag-fx-library">Library Floor Exercise</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Header>Code of Points Versions</NavDropdown.Header>
                        <NavDropdown.Item as={Link} to="/fig-wag-vt-cop">CoP Vault</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/fig-wag-ub-cop">CoP Uneven Bars</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/fig-wag-bb-cop">CoP Balance Beam</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/fig-wag-fx-cop">CoP Floor Exercise</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link as={Link} to="/downloads">Downloads</Nav.Link>
                    <NavDropdown title={isAuthenticated ? user.name : "Account"} id="account-nav-dropdown">
                        {!isAuthenticated && (
                            <NavDropdown.Item as={Link} onClick={() => loginWithRedirect()}>Login or Create Account</NavDropdown.Item>
                        )}
                        {isAuthenticated && (
                            <>
                                <NavDropdown.Item as={Link} onClick={() => logoutWithRedirect()}>Logout</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/profile" disabled>Profile</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/account" disabled>Account</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/redeem" disabled>Redeem license code</NavDropdown.Item>
                            </>
                        )}
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default GsNav;