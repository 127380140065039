import Spacer from './Spacer';
import RenderStyle from './RenderStyle';
import Size from '../WindowsBase/Size';

/** Represents the space between two connected elements in an exercise. */
export default class ConnectedElementSpacer extends Spacer {
    constructor(id) {
        super(id);

        // Give the user more screen area to click between two elements.
        this._extraWidthForClickableArea = RenderStyle.extraWidthForClickableAreaSpacers;

    }

    /** This spacer has a fixed width which is defined in the RenderStyle class. The height needs to be set by the parent panel. */
    measure(ctx) {
        this.constraintSize = new Size(RenderStyle.connectedElementSpacerWidth, 0);
    }

    draw(ctx) {
        if (this.isHovered) {
            ctx.fillStyle = RenderStyle.hoverBackgroundSpacer;
            ctx.fillRect(this.position.x, this.position.y, this.actualSize.width, this.actualSize.height);
        } else {
            ctx.fillStyle = RenderStyle.backgroundSpacer;
            ctx.fillRect(this.position.x, this.position.y, this.actualSize.width, this.actualSize.height);
        }

        super.draw(ctx);

        if (this.hasCursor && this.cursorCurrentlyVisible)
            this.showCursor(ctx);
    }
}
