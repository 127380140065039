import React, { useState, useMemo, useEffect, useCallback } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

const BbLibFilter = (props) => {
    const { filterChanged } = props;

    const noPredefinedFilter = useMemo(() => { return { code: "", name: "None", description: "No predefined filter selected." }; }, []);
    const [predefinedFilter, setPredefinedFilter] = useState(noPredefinedFilter);

    const initialFilterSettings = {
        cop: '2022',
        showDiffOnly: false,

        showGroup0: false,
        showGroup1: true,
        showGroup2: true,
        showGroup3: true,
        showGroup4: true,
        showGroup5: true,
        showGroup6: true,
        showGroupAll: false,

        showDvNone: true,
        showDvA: true,
        showDvB: true,
        showDvC: true,
        showDvD: true,
        showDvE: true,
        showDvF: true,
        showDvG: true,
        showDvH: true,
        showDvAll: true
    }

    const [filterSettings, setFilterSettings] = useState(initialFilterSettings);
    const emptyFilterSettings = useMemo(() => {
        return {
            cop: '2022',
            showDiffOnly: false,

            showGroup0: false,
            showGroup1: false,
            showGroup2: false,
            showGroup3: false,
            showGroup4: false,
            showGroup5: false,
            showGroup6: false,
            showGroupAll: false,

            showDvNone: false,
            showDvA: false,
            showDvB: false,
            showDvC: false,
            showDvD: false,
            showDvE: false,
            showDvF: false,
            showDvG: false,
            showDvH: false,
            showDvAll: false
        }
    }, []);

    const applyPredefinedFilter = (code) => {
        switch (code) {
            case 'reset': setPredefinedFilter({ code: 'reset', name: 'Clear Filter', description: 'Clears all check boxes in the filter pane.' });
                break;

            default: setPredefinedFilter(noPredefinedFilter);
                break;
        }
    }

    // React when the filter changes
    useEffect(
        () => {
            filterChanged(filterSettings);
        }, [filterSettings, filterChanged]
    );

    // React when the predefined filter is changed
    useEffect(
        () => {
            switch (predefinedFilter.code) {
                case 'reset':
                    setFilterSettings(
                        {
                            ...emptyFilterSettings
                        }
                    ); break;
                default:
                    break;
            }

        }, [predefinedFilter, emptyFilterSettings]
    );

    const [filterSpecifiedInUrl] = useState('');

    // Add name and description properties given the code
    const getPredefinedFilterProps = useCallback((code) => {
        switch (code) {
            case 'reset': return { code: 'reset', name: 'Clear Filter', description: 'Clears all check boxes in the filter pane' };

            default: return noPredefinedFilter;
        }
    }, [noPredefinedFilter]);

    // Load the url query string and see if a predefined filter is specified. Apply filter when required.
    useEffect(
        () => {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            const filterFromQueryString = params.get('filter');

            // We only use this parameter when it changes. Because otherwise we would not be able to use the custom filter settings
            // since the page would always go back to the predefined filter during each render
            if (filterFromQueryString && filterSpecifiedInUrl !== filterFromQueryString) {
                setPredefinedFilter(getPredefinedFilterProps(filterFromQueryString))
            }
        }, [filterSpecifiedInUrl, getPredefinedFilterProps]
    );

    const cop2017 = (e) => {
        if (e.target.checked) {
            setFilterSettings({ ...filterSettings, cop: '2017' });
        }
        setPredefinedFilter(noPredefinedFilter);
    }

    const cop2022 = (e) => {
        if (e.target.checked) {
            setFilterSettings({ ...filterSettings, cop: '2022' });
        }
        setPredefinedFilter(noPredefinedFilter);
    }

    const showDiffOnly = (e) => {
        setFilterSettings({ ...filterSettings, showDiffOnly: e.target.checked });
        setPredefinedFilter(noPredefinedFilter);
    }

    const showGroup0 = (e) => { setFilterSettings({ ...filterSettings, showGroup0: e.target.checked, showGroupAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showGroup1 = (e) => { setFilterSettings({ ...filterSettings, showGroup1: e.target.checked, showGroupAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showGroup2 = (e) => { setFilterSettings({ ...filterSettings, showGroup2: e.target.checked, showGroupAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showGroup3 = (e) => { setFilterSettings({ ...filterSettings, showGroup3: e.target.checked, showGroupAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showGroup4 = (e) => { setFilterSettings({ ...filterSettings, showGroup4: e.target.checked, showGroupAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showGroup5 = (e) => { setFilterSettings({ ...filterSettings, showGroup5: e.target.checked, showGroupAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showGroup6 = (e) => { setFilterSettings({ ...filterSettings, showGroup6: e.target.checked, showGroupAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showGroupAll = (e) => {
        setPredefinedFilter(noPredefinedFilter);
        const checked = e.target.checked;

        if (checked) {
            setFilterSettings(
                {
                    ...filterSettings,
                    showGroup0: true,
                    showGroup1: true,
                    showGroup2: true,
                    showGroup3: true,
                    showGroup4: true,
                    showGroup5: true,
                    showGroup6: true,
                    showGroupAll: true
                }
            );
        } else {
            setFilterSettings(
                {
                    ...filterSettings,
                    showGroup0: false,
                    showGroup1: false,
                    showGroup2: false,
                    showGroup3: false,
                    showGroup4: false,
                    showGroup5: false,
                    showGroup6: false,
                    showGroupAll: false
                }
            );
        }
    }

    const showDvNone = (e) => { setFilterSettings({ ...filterSettings, showDvNone: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvA = (e) => { setFilterSettings({ ...filterSettings, showDvA: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvB = (e) => { setFilterSettings({ ...filterSettings, showDvB: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvC = (e) => { setFilterSettings({ ...filterSettings, showDvC: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvD = (e) => { setFilterSettings({ ...filterSettings, showDvD: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvE = (e) => { setFilterSettings({ ...filterSettings, showDvE: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvF = (e) => { setFilterSettings({ ...filterSettings, showDvF: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvG = (e) => { setFilterSettings({ ...filterSettings, showDvG: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvH = (e) => { setFilterSettings({ ...filterSettings, showDvH: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvAll = (e) => {
        setPredefinedFilter(noPredefinedFilter);
        const checked = e.target.checked;

        if (checked) {
            setFilterSettings(
                {
                    ...filterSettings,
                    showDvNone: true,
                    showDvA: true,
                    showDvB: true,
                    showDvC: true,
                    showDvD: true,
                    showDvE: true,
                    showDvF: true,
                    showDvG: true,
                    showDvH: true,
                    showDvAll: true
                }
            );
        } else {
            setFilterSettings(
                {
                    ...filterSettings,
                    showDvNone: false,
                    showDvA: false,
                    showDvB: false,
                    showDvC: false,
                    showDvD: false,
                    showDvE: false,
                    showDvF: false,
                    showDvG: false,
                    showDvH: false,
                    showDvAll: false
                }
            );
        }
    }

    return (
        <>
            <h3>Filters</h3>
            <h4>Predefined filters:</h4>
            <DropdownButton variant='secondary' id="predefined_filters" title={predefinedFilter.name}>
                <Dropdown.Item as="button" id="filter_reset" onClick={() => applyPredefinedFilter('reset')}>Clear filter</Dropdown.Item>
            </DropdownButton>
            <p><b>Filter: </b><i>{predefinedFilter.description}</i></p>
            <h4>Custom filter:</h4>
            <i>(Filter settings apply top to bottom)</i>
            <Form>
                <Form.Group>
                    <Form.Label>Version of the CoP:</Form.Label>
                    <Form.Check type='radio' checked={filterSettings.cop === '2017'} id='filter_cop_2017' onChange={cop2017} label='CoP 2017 - 2021' />
                    <Form.Check type='radio' checked={filterSettings.cop === '2022'} id='filter_cop_2022' onChange={cop2022} label='CoP 2022 - 2024' />
                    <Form.Check type='checkbox' id='filter_showDiffOnly' checked={filterSettings.showDiffOnly} onChange={showDiffOnly} label='Changed elements  only' />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Group:</Form.Label>
                    <Form.Check type='checkbox' id='filter_group_0' checked={filterSettings.showGroup0} onChange={showGroup0} label='General purpose' />
                    <Form.Check type='checkbox' id='filter_group_1' checked={filterSettings.showGroup1} onChange={showGroup1} label='Mounts' />
                    <Form.Check type='checkbox' id='filter_group_2' checked={filterSettings.showGroup2} onChange={showGroup2} label='Leaps, jumps and hops' />
                    <Form.Check type='checkbox' id='filter_group_3' checked={filterSettings.showGroup3} onChange={showGroup3} label='Gymnastic turns' />
                    <Form.Check type='checkbox' id='filter_group_4' checked={filterSettings.showGroup4} onChange={showGroup4} label='Holds and acorbatic non-flight' />
                    <Form.Check type='checkbox' id='filter_group_5' checked={filterSettings.showGroup5} onChange={showGroup5} label='Acrobatic flight' />
                    <Form.Check type='checkbox' id='filter_group_6' checked={filterSettings.showGroup6} onChange={showGroup6} label='Dismounts' />
                    <Form.Check type='checkbox' id='filter_group_all' checked={filterSettings.showGroupAll} onChange={showGroupAll} label='All' />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Difficulty Value (select CoP version above):</Form.Label>
                    <Form.Row>
                        <Col>
                            <Form.Check type='checkbox' id='filter_dv_none' checked={filterSettings.showDvNone} onChange={showDvNone} label='No DV' />
                            <Form.Check type='checkbox' id='filter_dv_a' checked={filterSettings.showDvA} onChange={showDvA} label='A' />
                            <Form.Check type='checkbox' id='filter_dv_b' checked={filterSettings.showDvB} onChange={showDvB} label='B' />
                        </Col>
                        <Col>
                            <Form.Check type='checkbox' id='filter_dv_c' checked={filterSettings.showDvC} onChange={showDvC} label='C' />
                            <Form.Check type='checkbox' id='filter_dv_d' checked={filterSettings.showDvD} onChange={showDvD} label='D' />
                            <Form.Check type='checkbox' id='filter_dv_e' checked={filterSettings.showDvE} onChange={showDvE} label='E' />
                        </Col>
                        <Col>
                            <Form.Check type='checkbox' id='filter_dv_f' checked={filterSettings.showDvF} onChange={showDvF} label='F' />
                            <Form.Check type='checkbox' id='filter_dv_g' checked={filterSettings.showDvG} onChange={showDvG} label='G' />
                            <Form.Check type='checkbox' id='filter_dv_h' checked={filterSettings.showDvH} onChange={showDvH} label='H' />
                            <Form.Check type='checkbox' id='filter_dv_all' checked={filterSettings.showDvAll} onChange={showDvAll} label='All' />
                        </Col>
                    </Form.Row>
                </Form.Group>
            </Form>
        </>
    );
}

export default BbLibFilter;