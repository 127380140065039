import { v4 as uuidv4 } from 'uuid';

/** wagml Templates for new exercises */
const newWagmlExerciseVt = '<?xml version="1.0" encoding="utf-8"?> <we:exercise fileformatVersion="1.0.0.0" cop="Fig-2017" apparatus="VT" xmlns:we="http://www.gymnastics-software.com/WAG-Exercises"> <we:exercise.properties> <we:gymnastName></we:gymnastName> <we:gymnastNumber></we:gymnastNumber> <we:gymnastCountry></we:gymnastCountry> </we:exercise.properties> <we:performance> </we:performance> <we:evaluation> <we:execution> <we:executionValue value="0.000" /> <we:artistryValue value="0.0" /> <we:penaltyValue value="0.0" /> </we:execution> </we:evaluation> </we:exercise>';
const newWagmlExerciseUb = '<?xml version="1.0" encoding="utf-8"?> <we:exercise fileformatVersion="1.0.0.0" cop="Fig-2017" apparatus="UB" xmlns:we="http://www.gymnastics-software.com/WAG-Exercises"> <we:exercise.properties> <we:gymnastName></we:gymnastName> <we:gymnastNumber></we:gymnastNumber> <we:gymnastCountry></we:gymnastCountry> </we:exercise.properties> <we:performance> <we:cursor /> </we:performance> <we:evaluation> <we:execution> <we:executionValue value="0.000" /> <we:artistryValue value="0.0" /> <we:penaltyValue value="0.0" /> </we:execution> </we:evaluation> </we:exercise>';
const newWagmlExerciseBb = '<?xml version="1.0" encoding="utf-8"?> <we:exercise fileformatVersion="1.0.0.0" cop="Fig-2017" apparatus="BB" xmlns:we="http://www.gymnastics-software.com/WAG-Exercises"> <we:exercise.properties> <we:gymnastName></we:gymnastName> <we:gymnastNumber></we:gymnastNumber> <we:gymnastCountry></we:gymnastCountry> </we:exercise.properties> <we:performance> <we:cursor /> </we:performance> <we:evaluation> <we:execution> <we:executionValue value="0.000" /> <we:artistryValue value="0.0" /> <we:penaltyValue value="0.0" /> </we:execution> </we:evaluation> </we:exercise>';
const newWagmlExerciseFx = '<?xml version="1.0" encoding="utf-8"?> <we:exercise fileformatVersion="1.0.0.0" cop="Fig-2017" apparatus="FX" xmlns:we="http://www.gymnastics-software.com/WAG-Exercises" xmlns:editor="http://www.gymnastics-software.com/WAG-Exercises/editor"> <we:exercise.properties> <we:gymnastName></we:gymnastName> <we:gymnastNumber></we:gymnastNumber> <we:gymnastCountry></we:gymnastCountry> </we:exercise.properties> <we:performance> <we:cursor editor:cursorPosition="middleOfSpacer" /> </we:performance> <we:evaluation> <we:execution> <we:executionValue value="0.000" /> <we:artistryValue value="0.0" /> <we:penaltyValue value="0.0" /> </we:execution> </we:evaluation> </we:exercise>';

/** Empty data object that holds all scoreslip values */
const emptyScoreSlipData = {
    cop: '',
    apparatusCode: '',
    apparatusSymbol: '',
    gymnastNumber: '',
    gymnastName: '',
    gymnastCountry: '',
    executionDeduction: 0,
    artisticDeductions: 0,
    penaltyDeductions: 0,
    difficultyValue: 0,
    compositionRequirementsValue: 0,
    cr_1_value: 0,
    cr_2_value: 0,
    cr_3_value: 0,
    cr_4_value: 0,
    connectionValue: 0,
    difficultyScore: 0,
    executionScore: 0,
    finalScore: 0,
    A_acroCount: 0,
    A_danceCount: 0,
    A_total: 0,
    B_acroCount: 0,
    B_danceCount: 0,
    B_total: 0,
    C_acroCount: 0,
    C_danceCount: 0,
    C_total: 0,
    D_acroCount: 0,
    D_danceCount: 0,
    D_total: 0,
    E_acroCount: 0,
    E_danceCount: 0,
    E_total: 0,
    F_acroCount: 0,
    F_danceCount: 0,
    F_total: 0,
    G_acroCount: 0,
    G_danceCount: 0,
    G_total: 0,
    H_acroCount: 0,
    H_danceCount: 0,
    H_total: 0,
    I_acroCount: 0,
    I_danceCount: 0,
    I_total: 0
};

/** Returns a score slip object that can be used when creating a new score slip. */
export function getEmptyScoreSlipData() {
    return emptyScoreSlipData;
}

/** Recursive function that looks at all attributes, values and children. 
 *  When it finds data that belongs in the scoreslip dataset, it adds the value to the data object */
const parseXmlElementForScoreSlipData = (element, data) => {
    console.log('Parsing element: ', element);
    const elementName = element.nodeName;
    const elementValue = element.textContent;
    //console.log(`Name: [${elementName}]`, `Value: [${elementValue}]`);

    // Prevent that the page html is being parsed. This should not happen unless an invalid wagml was parsed by DOMParser.
    if (elementName === 'html')
        return null;

    // Process the attributes
    for (let i = 0; i < element.attributes.length; i++) {
        console.log(`attribute[${i}]`, `attribute name: [${element.attributes[i].nodeName}]`, `attribute value: [${element.attributes[i].nodeValue}]`, element.attributes[i]);

        const attributeName = element.attributes[i].nodeName;
        const attributeValue = element.attributes[i].nodeValue;

        switch (elementName) {
            case 'we:exercise':
                switch (attributeName) {
                    case 'cop': data.cop = attributeValue; break;
                    case 'apparatus':
                        data.apparatusCode = attributeValue;
                        switch (attributeValue) {
                            case 'VT':
                                data.apparatusSymbol = '';
                                break;
                            case 'UB':
                                data.apparatusSymbol = '';
                                break;
                            case 'BB':
                                data.apparatusSymbol = '';
                                break;
                            case 'FX':
                                data.apparatusSymbol = '';
                                break;
                            default:
                        } break;
                    default:
                } break;
            default:
        }
    }

    // Process the element
    switch (elementName) {
        case 'we:gymnastName': data.gymnastName = elementValue; break;
        case 'we:gymnastNumber': data.gymnastNumber = elementValue; break;
        case 'we:gymnastCountry': data.gymnastCountry = elementValue; break;

        case 'we:executionValue': data.executionDeduction = elementValue; break;
        case 'we:artistryValue': data.artisticDeductions = elementValue; break;
        case 'we:penaltyValue': data.penaltyDeductions = elementValue; break;
        default:
    }

    // Process the child elements
    for (let i = 0; i < element.childNodes.length; i++) {
        console.log(`childNode[${i}]`, `node type [${element.childNodes[i].nodeType}]`, element.childNodes[i].nodeName);

        if (element.childNodes[i].nodeType === 1)
            parseXmlElementForScoreSlipData(element.childNodes[i], data);
    }
}

/** Parses a wagml document and returns the information that must be displayed on a scoreslip (not the exercise itself). */
export function scoreSlipDataFromWagml(wagml) {
    let data = getEmptyScoreSlipData();

    if (!wagml || wagml === '')
        return data;

    const parser = new DOMParser();
    const dom = parser.parseFromString(wagml, "application/xml");
    const root = dom.childNodes[0];

    parseXmlElementForScoreSlipData(root, data);

    return data;
}

/** Returns the we:performance xml element */
export function getPerformanceElement(wagmlDom) {
    const exercise = wagmlDom.childNodes[0];

    for (let i = 0; i < exercise.childNodes.length; i++) {
        if (exercise.childNodes[i].nodeType === 1 && exercise.childNodes[i].nodeName === "we:performance") {
            return exercise.childNodes[i];
        }
    }

    return null;
}

/** Returns a new exercise wagml document */
export function getEmptyExercise(apparatusCode) {
    switch (apparatusCode) {
        case 'VT':
            return newWagmlExerciseVt;
        case 'UB':
            return newWagmlExerciseUb;
        case 'BB':
            return newWagmlExerciseBb;
        case 'FX':
            return newWagmlExerciseFx;
        default:
            throw new Error(`Unknown apparatus code: [${apparatusCode}]`);
    }
}

/** Convert an wagml string into a dom object (parse the xml) */
const parseWagml = (wagml) => {
    const parser = new DOMParser();
    return parser.parseFromString(wagml, "application/xml");
}

/** XML Document back to string conversion */
const XMLtoString = (elem) => {

    var serialized;

    try {
        // XMLSerializer exists in current Mozilla browsers
        const serializer = new XMLSerializer();
        serialized = serializer.serializeToString(elem);
    }
    catch (e) {
        // Internet Explorer has a different approach to serializing XML
        serialized = elem.xml;
    }

    return serialized;
}

/** Returns the xml element that represents the cursor.
 * Throws an exception when no cursor was found.
 */
const getCursor = (dom) => {
    const cursors = dom.getElementsByTagName("we:cursor");
    if (null === cursors)
        throw new Error("The wagml contains no cursor.");

    if (0 === cursors.length)
        throw new Error("The wagml document has no cursor.");

    if (1 < cursors.length)
        throw new Error("The wagml has more than one cursor.");

    return cursors[0];
}

/** Returns true if the cursor element was found. Otherwise false. */
const hasCursor = (dom) => {
    const cursors = dom.getElementsByTagName("we:cursor");

    if (cursors && cursors.length === 1)
        return true;

    return false;
}

const addCursor = (dom) => {
    const performance = getPerformanceElement(dom);
    const cursor = dom.createElement("we:cursor");
    cursor.setAttribute('id', uuidv4());

    performance.appendChild(cursor);
}

/** Add a new symbol to the wagml (at the location of the cursor) */
export function addKeyStroke(originalWagml, keystroke) {
    if (!originalWagml)
        return;

    if (!keystroke)
        return;

    if (keystroke === '')
        return;

    // Get the DOM
    const dom = parseWagml(originalWagml);

    // Get the cursor
    const cursor = getCursor(dom);

    // Create the new element
    const newElement = dom.createElement("we:element");
    newElement.setAttribute("code", keystroke);
    newElement.setAttribute("id", uuidv4());

    // Insert the symbol before the cursor
    cursor.parentElement.insertBefore(newElement, cursor);

    const xmlString = XMLtoString(dom);

    return xmlString;
}

const getNextXmlElement = (node) => {
    let nextSibling = node.nextSibling;
    while (null !== nextSibling && nextSibling.nodeType !== 1) {
        nextSibling = nextSibling.nextSibling;
    }

    // Check if this was the last element in a connection. If so, return the next sibling of the connection
    if (null === nextSibling && null !== node.parentElement && node.parentElement.nodeName === 'we:connection') {
        nextSibling = node.parentElement.nextSibling;
        while (null !== nextSibling && nextSibling.nodeType !== 1) {
            nextSibling = nextSibling.nextSibling;
        }
    }

    return nextSibling;
}

const getPreviousXmlElement = (node) => {
    let prevSibling = node.previousSibling;
    while (null !== prevSibling && prevSibling.nodeType !== 1) {
        prevSibling = prevSibling.previousSibling;
    }

    return prevSibling;
}

const getPreviousWagElement = (node) => {
    let prevSibling = node.previousSibling;
    if (prevSibling.nodeName !== 'we:connection') {
        while (null !== prevSibling && prevSibling.nodeType !== 1 && prevSibling.nodeName !== 'we:element') {
            prevSibling = prevSibling.previousSibling;
        }
    }

    if (null !== prevSibling && prevSibling.nodeName === 'we:connection') {
        prevSibling = prevSibling.lastChild;

        while (null !== prevSibling && prevSibling.nodeType !== 1 && prevSibling.nodeName !== 'we:element') {
            prevSibling = prevSibling.previousSibling;
        }
    }

    return prevSibling;

}

const getNextWagElement = (node) => {
    let nextXmlElement = getNextXmlElement(node);
    if (null === nextXmlElement)
        return null;

    if (nextXmlElement.nodeName === 'we:element')
        return nextXmlElement;

    if (nextXmlElement.nodeName === 'we:connection') {
        nextXmlElement = nextXmlElement.firstChild;
        while (null !== nextXmlElement && nextXmlElement.nodeType !== 1 && nextXmlElement.nodeName !== 'we:element') {
            nextXmlElement = nextXmlElement.nextSibling;
        }

        return nextXmlElement;
    }

    return null;
}

export function moveCursorLeft(originalWagml) {
    if (!originalWagml)
        return;

    // Get the DOM
    const dom = parseWagml(originalWagml);
    console.log('moveCursorLeft dom = ', dom);

    // Get the cursor
    const cursor = getCursor(dom);
    const cursorPosition = cursor.getAttribute('editor:cursorPosition');

    // Possible cases:
    //  1) cursor is the first element in the exercise
    //  2) cursor is the last element in the exercise 
    //  3) cursor is at the end of a connection
    //  4) cursor is between two elements in a connection
    //  3) cursor is at the end of the exercise => do nothing
    //  5) cursor is right before the start of a connection => move the cursor in the connection before the first element

    const previousElement = getPreviousXmlElement(cursor);
    const previousWagElement = getPreviousWagElement(cursor);
    const nextElement = getNextXmlElement(cursor);
    const nextWagElement = getNextWagElement(cursor);
    //const inSameConnection = cursor.parentElement === (nextElement ? nextElement.parentElement : null);

    let prevAndNextInSameConnection = false;
    if (null !== previousElement && null !== nextElement && previousElement.parentElement.nodeName === 'we:connection') {
        prevAndNextInSameConnection = previousElement.parentElement === nextElement.parentElement;
    }

    console.log('CURSOR to the LEFT -  wagml before = ', XMLtoString(dom));

    //  1) cursor is the first element in the exercise
    if (null === previousElement) {
        if (cursorPosition === 'endOfSpacer') {
            // The cursor is in a position where typing new symbols will result in the symbols being part of the connection
            // Moving the cursor to the left from here, will result in a cursor position where typing means adding non-connected symbols.
            cursor.setAttribute('editor:cursorPosition', 'middleOfSpacer');
        }

        return XMLtoString(dom);
    }

    //  2) cursor is the last element in the exercise
    if (null === nextElement) {
        if (cursorPosition === 'middleOfSpacer') {
            // The cursor is in a position where typing new symbols will result in the symbols not being part of the connection
            // Moving the cursor to the left from here, will result in a cursor position where typing means adding connected symbols.
            if (null !== previousWagElement) {
                const idPreviousElement = previousWagElement.getAttribute('id');
                return moveCursorAfterElement(originalWagml, idPreviousElement);
            }
        } else {
            // The cursor is after the last element, but within a connection
            if (null !== previousWagElement) {
                const idPreviousElement = previousWagElement.getAttribute('id');
                return moveCursorBeforeElement(originalWagml, idPreviousElement);
            }
        }

        return originalWagml;
    }

    //  3) cursor is at the end of a connection
    if (null !== cursor.parentElement && cursor.parentElement.nodeName === 'we:connection' && null === cursor.nextElementSibling) {
        if (null !== previousElement) {
            const idPreviousElement = previousElement.getAttribute('id');
            return moveCursorBeforeElement(originalWagml, idPreviousElement);
        }

        return originalWagml;
    }

    //  4) cursor is between two elements in a connection
    if (prevAndNextInSameConnection) {
        return moveCursorBeforeElement(originalWagml, previousElement.getAttribute('id'));
    }

    //  3) cursor is at the end of the exercise => do nothing
    if (null === nextWagElement) {
        console.log('CURSOR to the LEFT -  Cursor is at the end of the exercise. Do nothing.');
        console.log('CURSOR to the LEFT -  wagml after = ', XMLtoString(dom));
        return XMLtoString(dom);
    }

    //  5) cursor is right before the start of a connection => move the cursor in the connection before the first element
    if (null !== nextElement && nextElement.nodeName === 'we:connection' && null != nextWagElement) {
        console.log('CURSOR to the LEFT -  Cursor is in front of the start of a connection. Move it as first element in that connection.');
        nextElement.insertBefore(cursor, nextWagElement);
        console.log('CURSOR to the LEFT -  wagml after = ', XMLtoString(dom));
        return XMLtoString(dom);
    }

    return XMLtoString(dom);
}

export function moveCursorRight(originalWagml) {
    if (!originalWagml)
        return;

    // Get the DOM
    const dom = parseWagml(originalWagml);

    // Get the cursor
    const cursor = getCursor(dom);

    // Possible cases:
    //  1) cursor is the first element in the exercise => move it after the first we:element
    //  2) cursor is before an element => move the cursor after the element
    //  3) cursor is at the end of the exercise => do nothing
    //  4) cursor is at the end of a connection => move the cursor after the connection but before the next element/connection
    //  5) cursor is right before the start of a connection => move the cursor in the connection before the first element

    const previousElement = getPreviousXmlElement(cursor);
    const nextElement = getNextXmlElement(cursor);
    const nextWagElement = getNextWagElement(cursor);
    const inSameConnection = cursor.parentElement === (nextElement ? nextElement.parentElement : null);

    console.log('CURSOR to the RIGHT -  wagml before = ', XMLtoString(dom));

    //  1) cursor is the first element in the exercise => move it after the first we:element
    if (null === previousElement) {
        console.log('CURSOR to the RIGHT -  No previous element. Move the cursor after the first element.');
        nextWagElement.parentElement.insertBefore(cursor, nextWagElement.nextSibling);
        console.log('CURSOR to the RIGHT -  wagml after = ', XMLtoString(dom));
        return XMLtoString(dom);
    }

    //  2) cursor is before an element (and in the same connection if in a connection) => move the cursor behind the element
    if (null !== nextElement && nextElement.nodeName === 'we:element' && inSameConnection) {
        console.log('CURSOR to the RIGHT -  Cursor is before an element. Move it behind that element.');
        nextWagElement.parentElement.insertBefore(cursor, nextWagElement.nextSibling);
        console.log('CURSOR to the RIGHT -  wagml after = ', XMLtoString(dom));
        return XMLtoString(dom);
    }

    //  3) cursor is at the end of the exercise => do nothing
    if (null === nextWagElement) {
        console.log('CURSOR to the RIGHT -  Cursor is at the end of the exercise. Do nothing.');
        console.log('CURSOR to the RIGHT -  wagml after = ', XMLtoString(dom));
        return XMLtoString(dom);
    }

    //  4) cursor is at the end of a connection => move the cursor after the connection but before the next element/connection
    if (null !== cursor.parentElement && cursor.parentElement.nodeName === 'we:connection' && null !== cursor.parentElement.parentElement) {
        console.log('CURSOR to the RIGHT -  Cursor is at the end of a connection. Move it behind the connection');
        const connection = cursor.parentElement;
        const performance = connection.parentElement;
        performance.insertBefore(cursor, connection.nextSibling);
        console.log('CURSOR to the RIGHT -  wagml after = ', XMLtoString(dom));
        return XMLtoString(dom);
    }

    //  5) cursor is right before the start of a connection => move the cursor in the connection before the first element
    if (null !== nextElement && nextElement.nodeName === 'we:connection' && null != nextWagElement) {
        console.log('CURSOR to the RIGHT -  Cursor is in front of the start of a connection. Move it as first element in that connection.');
        nextElement.insertBefore(cursor, nextWagElement);
        console.log('CURSOR to the RIGHT -  wagml after = ', XMLtoString(dom));
        return XMLtoString(dom);
    }

    return XMLtoString(dom);
}


/** Prepares a wagml file that was opened from file.
 *  - assigns an id to every element in the performance, if no id's were in the file.
 *  - adds the editor namespace
 *  - sets the cop version to the one specified in the input parameter
 */
export function prepareWagmlFromFile(original, copVersion) {
    const dom = parseWagml(original);

    // Set the editor namespace
    const rootNode = dom.childNodes[0];
    rootNode.setAttribute('xmlns:editor', 'http://www.gymnastics-software.com/WAG-Exercises/editor');

    // Set the cop version to the one specified in the input parameters
    rootNode.setAttribute('cop', copVersion);

    const performance = getPerformanceElement(dom);

    // Add [id] to all elements (every xml element, so also the connections)
    const prepareElement = (node) => {
        if (node.nodeType === 1) {
            const attributeId = node.getAttribute('id');
            if (!attributeId) {
                node.setAttribute('id', uuidv4());
            }
        }

        // Prepare child nodes
        for (let i = 0; i < node.childNodes.length; i++) {
            prepareElement(node.childNodes[i]);
        }
    };

    for (let i = 0; i < performance.childNodes.length; i++) {
        prepareElement(performance.childNodes[i]);
    }

    // Check if the document has a cursor. If not, add one.
    if (!hasCursor(dom))
        addCursor(dom);

    const preparedWagml = XMLtoString(dom);

    return preparedWagml;
}

export function getEvaluationSuccess(wagml) {
    const dom = parseWagml(wagml);

    const success = dom.getElementsByTagName("we:evaluationSuccess");
    if (success && success.length === 1 && success[0].innerHTML === "true")
        return true;

    return false;
}

export function getEvaluationFailureReason(wagml) {
    const dom = parseWagml(wagml);

    const reason = dom.getElementsByTagName("we:evaluationFailureReason");
    if (reason && reason.length === 1)
        return reason[0].innerHTML;

    return "";
}

/** Returns the node with the specified id. Null is returned if the node was not found */
function findElementById(dom, id) {
    const performance = getPerformanceElement(dom);
    let returnNode = null;

    const findNode = (node) => {
        if (node.nodeType === 1) {
            const attributeId = node.getAttribute('id');
            if (attributeId === id) {
                returnNode = node;
                return;
            }
        }

        // Check child nodes
        for (let i = 0; i < node.childNodes.length; i++) {
            findNode(node.childNodes[i]);
            if (null != returnNode)
                return;
        }
    };

    for (let i = 0; i < performance.childNodes.length; i++) {
        findNode(performance.childNodes[i]);
        if (null != returnNode)
            return returnNode;
    }

    return returnNode;
}

/** Move the cursor after the element referenced by idElement. Stay in the same connection as the element. */
export function moveCursorAfterElement(original, idElement) {
    const dom = parseWagml(original);
    const cursor = getCursor(dom);
    const elementById = findElementById(dom, idElement);

    if (null === elementById)
        return;

    const parent = elementById.parentElement;
    parent.insertBefore(cursor, elementById.nextSibling);
    cursor.setAttribute('editor:cursorPosition', 'startOfSpacer');

    const updatedWagml = XMLtoString(dom);
    return updatedWagml;
}

/** Move the cursor after the element referenced by idElement. Jump out the connection the element is in. */
export function moveCursorAfterElementConnection(original, idElement) {
    const dom = parseWagml(original);
    const cursor = getCursor(dom);
    const elementById = findElementById(dom, idElement);

    console.log(`MOVE CURSOR after connection Element [${idElement}] -  wagml before = `, XMLtoString(dom));

    if (null === elementById)
        throw new Error(`No element found with id [${idElement}]`);;

    const parent = elementById.parentElement;
    if (parent.nodeName === 'we:connection') {
        parent.parentElement.insertBefore(cursor, parent.nextSibling);
        cursor.setAttribute('editor:cursorPosition', 'middleOfSpacer');
    } else if (parent.nodeName === 'we:performance') {
        parent.insertBefore(cursor, elementById.nextSibling);
        cursor.setAttribute('editor:cursorPosition', 'middleOfSpacer');
    } else {
        throw new Error(`Unexpected parent for element [${idElement}]. Expected we:connection or we:performance. Actual: ${parent.nodeName}`);
    }

    const updatedWagml = XMLtoString(dom);
    console.log('wagml.moveCursorAfterElementConnection(). DOM = ', dom);
    console.log(`MOVE CURSOR after connection Element  [${idElement}] -  wagml after = `, updatedWagml);

    return updatedWagml;
}

/** Move the cursor before the element referenced by idElement. Stay in the same the connection the element is in. */
export function moveCursorBeforeElement(original, idElement) {
    const dom = parseWagml(original);
    const cursor = getCursor(dom);
    const elementById = findElementById(dom, idElement);

    console.log(`MOVE CURSOR before Element [${idElement}] -  wagml before = `, XMLtoString(dom));

    if (null === elementById)
        throw new Error(`No element found with id [${idElement}]`);;

    const parent = elementById.parentElement;
    parent.insertBefore(cursor, elementById);
    cursor.setAttribute('editor:cursorPosition', 'endOfSpacer');

    const updatedWagml = XMLtoString(dom);
    console.log('wagml.moveCursorBeforeElement(). DOM = ', dom);
    console.log(`MOVE CURSOR before Element  [${idElement}] -  wagml after = `, updatedWagml);

    return updatedWagml;
}

/** Move the cursor before the element referenced by idElement. Jump out the connection the element is in. */
export function moveCursorBeforeElementConnection(original, idElement) {
    const dom = parseWagml(original);
    const cursor = getCursor(dom);
    const elementById = findElementById(dom, idElement);

    console.log('MOVE CURSOR before Element Connection -  wagml before = ', XMLtoString(dom));

    if (null === elementById)
        throw new Error(`No element found with id [${idElement}]`);;

    const parent = elementById.parentElement;
    if (parent.nodeName === 'we:connection') {
        parent.parentElement.insertBefore(cursor, parent.previousSibling);
        cursor.setAttribute('editor:cursorPosition', 'middleOfSpacer');
    } else if (parent.nodeName === 'we:performance') {
        parent.insertBefore(cursor, elementById.previousSibling);
        cursor.setAttribute('editor:cursorPosition', 'middleOfSpacer');
    } else {
        throw new Error(`Unexpected parent for element [${idElement}]. Expected we:connection or we:performance. Actual: ${parent.nodeName}`);
    }

    const updatedWagml = XMLtoString(dom);

    console.log('MOVE CURSOR before Element Connection -  wagml after = ', updatedWagml);

    return updatedWagml;
}
