import CanvasUIElement from './CanvasUIElement';

/** Represents the space before the first element in an exercise. */
export default class Spacer extends CanvasUIElement {
    constructor(id) {
        super(id);

        this._hasCursor = false;
    }

    /** Gets or sets a flag indicating whether this spacer has the cursor on it. */
    get hasCursor() { return this._hasCursor }
    set hasCursor(hasCursor) { this._hasCursor = hasCursor; }

    /** The measure() function needs to be implemented by the derived class. */
    measure(ctx) {
        throw new Error('Measure() must be implemented in the derived class.');
    }

    /** The spacer itself is not visible. */
    draw(ctx) {
    }

    /** Don't show the borders of a spacer. */
    drawComponentBounds(ctx) {
    }
}