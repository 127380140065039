import React from 'react';

const WagUb = () => {
    return (
        <>
            <h1>Uneven Bars</h1>
        </>
    );
}

export default WagUb;