/** Describes the thickness of a frame around a rectangle. Four Double values describe the Left, Top, Right, and Bottom sides of the rectangle, respectively. */
export default class Thickness {
    constructor(uniformLength) {
        this._left = uniformLength;
        this._top = uniformLength;
        this._right = uniformLength;
        this._bottom = uniformLength;
    }

    get left() { return this._left; }
    set left(left) { this._left = left; }

    get top() { return this._top; }
    set top(top) { this._top = top; }

    get right() { return this._right; }
    set right(right) { this._right = right; }

    get bottom() { return this._bottom; }
    set bottom(bottom) { this._bottom = bottom; }
}
