
export const initialWebUserState = {
    name: "Anonymous",
    email: null,
    nickname: null,
    avatar: null,
    fullName: null,
    firstName: null,
    lastName: null,
    packages: [],
    listOfCops: [],
    selectedCop: null,
    shoppingCart: { items: [] },
};
