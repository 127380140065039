import CanvasScoreSlip from './CanvasScoreSlip';
import CanvasExercise from './CanvasExercise';
import Size from '../WindowsBase/Size';
import Tooltip from './Tooltip';

/** Represents the complete scoreslip + exercise. */
export default class WagmlCanvas {
    constructor(dispatchWagml) {
        this._dispatchWagml = dispatchWagml;

        // An array of CanvasUIElement objects
        this._components = [];

        this._canvasScoreSlip = new CanvasScoreSlip(this._layoutSettings);
        this._canvasExercise = new CanvasExercise(dispatchWagml);

        this._wagmlText = '';
        this._wagmlDom = null;
        this._canvasContext = null;
        this._canvasSize = new Size(0, 0);
    }

    get dispatchWagml() { return this._dispatchWagml; }

    get ctx() { return this._canvasContext; }
    set ctx(context) {
        this._canvasContext = context;

        this.canvasExercise.ctx = this.ctx;
        this.canvasScoreSlip.ctx = this.ctx;
    }

    get components() { return this._components; }

    get canvasSize() { return this._canvasSize; }
    set canvasSize(size) { this._canvasSize = size; }

    get canvasScoreSlip() { return this._canvasScoreSlip; }
    get canvasExercise() { return this._canvasExercise; }

    get wagmlDom() { return this._wagmlDom; }
    set wagmlDom(wagmlDom) {
        this._wagmlDom = wagmlDom;

        this.canvasExercise.wagmlDom = null;
        this.canvasScoreSlip.exercisePropertiesDom = null;
        this.canvasScoreSlip.evaluationDom = null;

        if (!this.wagmlDom)
            return;

        const exercise = this.wagmlDom.childNodes[0];
        if (!exercise)
            throw new Error("The wagml document has no root node.");

        if (exercise.nodeName !== "we:exercise")
            throw new Error(`The wagml document root node name is invalid: ${exercise.nodeName} (expected we:exercise)`);

        // Dispatch the child xml elements to their respective class       
        for (let i = 0; i < exercise.childNodes.length; i++) {
            if (exercise.childNodes[i].nodeType === 1 && exercise.childNodes[i].nodeName === "we:performance") {
                this.canvasExercise.performanceDom = exercise.childNodes[i];
            } else if (exercise.childNodes[i].nodeType === 1 && exercise.childNodes[i].nodeName === "we:exercise.properties") {
                this.canvasScoreSlip.exercisePropertiesDom = exercise.childNodes[i];
            } else if (exercise.childNodes[i].nodeType === 1 && exercise.childNodes[i].nodeName === "we:evaluation") {
                this.canvasScoreSlip.evaluationDom = exercise.childNodes[i];
            }
        }
    }

    get wagmlText() { return this._wagmlText; }
    set wagmlText(text) {
        this._wagmlText = text;

        if (this.wagmlText) {
            const parser = new DOMParser();
            const newDom = parser.parseFromString(this.wagmlText, "application/xml");
            const parserErrors = newDom.getElementsByTagName('parsererror');
            if (parserErrors && 0 < parserErrors.length) {
                throw new Error('Error parsing wagml document!');
            }

            this.wagmlDom = newDom;
        } else {
            this.wagmlDom = null;
        }

        // A new wagml means possible new UI elements.
        this.createAllComponents();
    }

    /** Clear the canvas */
    clearCanvas() {
        if (0 === this.canvasSize.height || 0 === this.canvasSize.width)
            return;

        // Clear the canvas
        if (this.ctx) {
            this.ctx.clearRect(0, 0, this.canvasSize.width, this.canvasSize.height);
        }
    }

    createAllComponents() {
        this.createTooltip();
        this.canvasScoreSlip.createAllComponents();
        this.canvasExercise.createAllComponents();
    }

    createTooltip() {
        const newComponent = new Tooltip();
        this.components.push(newComponent);

    }

    /** Render the scoreslip and exercise */
    render() {
        this.clearCanvas();
        this.canvasScoreSlip.render();
        this.canvasExercise.render();

        // Measure own UI elements
        for (let i = 0; i < this.components.length; i++)
        {
            const component = this.components[i];
            component.measure(this.ctx);        
            component.actualSize.width = component.constraintSize.width;
            component.actualSize.height = component.constraintSize.height;
        }

        // Arrange own UI elements
        for (let i = 0; i < this.components.length; i++)
        {
            const component = this.components[i];
            component.position.x = 0;
            component.position.y = 0;
        }

        // Render own UI elements
        for (let i = 0; i < this.components.length; i++) {
            this.components[i].draw(this.ctx);
        }
    }

    /** Not the content has changed, but maybe the screen was resized. */
    updateLayout() {
        this.canvasExercise.updateLayout();
    }

    /** Called when the canvas receives or looses the focus. */
    setFocus(focus) {
        if (focus) {
            this.canvasExercise.showCursor = true;
        } else {
            this.canvasExercise.showCursor = false;
        }
    }

    /** Deal with the mouse being over the canvas. If parts need to be redrawn because of the mouse 
     *  being over an object, then this function will take care of it. 
     *  The collection of hovered UI Elements is returned.
     */
    onMouseMove(mousePosition, e) {
        if (this.canvasScoreSlip.onMouseMove(mousePosition, e))
            return true;
        if (this.canvasExercise.onMouseMove(mousePosition, e))
            return true;

        // If not processed, show the default mouse cursor
        e.target.style.cursor = 'text';
    }

    onMouseLeftButtonClick(mousePosition) {
        this.canvasScoreSlip.onMouseLeftButtonClick(mousePosition);
        this.canvasExercise.onMouseLeftButtonClick(mousePosition);
    }

    onMouseMiddleButtonClick(mousePosition) {
        this.canvasScoreSlip.onMouseMiddleButtonClick(mousePosition);
        this.canvasExercise.onMouseMiddleButtonClick(mousePosition);
    }

    onMouseRightButtonClick(mousePosition) {
        this.canvasScoreSlip.onMouseRightButtonClick(mousePosition);
        this.canvasExercise.onMouseRightButtonClick(mousePosition);
    }

    /** Deal with mouse left button click event. If parts need to be redrawn because of the mouse 
     *  being over an object, then this function will take care of it. */
}