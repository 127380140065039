export default class FigCopClient {

    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    async CreateNewWagml(accessToken, copVersion) {
        let url = this.baseUrl + 'CreateNewWagml';

        if (copVersion)
            url += `?copVersion=${copVersion}`;
        let data = {};
        if (accessToken) {
            data = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            };
        }
        else {
            data = {
                method: 'POST',
                mode: 'cors',
            };
        }

        try {
            const response = await fetch(url, data);
            if (response.ok) {
                const xml = await response.text();
                return xml;
            } else {
                throw response;
            }
        } catch (e) {
            console.log('ERROR: ' + e);
        }
    }

    /** As development continues, older versions of the wagml may mbe missing in new stuff (such as the cursor).
     *  This method makes sure that the wagml is in sync with the latest code.
     */
    async UpgradeWagmlToLatestVersion(accessToken, wagml) {
        let url = this.baseUrl + `UpgradeWagmlToLatestVersion`;
        let data = {};
        if (accessToken) {
            data = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'text/xml',
                },
                body: wagml
            };
        }
        else {
            data = {
                method: 'POST',
                mode: 'cors',
                headers: new Headers(
                    {
                        'Content-Type': 'text/xml'
                    }),
                body: wagml
            };
        }

        try {
            const response = await fetch(url, data);
            if (response.ok) {
                const xml = await response.text();
                return xml;
            } else {
                throw response;
            }
        } catch (e) {
            throw e;
        }
    }

    /** Get the history of all VT elements. For vault, no license is needed, everybode can see all vault elements. */
    async GetVtLibrary() {
        let url = this.baseUrl + `GetVtLibrary`;
        const data = {
            method: 'GET',
            mode: 'cors'
        };

        try {
            const response = await fetch(url, data);
            if (response.ok) {
                const json = await response.text();
                return json;
            } else {
                throw response;
            }
        } catch (e) {
            throw e;
        }
    }

    /** Get the history of all UB elements. If not licensed, the returned data contains only A, B, C elements. */
    async GetUbLibrary(accessToken) {
        let url = this.baseUrl + `GetUbLibrary`;
        let data = {};
        if (accessToken) {
            data = {
                method: 'GET',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            };
        }
        else {
            data = {
                method: 'GET',
                mode: 'cors'
            };
        }

        try {
            const response = await fetch(url, data);
            if (response.ok) {
                const json = await response.text();
                return json;
            } else {
                throw response;
            }
        } catch (e) {
            throw e;
        }
    }

    /** Get the history of all BB elements. If not licensed, the returned data contains only A, B, C elements. */
    async GetBbLibrary(accessToken) {
        let url = this.baseUrl + `GetBbLibrary`;
        let data = {};
        if (accessToken) {
            data = {
                method: 'GET',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            };
        }
        else {
            data = {
                method: 'GET',
                mode: 'cors'
            };
        }

        try {
            const response = await fetch(url, data);
            if (response.ok) {
                const json = await response.text();
                return json;
            } else {
                throw response;
            }
        } catch (e) {
            throw e;
        }
    }

    /** Get the history of all FX elements. If not licensed, the returned data contains only A, B, C elements. */
    async GetFxLibrary(accessToken) {
        let url = this.baseUrl + `GetFxLibrary`;
        let data = {};
        if (accessToken) {
            data = {
                method: 'GET',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            };
        }
        else {
            data = {
                method: 'GET',
                mode: 'cors'
            };
        }

        try {
            const response = await fetch(url, data);
            if (response.ok) {
                const json = await response.text();
                return json;
            } else {
                throw response;
            }
        } catch (e) {
            throw e;
        }
    }

    /** Evaluate the exercise. The wagml is read to determine the cop version and apparatus. The access token is used to verify whether
     *  or not the user has access to the full cop or to a limited set.
     */
    async ProcessExercise(accessToken, wagml) {
        let url = this.baseUrl + `ProcessExercise`;
        let data = {};
        if (accessToken) {
            data = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'text/xml',
                },
                body: wagml
            };
        }
        else {
            data = {
                method: 'POST',
                mode: 'cors',
                headers: new Headers(
                    {
                        'Content-Type': 'text/xml'
                    }),
                body: wagml
            };
        }

        try {
            const response = await fetch(url, data);
            if (response.ok) {
                const xml = await response.text();
                console.log("Evaluated exercise: ", xml);
                return xml;
            } else {
                throw response;
            }
        } catch (e) {
            throw e;
        }
    }

    async AddKeystroke(accessToken, wagml, keystroke) {
        let url = this.baseUrl + `AddSymbol?symbol=${keystroke}`;
        let data = {};
        if (accessToken) {
            data = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'text/xml',
                },
                body: wagml
            };
        }
        else {
            data = {
                method: 'POST',
                mode: 'cors',
                headers: new Headers(
                    {
                        'Content-Type': 'text/xml'
                    }),
                body: wagml
            };
        }

        try {
            const response = await fetch(url, data);
            if (response.ok) {
                const xml = await response.text();
                return xml;
            } else {
                throw response;
            }
        } catch (e) {
            throw e;
        }
    }

    async ProcessKeyboardEvent(accessToken, eventName, wagml) {
        let url = this.baseUrl;
        switch (eventName) {
            case '--move-cursor-left':
                url += 'MoveCursorToTheLeft'; break;
            case '--move-cursor-right':
                url += 'MoveCursorToTheRight'; break;
            case '--move-cursor-home':
                url += 'MoveCursorToHome'; break;
            case '--move-cursor-end':
                url += 'MoveCursorToEnd'; break;
            case '--backspace':
                url += 'DeleteSymbolBeforeCursor'; break;
            default:
                throw new Error('Unknown keyboard event: ' + eventName);
        }

        let data = {};
        if (accessToken) {
            data = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'text/xml',
                },
                body: wagml
            };
        }
        else {
            data = {
                method: 'POST',
                mode: 'cors',
                headers: new Headers(
                    {
                        'Content-Type': 'text/xml'
                    }),
                body: wagml
            };
        }

        try {
            const response = await fetch(url, data);
            if (response.ok) {
                const xml = await response.text();
                return xml;
            } else {
                throw response;
            }
        } catch (e) {
            throw e;
        }
    }
}