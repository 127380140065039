import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useReducer, useState } from 'react';
import WebUserContext from './WebUserContext';
import { initialWebUserState } from './WebUserState';
import { reducer } from './reducer';
import WebUserClient from './WebUserClient';

const baseUrlWebUserApi = process.env.REACT_APP_API_BASE_URL;

const WebUserProvider = (props) => {
    const {
        children
    } = props;

    // Get the client for the WebUser API
    const [client] = useState(
        () => new WebUserClient(baseUrlWebUserApi)
    );

    // Get the current Auth0 state
    const authState = useAuth0();

    // Setup a reducer to help manage state
    const [state, dispatch] = useReducer(reducer, initialWebUserState);

    // Handle changes Auth0 or in the WebUser api
    useEffect(() => {
        (async () => {
            // When the Auth0 state changed, reload the latest info from Auth0 into the WebUser state
            // All react components that call useWebUser() will be rerendered because of this.
            dispatch({
                type: 'AuthStateChanged',
                authState: { authState }
            });

            // Changes in the authorization state affects the data the user can work with, so 
            // reload everything for the new logged-on or logged-off user.
            try {
                let accessToken = null;
                if (authState.isAuthenticated) {
                    accessToken = await authState.getAccessTokenSilently();
                }

                const copsString = await client.ListOfCop(accessToken);
                const cops = JSON.parse(copsString);
                const packages = cops.CopPackages;

                const listOfCops = packages.map((p) => ({ code: p.Code, displayName: p.DisplayName, description: p.Description }));

                dispatch({
                    type: 'COPS',
                    cops: packages,
                    listOfCops: listOfCops,
                });
            } catch (error) {
                dispatch({
                    type: 'ERROR',
                    error: error
                });

            }

        })();
    }, [authState, client]);

    return (
        <WebUserContext.Provider
            value={{
                ...state,
                dispatch,
            }}>
            {children}
        </WebUserContext.Provider>
    );
};

export default WebUserProvider;