import Size from './Size';
import Point from './Point';

/** Describes the width, height, and location of a rectangle. */
export default class Rect {
    constructor(x, y, width, height) {
        this._x = x;
        this._y = y;
        this._width = width;
        this._height = height;
    }

    get x() { return this._x; }
    set x(x) { this._x = x; }

    get y() { return this._y; }
    set y(y) { this._y = y; }

    get width() { return this._width; }
    set width(width) { this._width = width; }

    get height() { return this._height; }
    set height(height) { this._height = height; }

    get bottom() { return this.y + this.height; }
    get bottomLeft() { return new Point(this.x, this.y + this.height); }
    get bottomRight() { return new Point(this.x + this.width, this.y + this.height); }
    get left() { return this.x; }
    get right() { return this.x + this.width; }
    get size() { return new Size(this.width, this.height); }
    set size(size) { if (this.isEmpty) throw new Error("Cannot set size on an empty rectangle."); this.width = size.width; this.height = size.height; }
    get top() { return this.y; }
    get topLeft() { return new Point(this.x, this.y); }
    get topRight() { return new Point(this.x + this.width, this.y); }

    get location() { return new Point(this.x, this.y); }
    set location(point) { this.x = point.x; this.y = point.y; }

    static get empty() { return new Rect(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER); }
    get isEmpty() { return this.x === Rect.empty.x && this.y === Rect.empty.y && this.width === Rect.empty.width && this.height === Rect.empty.height; }

    containsPoint(point) { if (this.isEmpty) return false; return this.left <= point.x && this.right >= point.x && this.top <= point.y && this.bottom >= point.y; }
    containsXY(x, y) { if (this.isEmpty) return false; return this.left <= x && this.right >= x && this.top <= y && this.bottom >= y; }

    /** Indicates whether the rectangle contains the specified rectangle. Returns true if rect is entirely contained by the rectangle; otherwise, false. */
    containsRect(rect) { if (this.isEmpty) return false; return this.left <= rect.left && this.right >= rect.right && this.top <= rect.top && this.bottom >= rect.bottom; }
}
