import React, { useReducer } from 'react';
import WagmlContext from './WagmlContext';
import { initialStateFx } from './WagmlState';
import { wagmlReducer } from './WagmlReducer';

const WagmlProvider = (props) => {
    const {
        children
    } = props;

    // Setup a reducer to help manage state
    const [state, dispatchWagml] = useReducer(wagmlReducer, initialStateFx);

    return (
        <WagmlContext.Provider
            value={{
                ...state,
                dispatchWagml,
            }}>
            {children}
        </WagmlContext.Provider>
    );
};

export default WagmlProvider;