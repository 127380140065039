import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Home = () => {
    const borderStyle =
        { border: '1px solid #000' }


    return (
        <Container fluid>
            <Row>
                <Col>
                    <h1>Software Tools for Women's Artistic Gymnastics</h1>
                    <h2>WAG Symbols Font</h2>
                    <p>With this font you can use WAG Symbols in your own documents just like you use regular characters. In the <strong>FIG </strong>
                    menu you find an element library page for all four apparatuses. Clicking a symbol will copy it to the clipboard. From there you
                    can paste it into your own document.
                    </p>
                    <p>The font files can be downloaded from the <a href='/downloads' alt='download'>download page</a>. After downloading the file(s), you must install them on your computer.
                    Whenever new elements are added, or shapes of exising symbols change, we will update the font files. To get the latest version of the font,
                    uninstall the version on our computer prior to installing the latest version.
                    </p>
                    <i>Every symbol was manually designed using Fontlab:</i><br />
                    <img src="/images/WAG_Symbols_Font_Glyph.png" alt="WAG Symbols Glyph" Height='250' style={borderStyle} /><br />
                    Two font files are released for the general public and are free:
                    <ul>
                        <li>WAG Symbols</li>
                        <li>WAG Symbols Bold</li>
                    </ul>
                    <p><strong>WAG Symbols Bold</strong> is the original font developed in 2006. Because it was the first time I designed a font, the symbols look a bit too 'fat'.
                    However this font is the only font that contains every WAG symbol that was ever defined in the WAG character map (2603 in total).</p>
                    <p><strong>WAG Symbols</strong> is the second font that was developed. The symbols are more slim, but not every symbol was included in this font (1705 in total).
                    If you have a document that contains a WAG symbol that is not supported by <strong>WAG Symbols</strong>, you will see the boxed WAG character: </p>
                    <p><strong>A third font</strong> is in development and is a font based on the handwriting of an international judge.</p>
                    <img src="/images/Fonts.png" alt="WAG Symbols Glyph" style={borderStyle} /><br />
                    <br /><br />
                    <h2>Library of WAG Elements</h2>
                    <p>This site has for every apparatus a page of elements. The number of elements that are shown is limited in the free version.
                    If you have acquired a license, you will have access to all elements in the current and future CoP.</p>
                    <p>
                        Use these pages to find symbols that you want to copy into your own document (clicking a symbol copies it onto the clipboard).</p>
                    <p>
                        The library also has a filter section where you can specify what type of elements you want to see in the list. Predefined filters
                    help you find the list of elements that are referenced by a particular rule in the CoP.</p>
                    <ul>
                        <li><a href="/fig-wag-vt-library">Vault library</a></li>
                        <li><a href="/fig-wag-ub-library">Uneven Bars library</a></li>
                        <li><a href="/fig-wag-bb-library">Balance Beam library</a></li>
                        <li><a href="/fig-wag-fx-library">Floor Exercise library</a></li>
                    </ul>
                    <br /><br />
                    <h2>Online Exercise Editor</h2>
                    <p><strong>The online editor tool is still under development</strong></p>
                    <p>On this website - in the future - you will find an online tool for displaying and calculating exercises for Women Artistic Gymnastics using the CoP 2017-2021 and the CoP 2022-2024.</p>
                    <p>There will be a free version of the editor which will be limited to elements with DV A, B and C.</p>
                    <p>If you acquire a license, all limitations will be removed.</p>
                    <p>The tool will be compatible with files saved using the old <strong>GS Judge</strong> application.</p>
                    <i>Screenshot of the old GS Judge application:</i><br/>
                    <img src="/images/GS_Judge_screenshot.png" alt="GS Judge application" style={borderStyle} height='25%' /><br />
                </Col>
                <Col>
                </Col>
            </Row>
        </Container>
    );
};

export default Home;