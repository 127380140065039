import './Cop.css';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';

const FigWagFxCop = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-3 sidenav position-fixed">
                        <div id="toc_container">
                            <p className="toc_title">Contents</p>
                            <p className="toc_title"><a href="#CoP_2022_2024">CoP 2022 - 2024</a></p>
                            <ul className="toc_list">
                                <li><a href="#13_1_General">13.1 General</a>
                                    <ul>
                                        <li><a href="#13_1_General_Music">a) Music Requirements</a></li>
                                        <li><a href="#13_1_General_Timing">b) Timing</a></li>
                                        <li><a href="#13_1_General_Border">c) Border Markings</a></li>
                                    </ul>
                                </li>
                                <li><a href="#13_2_Content_of_the_Exercise">13.2 Content of the Exercise</a>
                                    <ul>
                                        <li><a href="#13_2_Content_of_the_Exercise_Acro_Lines">a) Acro Lines</a></li>
                                    </ul>
                                </li>
                                <li><a href="#13_3_Composition_Requirements">13.3 Composition Requirements (CR)</a>
                                </li>
                                <li><a href="#13_4_Connection_Value">13.4 Connection Value (CV)</a>
                                </li>
                                <li><a href="#13_5_Artistry_Composition_Deductions">13.5 Artistry &amp; Composition Deductions</a>
                                </li>
                                <li><a href="#13_6_Specific_Apparatus_Deductions">13.6 Specific Apparatus Deductions</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-9 offset-3">
                        <h1><span style={{ fontFamily: "WAG Symbols", fontSize: "72px" }}></span>Code of Points Floor Exercise</h1>
                        <h2 id="CoP_2022_2024">CoP 2022 - 2024, Section 13 - Floor Exercise</h2>
                        <h3 id="13_1_General">13.1 General</h3>
                        <h4 id="13_1_General_Music">a) Music Requirements</h4>
                        <Alert variant="warning">No more references to CD (compact disk) as audio storage.<br /> A clarification regarding songs</Alert>
                        <ul>
                            <li>The Floor exercise music will be given to the competition administration. Each piece of music will be timed; said time will be approved by the administration and the delegation head coach.</li>
                            <li>The following must be included:
                                <ul>
                                    <li>The name of the gymnast and the 3 capitalised letters used by FIG for the country code.</li>
                                    <li>The name of the composer and the title of the music.</li>
                                </ul>
                            </li>
                            <li>The musical accompaniment with orchestration, piano or other instruments must be recorded.
                                <ul>
                                    <li>A signal or tone may be used at the beginning of the recording. However, the name of the gymnast may <b><u>not</u></b> be spoken.</li>
                                    <li>The human voice may be used as a musical instrument without words:
                                        <ul>
                                            <li>Examples of the ‘human voice’ as an acceptable instrument are: humming, vocalising without words, whistling, chanting.</li>
                                            <li><span className="added">Any music which is not clearly a song or part of a song is accepted.</span></li>
                                        </ul>
                                    </li>
                                    <li>Absence of music or music with words <span className="deduction"> – 1.00 </span><br />
                                        <i>Note: Taken by the D-Panel from the Final Score.</i></li>
                                </ul>
                                In case of doubt, the federation/gymnast may submit music to the WTC for evaluation.
                            </li>
                        </ul>
                        <h4 id="13_1_General_Timing">b) Timing</h4>
                        <Alert variant="warning">'Assistant' is replaced by 'Time Judge'</Alert>
                            The evaluation of the exercise begins with the first movement of the gymnast. The duration of the exercise may not exceed 1:30 minutes (90 seconds).
                        <ul>
                            <li>The <span className="changed">Time Judge</span> begins timing when the gymnast begins with the first movement of her exercise.</li>
                            <li>The <span className="changed">Time Judge</span> stops timing when the gymnast ends her exercise with the last position. The exercise must end with the music.</li>
                            <li>The deduction for overtime will be taken if the exercise is 1:31 minutes (91 seconds) or more <span className="deduction"> – 0.10 </span>.</li>
                            <li>Elements performed after the 90 second time limit will be recognised by the D-Panel and evaluated by the E-Panel.</li>
                        </ul>
                        <h4 id="13_1_General_Border">c) Border Markings</h4>
                        <div>
                            Exceeding the prescribed floor area (12 x 12 m), that is touching the floor with any part of the body outside of the border markings, will result in a deduction:
            <ul>
                                <li>One step or landing outside of the boundary with a foot or hand <span className="deduction"> – 0.10</span></li>
                                <li>Step(s) outside with both feet, both hands or a body part or landing with both feet outside <span className="deduction"> – 0.30 </span></li>
                            </ul>
                        </div>
                        <div>
                            Both time and line violations are reported in writing by the respective Time and Line Judge to the D-Panel, who then take the deduction from the Final Score.
            </div>
                        <h3 id="13_2_Content_of_the_Exercise">13.2 Content of the Exercise</h3>
                        <div>
                            The maximum 8 highest difficulties including the dismount are counted for DV.
            <ul>
                                <li>No DMT <span className="deduction">– 0.50 </span> from Final Score (D-Panel).</li>
                            </ul>
                        </div>
                        <div>
                            Within the 8 counting elements there must be a minimum of:
                <ul>
                                <li>3 Dance</li>
                                <li>3 Acro</li>
                            </ul>
                and 2 optional elements
            </div>
                        <div>
                            The dismount is the last counting acro line (<i>credit highest DV</i>)
                <ul>
                                <li>No DMT will be credited if only one acro line is performed.</li>
                            </ul>
                        </div>
                        <h4 id="13_2_Content_of_the_Exercise_Acro_Lines">a) Acro Lines</h4>
                        <ul>
                            <li>The maximum number of acro lines is 4.</li>
                            <li>Any difficulty in additional acro line(s) will not be counted for DV.</li>
                            <li>Any acro element performed after the last counting acro line will not be counted for DV.</li>
                            <li>An acro line consists of a minimum of 2 directly connected flight elements one of which must be a salto.</li>
                            <ul>
                                <li>Failure to land on the feet first from a salto will still be considered an acro line.</li>
                            </ul>
                        </ul>

                        <h3 id="13_3_Composition_Requirements">13.3 Composition Requirements (CR) – D-Panel 2.00</h3>
                        <ol>
                            <li>
                                A dance passage composed of <u>two</u> different leaps or hops (from the Code) connected directly or indirectly
                    <i>(with running steps, small leaps, hops, chassé, chainé turns)</i>,
                   s one of them with 180° split <i>(cross or side)</i> or straddle position <span className="award">award 0.50</span><br />
                    (The objective is to create a large flowing and traveling movement pattern)
                    <ul>
                                    <li>No jumps or turns are permitted because they are stationary. Chainé turns (½ turns on two feet) are allowed because they are traveling steps.</li>
                                    <li>Leaps and hops must land on one leg if performed as the 1st element in the dance passage.</li>
                                </ul>
                            </li>
                            <li>
                                Salto with LA turn (min. 360°) <span className="award">award 0.50</span>
                            </li>
                            <li>Salto with double BA <span className="award">award 0.50</span></li>
                            <li>Salto bwd and salto fwd (no aerials) in the same or different acro line <span className="award">award 0.50</span>
                            </li>
                        </ol>
                        <i><b>Note:</b> CR 2, 3 and 4 must be performed within acro lines.</i>

                        <h3 id="13_4_Connection_Value">13.4 Connection Value (CV) – D-Panel</h3>
                        <ol type="a">
                            <li>CV can be awarded for indirect (acrobatic) and direct (acrobatic, mixed, turns) connections.</li>
                            <li>The CV will be added into the D-Score.</li>
                        </ol>
                        <p>Formulas for indirect and direct connections</p>
                        <Alert variant="danger">REMOVED:<br />B + B (no step)<br />Turns may be performed on the same support leg (brief demi-plié on support leg is permitted)</Alert>
                        <Table className="center fit" bordered>
                            <tr className="headerRow">
                                <th colspan="2">INDIRECT ACROBATIC</th>
                            </tr>
                            <tr className="inversed">
                                <th>0.10</th>
                                <th>0.20</th>
                            </tr>
                            <tr className="narrowRow">
                                <td>B/C + D</td>
                                <td>C + E<br />D + D</td>
                            </tr>
                            <tr className="narrowRow">
                                <td>A + A + D</td>
                                <td>A + A + E</td>
                            </tr>
                            <tr className="headerRow">
                                <th colspan="2">DIRECT ACROBATIC</th>
                            </tr>
                            <tr className="narrowRow">
                                <td>A + D</td>
                                <td>A + E</td>
                            </tr>
                            <tr className="narrowRow">
                                <td>C + C</td>
                                <td>C + D</td>
                            </tr>
                            <tr className="headerRow">
                                <th colspan="2">MIXED</th>
                            </tr>
                            <tr className="narrowRow">
                                <td>D salto + B (dance)<br />
                        E salto + A (dance)<br />
                                    <i>(this order only)</i></td>
                                <td></td>
                            </tr>
                            <tr className="headerRow">
                                <th colspan="2">CONNECTION of TURNS on ONE LEG</th>
                            </tr>
                            <tr className="narrowRow">
                                <td>D + B</td>
                                <td><i><b>Note:</b></i> Turns may be performed with <br />step into turn on opposite leg<br /><i>(demi-plié is not permitted)</i></td>
                            </tr>
                        </Table>

                        <p><i><b>Indirect connections</b> are those in which directly connected acrobatic
                        elements with flight phase and hand support (i.e. round-off, flic-flac, etc. as
                        preparatory elements) are performed between saltos.</i>
                        </p>
                        <p><b><i>Note:</i></b> Acro elements for CV are without hand support only.
                        </p>
                        <h3 id="13_5_Artistry_Composition_Deductions">13.5 Artistry &amp; Composition Deductions – E-Panel</h3>
                        <Alert variant="warning">Multiple changes in the table of deductions for artistry and composition!<br />
                                Some deductions have switched place with section 13.6 (specific apparatus deductions)</Alert>
                        <Table className="fit" bordered>
                            <tr className="headerRow">
                                <th>Faults</th>
                                <th className="center">0.10</th>
                                <th className="center">0.30</th>
                            </tr>
                            <tr>
                                <th colspan="3">Artistry of Performance</th>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Insufficient artistry of performance throughout the entire <br />exercise:</li></ul></td>
                                <td /><td />
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Poor body posture (head, shoulders, trunk)</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Insufficient amplitude (maximum elongation of the <br />movements)</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Poor foot work</li></ul></td>
                                <td className="narrowCol center"></td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level3Indent" ><li>Feet not pointed/relaxed/turned in</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Insufficient involvement of the body parts</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Poor expressive engagement according to the style of <br />the music</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Performance of the entire exercise as a series of <br />disconnected elements &amp; movements (lack of fluency)</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr>
                                <th colspan="3">Composition</th>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Insufficient complexity or creativity of movements:<br /><i>(A complex and creative movement is one that requires<br />training time, coordination and previous preparation)</i></li></ul></td>
                                <td /><td />
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Throughout the exercise</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Poor choreography in the corner/lack of variety</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Missing movement touching floor <i>(including minimum<br />trunk, or thigh, or knee or head)</i></li></ul></td>
                                <td /><td />
                            </tr>
                            <tr>
                                <th colspan="3">Music and Musicality</th>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Editing of music <i>(e.g. no opening, ending, or accents)</i>:</li></ul></td>
                                <td /><td />
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>No structure to the music</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Musicality:</li></ul></td>
                                <td /><td />
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Lack of synchronisation between movement and<br />musical beat at the end of exercise</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center"></td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Background music (the exercise is connected to the<br />music partly or only at the beginning and end of the<br />exercise)</li></ul></td>
                                <td className="narrowCol center">X</td>
                                <td className="narrowCol center">X</td>
                            </tr>
                        </Table>

                        <h3 id="13_6_Specific_Apparatus_Deductions">13.6 Specific Apparatus Deductions – E-Panel</h3>
                        <Alert variant="warning">Multiple changes in the table of deductions for artistry and composition!<br />
            Some deductions have switched place with section 13.5 (artistry &amp; composition deductions)</Alert>
                        <Table className="fit" bordered hover>
                            <tr className="headerRow">
                                <th>Faults</th>
                                <th>0.10</th>
                            </tr>
                            <tr>
                                <th colspan="2">Excessive preparation</th>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem"><li>Adjustment (steps without choreography)</li></ul></td>
                                <td className="narrowCol">ea X</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem"><li>Excessive arm swing before dance elements</li></ul></td>
                                <td className="narrowCol">ea X</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem"><li>Pause <i>(apply at 2 seconds)</i></li></ul></td>
                                <td className="narrowCol">ea X</td>
                            </tr>
                            <tr>
                                <th colspan="2">Distribution of elements</th>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem"><li>Exercise starts immediately with an acro line/acro element</li></ul></td>
                                <td className="narrowCol">&nbsp;&nbsp;&nbsp;X</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem"><li>Subsequent acro line performed after previous line along the same diagonal <br />without choreography in between (long acro line allowed)</li></ul></td>
                                <td className="narrowCol">ea X</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem"><li>More than 1 subsequent acro line</li></ul></td>
                                <td className="narrowCol">ea X</td>
                            </tr>
                            <tr className="narrowRow">
                                <td className="narrowCol"><ul className="narrowListItem"><li>Exercise ends with acro element (no choreography after last acro)</li></ul></td>
                                <td className="narrowCol">&nbsp;&nbsp;&nbsp;X</td>
                            </tr>
                        </Table>

                        <h4>Acro Lines &amp; Dismounts</h4>
                        <ol type="a">
                            <li>
                                Only one acro line<br />
                                <strong>Example 1 </strong><span className="wag">()</span><br />
                    Evaluation:
                    <ul>
                                    <li>No DV ==&gt; count max. 7 elements <i>(D-Panel)</i></li>
                                    <li>No DMT ==&gt;  – 0.50 <i>(D-Panel)</i></li>
                                    <li>Apply landing deductions <i>(E-Panel)</i></li>
                                </ul>
                                <strong>Example 2 </strong><span className="wag">() ()</span> or <span className="wag">() ()</span><br />
                    Evaluation:
                    <ul>
                                    <li>No DV ==&gt; count max. 7 elements <i>(D-Panel)</i></li>
                                    <li>No DMT ==&gt;  – 0.50 <i>(D-Panel)</i></li>
                                    <li>Apply landing deductions <i>(E-Panel)</i></li>
                                </ul>
                            </li>
                            <li>
                                Two acro Lines<br />
                                <strong>Example 3 </strong><span className="wag">() () ()</span><br />
                    Evaluation:
                    <ul>
                                    <li>Credit DMT <i>(D-Panel)</i></li>
                                </ul>
                                <strong>Example 4 </strong><span className="wag">() () Fall ()</span><br />
                    The gymnast fails to land feet first in the 2nd acro line<br />
                    Evaluation:
                    <ul>
                                    <li>No DV ==&gt; count max. 7 elements <i>(D-Panel)</i></li>
                                    <li>Fall – 1.00 <i>(E-Panel)</i></li>
                                </ul>
                            </li>
                            <li>
                                With repetition of the same element<br />
                                <strong>Example 5 </strong><span className="wag">() () (Fall)</span><br />
                    D + D + X (not completed)<br />
                    Evaluation:
                    <ul>
                                    <li>No DV ==&gt; count max. 7 elements <i>(D-Panel)</i></li>
                                    <li>Apply landing deductions if necessary <i>(E-Panel)</i></li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FigWagFxCop;