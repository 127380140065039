import './WagKeyboardFx.css';
import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Button, Table } from "react-bootstrap";
import useWebUser from '../../Services/WebUser/useWebUser';

const WagKeyboardFx = (props) => {
    const { selectedCop } = useWebUser();
    const [keyboardHtml, setKeyboardHtml] = useState(null);

    useEffect(() => {
        if (selectedCop) {

            let keyboardData = selectedCop.FloorExercise.Keyboard;
            if (!keyboardData)
                return;

            let tabs = keyboardData.Tabs;
            if (!tabs || tabs.length === 0)
                return;

            let keyId = 0;
            let tabsHtml = [];

            for (let t = 0; t < tabs.length; t++) {
                let highestDV = 0;
                let tab = tabs[t];
                let keys = tab.Keys.filter(k => k.Group === (t + 1));

                let keysHtml_A = [];
                let keysHtml_B = [];
                let keysHtml_C = [];
                let keysHtml_D = [];
                let keysHtml_E = [];
                let keysHtml_F = [];
                let keysHtml_G = [];
                let keysHtml_H = [];
                let keysHtml_I = [];

                for (let k = 0; k < keys.length; k++) {
                    let key = keys[k];
                    keyId++;

                    if (key.Column > highestDV) {
                        highestDV = key.Column;
                    }

                    let keyArray = null;
                    switch (key.Column) {
                        case 1: keyArray = keysHtml_A; break;
                        case 2: keyArray = keysHtml_B; break;
                        case 3: keyArray = keysHtml_C; break;
                        case 4: keyArray = keysHtml_D; break;
                        case 5: keyArray = keysHtml_E; break;
                        case 6: keyArray = keysHtml_F; break;
                        case 7: keyArray = keysHtml_G; break;
                        case 8: keyArray = keysHtml_H; break;
                        case 9: keyArray = keysHtml_I; break;
                        default:
                            keyArray = keysHtml_A;
                    }

                    keyArray.push(
                        <Button
                            className="keyboard-button"
                            key={keyId}
                            variant="outline-dark"
                            onClick={() => { props.onKeyboardClicked(key.KeyStroke); }}>
                            {key.KeyStroke}
                        </Button>
                    );
                }
                tabsHtml.push(
                    <Tab eventKey={tab.Name} title={tab.Name} key={tab.Name}>
                        <Table>
                            <thead>
                                <tr>
                                    {highestDV >= 1 ? <th>A</th> : null}
                                    {highestDV >= 2 ? <th>B</th> : null}
                                    {highestDV >= 3 ? <th>C</th> : null}
                                    {highestDV >= 4 ? <th>D</th> : null}
                                    {highestDV >= 5 ? <th>E</th> : null}
                                    {highestDV >= 6 ? <th>F</th> : null}
                                    {highestDV >= 7 ? <th>G</th> : null}
                                    {highestDV >= 8 ? <th>H</th> : null}
                                    {highestDV >= 9 ? <th>I</th> : null}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {highestDV >= 1 ? <td>{keysHtml_A}</td> : null}
                                    {highestDV >= 2 ? <td>{keysHtml_B}</td> : null}
                                    {highestDV >= 3 ? <td>{keysHtml_C}</td> : null}
                                    {highestDV >= 4 ? <td>{keysHtml_D}</td> : null}
                                    {highestDV >= 5 ? <td>{keysHtml_E}</td> : null}
                                    {highestDV >= 6 ? <td>{keysHtml_F}</td> : null}
                                    {highestDV >= 7 ? <td>{keysHtml_G}</td> : null}
                                    {highestDV >= 8 ? <td>{keysHtml_H}</td> : null}
                                    {highestDV >= 9 ? <td>{keysHtml_I}</td> : null}
                                </tr>
                            </tbody>
                        </Table>
                    </Tab>);
            }

            setKeyboardHtml(tabsHtml);
        }
    }, [selectedCop, props]
    );

    return (
        <>
            <Tabs>
                {keyboardHtml}
            </Tabs>
        </>
    );
};

export default WagKeyboardFx;