export const SansSerif12 = '12px Helvetica, Arial, sans-serif';
export const SansSerif14 = '14px Helvetica, Arial, sans-serif';
export const SansSerif16 = '16px Helvetica, Arial, sans-serif';
export const SansSerif17 = '18px Helvetica, Arial, sans-serif';
export const SansSerif20 = '20px Helvetica, Arial, sans-serif';
export const SansSerif22 = '22px Helvetica, Arial, sans-serif';
export const SansSerif24 = '24px Helvetica, Arial, sans-serif';
export const SansSerif26 = '26px Helvetica, Arial, sans-serif';
export const SansSerif28 = '28px Helvetica, Arial, sans-serif';
export const SansSerif30 = '30px Helvetica, Arial, sans-serif';
export const SansSerif96 = '96px Helvetica, Arial, sans-serif';

export const Wag14_Tokyo = '14px WAG Symbols Tokyo';
export const Wag18_Tokyo = '18px WAG Symbols Tokyo';
export const Wag22_Tokyo = '22px WAG Symbols Tokyo';
export const Wag26_Tokyo = '26px WAG Symbols Tokyo';
export const Wag30_Tokyo = '30px WAG Symbols Tokyo';
export const Wag34_Tokyo = '34px WAG Symbols Tokyo';
export const Wag38_Tokyo = '38px WAG Symbols Tokyo';
export const Wag42_Tokyo = '42px WAG Symbols Tokyo';
export const Wag50_Tokyo = '50px WAG Symbols Tokyo';
export const Wag60_Tokyo = '60px WAG Symbols Tokyo';
export const Wag70_Tokyo = '70px WAG Symbols Tokyo';
export const Wag80_Tokyo = '80px WAG Symbols Tokyo';
export const Wag90_Tokyo = '90px WAG Symbols Tokyo';
export const Wag100_Tokyo = '100px WAG Symbols Tokyo';

export const Wag14 = '14px WAG Symbols';
export const Wag18 = '18px WAG Symbols';
export const Wag22 = '22px WAG Symbols';
export const Wag26 = '26px WAG Symbols';
export const Wag30 = '30px WAG Symbols';
export const Wag34 = '34px WAG Symbols';
export const Wag38 = '38px WAG Symbols';
export const Wag42 = '42px WAG Symbols';
export const Wag50 = '50px WAG Symbols';
export const Wag60 = '60px WAG Symbols';
export const Wag70 = '70px WAG Symbols';
export const Wag80 = '80px WAG Symbols';
export const Wag90 = '90px WAG Symbols';
export const Wag100 = '100px WAG Symbols';