import React from 'react';
import WagmlProvider from '../Services/Wagml/WagmlProvider';
import FigWagFx from './FigWagFx';

const FigWagFxParent = () => {
    return (
        <WagmlProvider>
            <FigWagFx />
        </WagmlProvider>
    );
}

export default FigWagFxParent;