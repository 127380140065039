import React from 'react';

const WagVt = () => {
    return (
        <>
            <h1>Vault</h1>
        </>
    );
}

export default WagVt;