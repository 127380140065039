import React from 'react';
import useWebUser from '../../Services/WebUser/useWebUser';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const CopDropdown = () => {
    const { listOfCops, dispatch, selectedCop } = useWebUser();

    const onSelect = (e) => {
        // When the user selects another cop from the list, dispatch the change to the context
        dispatch({
            type: 'UPDATE_SELECTED_COP',
            selectedCopCode: e
        });        
    }

    return (
        <>
            <DropdownButton id="dropdown-cop" variant="secondary" title={selectedCop ? selectedCop.DisplayName : ""}>
                {
                    listOfCops &&
                    listOfCops.map(c => {
                        return (
                            <Dropdown.Item
                                key={c.code}
                                eventKey={c.code}
                                onSelect={onSelect}>
                                {c.displayName}
                            </Dropdown.Item>
                        )
                    })
                }
            </DropdownButton>
        </>
    );
}

export default CopDropdown;