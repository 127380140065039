import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FigCopClient from '../Services/FigCop/FigCopClient';
import VtLibFilter from '../Components/VtLibFilter/VtLibFilter';
import VtLibElements from '../Components/VtLibElements/VtLibElements';

const baseUrlWebUserApi = process.env.REACT_APP_API_BASE_URL;

const FigWagVtLibrary = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filterSettings, setFilterSettings] = useState();

    // Get the client for the WebUser API
    const [client] = useState(
        () => new FigCopClient(baseUrlWebUserApi)
    );

    // (Re)load data from Azure when authentication changes
    useEffect(
        () => {
            (async () => {
                const json = await client.GetVtLibrary();
                const fxData = JSON.parse(json);
                setData(fxData);
            })();
        }
        , [client]);

    // React to changes in data and/or filter settings
    useEffect(
        () => {
            const ft = filterSettings;

            /** Filter the list of elements using the selected filter options */
            const filterData = (data) => {
                if (!ft)
                    return data;

                const filterByGroup = ft.showGroup0 || ft.showGroup1 || ft.showGroup2 || ft.showGroup3 || ft.showGroup4
                    || ft.showGroup5 || ft.showGroupAll;

                let source = data;
                let filtered = null;

                if (ft.showDiffOnly) {
                    filtered = source.filter(d => (d.cop2022.changes && (d.cop2022.changes !== 'identical')));

                    source = filtered;
                }

                if (filterByGroup) {
                    filtered = source.filter(d =>
                        d.element.group === (filterByGroup && (ft.showGroup0 ? '0' : '')) ||
                        d.element.group === (filterByGroup && (ft.showGroup1 ? '1' : '')) ||
                        d.element.group === (filterByGroup && (ft.showGroup2 ? '2' : '')) ||
                        d.element.group === (filterByGroup && (ft.showGroup3 ? '3' : '')) ||
                        d.element.group === (filterByGroup && (ft.showGroup4 ? '4' : '')) ||
                        d.element.group === (filterByGroup && (ft.showGroup5 ? '5' : ''))
                    );

                    source = filtered;
                }

                if (!filtered)
                    filtered = data;

                return filtered;
            }

            setFilteredData(filterData(data));
        }
        , [data, filterSettings]
    );

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md="auto">
                        <VtLibFilter filterChanged={setFilterSettings} />
                    </Col>
                    <Col>
                        <h1><span style={{ fontFamily: "WAG Symbols", fontSize: "72px" }}></span>Vault Library</h1>
                        <VtLibElements filteredData={filteredData} />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default FigWagVtLibrary;