import React from 'react';
import { useTable, useFilters, useSortBy } from 'react-table';
import { matchSorter } from 'match-sorter';
import HistoryTableDefaultColumnFilter from '../HistoryTableDefaultColumnFilter/HistoryTableDefaultColumnFilter';
import { rowNumberFromNumber } from '../../Libraries/Utilities/GeneralPurposeFunctions';

const HistoryTable = (props) => {
    const { columns, data } = props;

    function fuzzyTextFilterFn(rows, id, filterValue) {
        return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
    }

    // Let the table remove the filter if the string is empty
    fuzzyTextFilterFn.autoRemove = val => !val;

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: HistoryTableDefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        rows, // rows for the table based on the data passed
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
        visibleColumns
    } = useTable({ columns, data, defaultColumn, filterTypes }, useFilters, useSortBy);

    /** Returns css style based on the row data */
    const getCellProps = (cellInfo) => {
        const cellId = cellInfo.column.id;

        /**
         * Column layout:
         *   0  = element.symbol
         *   1  = element.description
         *   2  = cop2017.number
         *   3  = cop2017.difficultyValue
         *   4  = cop2022.number
         *   5  = cop2022.difficultyValue
         *   6  = cop2022.changes
         */

        if (cellId === "cop2022.number" || cellId === "cop2022.difficultyValue" || cellId === "cop2022.changes") {
            // Don't highlight general purpose elements (elements not in a CoP group)
            const group2017 = rowNumberFromNumber(cellInfo.row.cells[2].value);
            const group2022 = rowNumberFromNumber(cellInfo.row.cells[4].value);

            if (0 === group2017 && 0 === group2022) {
                // Don't show the number for group 0 elements because it is an internal GS number
                return { style: { display: 'none' } };
            } else {
                const diff2017 = cellInfo.row.cells[6].value;

                if (diff2017 === "removed")
                    return { style: { backgroundColor: '#F1948A' } };
                if (diff2017 === "identical")
                    return { style: { backgroundColor: '#E5E7E9' } };
                if (diff2017 === "added")
                    return { style: { backgroundColor: '#82E0AA' } };
                if (diff2017 === "lower-dv")
                    return { style: { backgroundColor: '#F8C471' } };
                if (diff2017 === "higher-dv")
                    return { style: { backgroundColor: '#C39BD3' } };
                if (diff2017 === "number-changed")
                    return { style: { backgroundColor: '#EDBB99' } };
                if (!isNaN(diff2017)) {
                    if (Number(diff2017) < 0)
                        return { style: { backgroundColor: '#EDBB99' } };
                    else
                        return { style: { backgroundColor: '#5bdd62' } };
                }
            }
        }

        if (cellId === "cop2017.number" || cellId === "cop2017.difficultyValue") {
            // Don't highlight general purpose elements (elements not in a CoP group)
            const group2017 = rowNumberFromNumber(cellInfo.row.cells[2].value);
            const group2022 = rowNumberFromNumber(cellInfo.row.cells[4].value);

            if (0 === group2017 && 0 === group2022) {
                // Don't show the number for group 0 elements because it is an internal GS number
                return { style: { display: 'none' } };
            }
        }

        return {};
    }

    return (<>
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps([
                                {
                                    style: column.style
                                },
                                column.getSortByToggleProps()
                            ])}>
                                {column.render("Header")}
                                {/* Add a sort direction indicator */}
                                <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                </span>
                                {/* Render the columns filter UI */}
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                            </th>
                        ))}
                    </tr>
                ))}
                <tr>
                    <th
                        colSpan={visibleColumns.length}
                        style={{
                            textAlign: 'left',
                        }}
                    >
                    </th>
                </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps([
                                        getCellProps(cell)
                                    ])}>
                                        {cell.render("Cell")}
                                    </td>);
                            })}
                        </tr>)
                })}
            </tbody>
        </table>
    </>
    );
}

export default HistoryTable;