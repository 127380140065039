import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import WebUserProvider from '../src/Services/WebUser/WebUserProvider';


ReactDOM.render(
    <ErrorBoundary>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={process.env.REACT_APP_AUTH0_CALLBACK_URL}
            audience={process.env.REACT_APP_AUTH0_AUDIANCE}
        >
            <WebUserProvider>
                <Router>
                    <App />
                </Router>
            </WebUserProvider>
        </Auth0Provider>
    </ErrorBoundary>
    , document.getElementById('root'));
