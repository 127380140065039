import React from 'react';
import wagSymbolsFont from '../Assets/Fonts/WAG Symbols.otf';
import wagSymbolsBoldFont from '../Assets/Fonts/WAG Symbols Bold.otf';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Downloads = () => {

    return (
        <>
            <h1>Download WAG Symbol Fonts</h1>
            <h2>WAG Symbols and WAG Symbols Bold</h2>
            <ul>
                <li>It is best to download and install both font files because they belong to the same font-family. If the 'thin' font doesn't know a symbol,
                    your computer will likely fall back to the symbol in the 'bold' font. It will look bolder than the other symbols, but at least it will be visible.

                </li>
                <li>If you have WAG fonts already installed on your computer, it is best to remove them first.</li>
                <li>Not every application will notice a new font immediately. So if the symbols are not showing correct, try restarting your application.</li>
            </ul>
            <Table style={{ textAlign: 'center'}}>
                <Row>
                    <Col>
                        <h3>WAG Symbols</h3>
                        <ul type="none">
                            <li>1705 symbols</li>
                            <li>Less bold symbols</li>
                        </ul>
                    </Col>
                    <Col>
                        <h3>WAG Symbols Bold</h3>
                        <ul type="none">
                            <li>2603 symbols</li>
                            <li>Bold symbols</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Button variant="primary"><a href={wagSymbolsFont} style={{ color: 'inherit', textDecoration: 'inherit' }} download="WAG Symbols.otf">Download</a></Button>
                    </Col>
                    <Col>
                    <Button variant="primary"><a href={wagSymbolsBoldFont} style={{ color: 'inherit', textDecoration: 'inherit' }} download="WAG Symbols Bold.otf">Download</a></Button>
                    </Col>
                </Row>
            </Table>
            <br />

        </>
    );
};

export default Downloads;
