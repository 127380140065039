import React from 'react';
import useWagml from '../../Services/Wagml/useWagml';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFastBackward, faStepBackward, faBackspace, faStepForward, faFastForward } from '@fortawesome/free-solid-svg-icons';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

const WagEditorToolbar = (props) => {
    const { dispatchWagml } = useWagml();

    return (
        <ButtonToolbar>
            <ButtonGroup>
                {/* <Button variant="secondary" onClick={(e) => { props.onToolbarButtonClicked('--move-cursor-home'); }}><FontAwesomeIcon icon={faFastBackward} /></Button>
                <Button variant="secondary" onClick={(e) => { props.onToolbarButtonClicked('--move-cursor-left'); }}><FontAwesomeIcon icon={faStepBackward} /></Button>
                <Button variant="secondary" onClick={(e) => { props.onToolbarButtonClicked('--move-cursor-right'); }}><FontAwesomeIcon icon={faStepForward} /></Button>
                <Button variant="secondary" onClick={(e) => { props.onToolbarButtonClicked('--move-cursor-end'); }}><FontAwesomeIcon icon={faFastForward} /></Button>
                <Button variant="secondary" onClick={(e) => { props.onToolbarButtonClicked('--backspace'); }}><FontAwesomeIcon icon={faBackspace} /></Button> */}
                <Button variant="secondary" onClick={() => dispatchWagml({ type: 'MOVE_CURSOR_HOME' })}><FontAwesomeIcon icon={faFastBackward} /></Button>
                <Button variant="secondary" onClick={() => dispatchWagml({ type: 'MOVE_CURSOR_LEFT' })}><FontAwesomeIcon icon={faStepBackward} /></Button>
                <Button variant="secondary" onClick={() => dispatchWagml({ type: 'MOVE_CURSOR_RIGHT' })}><FontAwesomeIcon icon={faStepForward} /></Button>
                <Button variant="secondary" onClick={() => dispatchWagml({ type: 'MOVE_CURSOR_END' })}><FontAwesomeIcon icon={faFastForward} /></Button>
                <Button variant="secondary" onClick={() => dispatchWagml({ type: 'BACKSPACE' })}><FontAwesomeIcon icon={faBackspace} /></Button>
            </ButtonGroup>
        </ButtonToolbar>
    );
}

export default WagEditorToolbar;