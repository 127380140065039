import React from 'react';

const WagBb = () => {
    return (
        <>
            <h1>Balance Beam</h1>
        </>
    );
}

export default WagBb;