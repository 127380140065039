import * as Colors from '../Utilities/Colors';
import * as Fonts from '../Utilities/Fonts';

export default class RenderStyle {
    // Borders
    static outerBorderThickness = 2;
    static innerBorderThickness = 1;

    // Margins
    static leftMargin = 2;
    static rightMargin = 2;
    static topMargin = 2;
    static bottomMargin = 2;

    // Spacing
    static startOfExerciseSpacerWidth = 50;
    static endOfExerciseSpacerWidth = 50;
    static nonConnectedElementSpacerWidth = 50;
    static connectedElementSpacerWidth = 4;
    static extraWidthForClickableAreaSpacers = 5;
    static backgroundSpacer = Colors.White;
    static hoverBackgroundSpacer = Colors.AliceBlue;

    static elementSpacerPixels = 20;
    static connectionSpacerPixels = 50;

    // Colors
    static outerBorderColor = Colors.Black;
    static innerBorderColor = Colors.Black;

    // Alignment
    static gymnastInfoAlignment = 'center';

    // Fonts
    static gymnastInfoFont = Fonts.SansSerif20;
    static apparatusInfoFont = Fonts.Wag26;
    static labelsFont = Fonts.SansSerif20;
    static smallScoreFont = Fonts.SansSerif16;
    static largeScoreFont = Fonts.SansSerif30;

    // Exercise symbols
    static exerciseFont = Fonts.Wag50;
    static exerciseSymbolsColor = Colors.Black;
    static elementBackground = Colors.White;
    static hoverBackgroundNonSelectedElementColor = Colors.LightGrey;
    static hoverBackgroundSelectedElementColor = Colors.Red;
    static selectedElementBackgroundColor = Colors.DeepSkyBlue;

    // Connections
    static connectionLineColor = Colors.Black;
    static connectionLineThickness = 2;
    static connectionLineHeight = 30;
    static debugConnectionLineControlPointColor = Colors.Red;
    static debugConnectionLineControlPointThickness = 1;
    static debugConnectionLineControlPointSize = 4;
}
