import React from 'react';
import LoginButton from '../Components/LoginButton/LoginButton';

const Logon = () => {
    return (
        <>
            <LoginButton />
        </>
    );
};

export default Logon;