import CanvasUIElement from './CanvasUIElement';
import RenderStyle from './RenderStyle';
import Point from '../WindowsBase/Point';
import Size from '../WindowsBase/Size';
import { Visibility } from '../WindowsBase/Visibility';

/** Represents the line under connected elements.  */
export default class ConnectionIndicator extends CanvasUIElement {
    constructor(id) {
        super(id);

        this._connectedElements = [];

        this._startingPoint = new Point();
        this._endingPoint = new Point();
        this._controlPoint = new Point();
    }

    /** Gets the collection of elements that are connected by this connection line. */
    get connectedElements() { return this._connectedElements; }

    /** Gets or sets the starting point of the connection line */
    get startingPoint() { return this._startingPoint; }
    set startingPoint(point) { this._startingPoint = point; }

    /** Gets or sets the ending point of the connection line */
    get endingPoint() { return this._endingPoint; }
    set endingPoint(point) { this._endingPoint = point; }

    /** Gets or sets the control point of the quadratic Beziér curve */
    get controlPoint() { return this._controlPoint; }
    set controlPoint(point) { this._controlPoint = point; }

    measure(ctx) {
        // The size of the connection line depends on the size of the elements to connect
        let totalWidth = 0;
        for (let i = 0; i < this.connectedElements.length; i++) {
            const component = this.connectedElements[i];
            if (component.constraintSize.isEmpty)
                component.measure(ctx);

            if (!component.constraintSize.isEmpty)
                totalWidth += component.constraintSize.width;
        }

        // Add the width of the element spacer
        totalWidth += (this.connectedElements.length - 1) * RenderStyle.connectedElementSpacerWidth;

        this.constraintSize = new Size(totalWidth, RenderStyle.connectionLineHeight);
    }

    draw(ctx) {
        if (!ctx)
            return;

        if (this.actualSize.isEmpty)
            return;

        if (this.visibility !== Visibility.Visible)
            return;

        // Calculate the position of the points
        // Extend the indicator a little into the spacer before the connection, so that the cursor is shown inside 
        // the connection in case the cursor is before the first element in the connection.
        this.startingPoint.x = this.position.x - RenderStyle.connectedElementSpacerWidth;
        this.startingPoint.y = this.position.y;

        // Extend the indicator a little into the spacer behind the connection, so that the cursor is shown inside 
        // the connection in case the cursor is behind the last element in the connection.
        this.endingPoint.x = this.position.x + this.actualSize.width + RenderStyle.connectedElementSpacerWidth;
        this.endingPoint.y = this.position.y;

        this.controlPoint.x = this.startingPoint.x + ((this.endingPoint.x - this.startingPoint.x) / 2);
        this.controlPoint.y = this.position.y + (this.actualSize.width * 0.2);

        ctx.lineWidth = RenderStyle.connectionLineThickness;
        ctx.strokeStyle = RenderStyle.connectionLineColor;
        ctx.beginPath();
        ctx.moveTo(this.startingPoint.x, this.startingPoint.y);
        ctx.quadraticCurveTo(this.controlPoint.x, this.controlPoint.y, this.endingPoint.x, this.endingPoint.y);
        ctx.stroke();
    }

    drawComponentBounds(ctx) {
        if (!ctx)
            return;

        if (this.actualSize.isEmpty)
            return;

        super.drawComponentBounds(ctx);

        ctx.save();

        // Draw the start, stop and control points
        ctx.beginPath();
        ctx.strokeStyle = RenderStyle.debugConnectionLineControlPointColor;
        ctx.lineWidth = RenderStyle.debugConnectionLineControlPointThickness;

        // Start point
        ctx.moveTo(this.startingPoint.x - RenderStyle.debugConnectionLineControlPointSize, this.startingPoint.y - RenderStyle.debugConnectionLineControlPointSize);
        ctx.lineTo(this.startingPoint.x + RenderStyle.debugConnectionLineControlPointSize, this.startingPoint.y + RenderStyle.debugConnectionLineControlPointSize);
        ctx.moveTo(this.startingPoint.x + RenderStyle.debugConnectionLineControlPointSize, this.startingPoint.y - RenderStyle.debugConnectionLineControlPointSize);
        ctx.lineTo(this.startingPoint.x - RenderStyle.debugConnectionLineControlPointSize, this.startingPoint.y + RenderStyle.debugConnectionLineControlPointSize);
        ctx.stroke();

        // End point
        ctx.moveTo(this.endingPoint.x - RenderStyle.debugConnectionLineControlPointSize, this.endingPoint.y - RenderStyle.debugConnectionLineControlPointSize);
        ctx.lineTo(this.endingPoint.x + RenderStyle.debugConnectionLineControlPointSize, this.endingPoint.y + RenderStyle.debugConnectionLineControlPointSize);
        ctx.moveTo(this.endingPoint.x + RenderStyle.debugConnectionLineControlPointSize, this.endingPoint.y - RenderStyle.debugConnectionLineControlPointSize);
        ctx.lineTo(this.endingPoint.x - RenderStyle.debugConnectionLineControlPointSize, this.endingPoint.y + RenderStyle.debugConnectionLineControlPointSize);
        ctx.stroke();

        // Control point
        ctx.moveTo(this.controlPoint.x - RenderStyle.debugConnectionLineControlPointSize, this.controlPoint.y - RenderStyle.debugConnectionLineControlPointSize);
        ctx.lineTo(this.controlPoint.x + RenderStyle.debugConnectionLineControlPointSize, this.controlPoint.y + RenderStyle.debugConnectionLineControlPointSize);
        ctx.moveTo(this.controlPoint.x + RenderStyle.debugConnectionLineControlPointSize, this.controlPoint.y - RenderStyle.debugConnectionLineControlPointSize);
        ctx.lineTo(this.controlPoint.x - RenderStyle.debugConnectionLineControlPointSize, this.controlPoint.y + RenderStyle.debugConnectionLineControlPointSize);
        ctx.stroke();

        ctx.closePath();

        ctx.restore();
    }

}
