import Spacer from './Spacer';
import RenderStyle from './RenderStyle';
import Size from '../WindowsBase/Size';
import * as Colors from '../Utilities/Colors';
import { Visibility } from '../WindowsBase/Visibility';

/** Represents the space before the first element in an exercise. */
export default class StartOfExerciseSpacer extends Spacer {
    constructor(id) {
        super(id);

        // The cursor can be in the middle (before the connection) or at the end (inside the connection) of the spacer
        this._cursorInTheMiddle = false;
        this._cursorAfterSpacer = true;

        this._lastHoveredSection = '';

        // Give the user more screen area to click between two elements.
        this._extraWidthForClickableArea = RenderStyle.extraWidthForClickableAreaSpacers;
    }

    /** Gets or sets a flag indicating whether the cursor is on the right side of the spacer */
    get cursorAfterSpacer() { return this._cursorAfterSpacer; }
    set cursorAfterSpacer(flag) { this._cursorAfterSpacer = flag; }

    /** Gets or sets a flag indicating whether the cursor is in the middle of the spacer */
    get cursorInTheMiddle() { return this._cursorInTheMiddle; }
    set cursorInTheMiddle(flag) { this._cursorInTheMiddle = flag; }

    isPointInElement(point) {
        const inElement = super.isPointInElement(point);

        if (inElement) {
            this.getRegionPointIsIn(point);
        }

        return inElement;
    }

    /** Returns middle - right depending on where the point is. This is used to determine where to place the
    * cursor when the area is clicked.
    */
    getRegionPointIsIn(point) {
        const oldRegion = this.lastHoveredRegion;

        if (point.x > (this.position.x + this.actualSize.width - this.extraWidthForClickableArea)) {
            this.lastHoveredRegion = 'right';
        } else {
            this.lastHoveredRegion = 'middle';
        }

        if (oldRegion !== this.lastHoveredRegion)
            this.isDirty = true;

        return this.lastHoveredRegion;
    }

    /** A custom draw function because this component needs to draw the cursor either in the middle or at the end of the spacer. */
    drawCursor(ctx, color) {
        if (!ctx)
            return;

        if (this.actualSize.isEmpty)
            return;

        if (this.visibility !== Visibility.Visible)
            return;

        const cursorWidth = 2;
        let x = 0;

        if (this.cursorInTheMiddle) {
            x = this.position.x + (this.actualSize.width / 2);
        };

        if (this.cursorAfterSpacer) {
            x = this.position.x + this.actualSize.width - (this.extraWidthForClickableArea / 2);
        };

        const y = this.position.y;

        ctx.fillStyle = Colors.White;
        ctx.strokeStyle = Colors.White;
        ctx.beginPath();
        ctx.rect(x - 2, y, cursorWidth + 4, this.actualSize.height);
        ctx.closePath();
        ctx.fill();

        ctx.lineWidth = cursorWidth;
        ctx.strokeStyle = color;
        ctx.beginPath();
        ctx.moveTo(x, this.position.y);
        ctx.lineTo(x, this.position.y + this.actualSize.height);
        ctx.stroke();
    }

    /** This spacer has a fixed width which is defined in the RenderStyle class. The height needs to be set by the parent panel. */
    measure(ctx) {
        this.constraintSize = new Size(RenderStyle.startOfExerciseSpacerWidth, 0);
    }

    draw(ctx) {
        if (this.isHovered) {
            // Clear the background first
            ctx.fillStyle = RenderStyle.backgroundSpacer;
            ctx.fillRect(this.position.x, this.position.y, this.actualSize.width, this.actualSize.height);

            // Then only colorize the selected region
            ctx.fillStyle = RenderStyle.hoverBackgroundSpacer;

            switch (this.lastHoveredRegion) {
                case 'middle': ctx.fillRect(this.position.x, this.position.y, this.actualSize.width - (1 * this.extraWidthForClickableArea), this.actualSize.height); break;
                case 'right': ctx.fillRect(this.position.x + this.actualSize.width - this.extraWidthForClickableArea, this.position.y, this.extraWidthForClickableArea, this.actualSize.height); break;
                default: ctx.fillRect(this.position.x, this.position.y, this.actualSize.width, this.actualSize.height);
            }

        } else {
            ctx.fillStyle = RenderStyle.backgroundSpacer;
            ctx.fillRect(this.position.x, this.position.y, this.actualSize.width, this.actualSize.height);
        }

        super.draw(ctx);

        if (this.hasCursor && this.cursorCurrentlyVisible)
            this.showCursor(ctx);        
    }    
}
