import React, { useEffect, useState, useRef } from 'react';
import useWagml from '../Services/Wagml/useWagml';
import * as ml from '../Services/Wagml/Wagml';
import useWebUser from '../Services/WebUser/useWebUser';
import { useAuth0 } from '@auth0/auth0-react';
import WagCanvasFx from '../Components/WagCanvasFx/WagCanvasFx';
import CopDropdown from '../Components/CopDropdown/CopDropdown';
import WagEditorToolbar from '../Components/WagEditorToolbar/WagEditorToolbar';
import WagKeyboardFx from '../Components/WagKeyboardFx/WagKeyboardFx';
import FigCopClient from '../Services/FigCop/FigCopClient';
import Alert from 'react-bootstrap/Alert';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFolderOpen, faSave, faFileImage } from '@fortawesome/free-solid-svg-icons';

const baseUrlWebUserApi = process.env.REACT_APP_API_BASE_URL;

const FigWagFx = () => {
    const { dispatchWagml, renderedExerciseAsImage, wagml, CannotEvaluateBecauseOfMissingLicense } = useWagml();

    const { selectedCop } = useWebUser();

    const {
        getAccessTokenSilently,
        isAuthenticated,
    } = useAuth0();

    const [accessToken, setAccessToken] = useState(null);
    const [showLogonAlert, setShowLogonAlert] = useState(true);
    const inputOpenFileRef = useRef(null);
    const [uploadedFile, setUploadedFile] = useState();
    const [fileName, setFileName] = useState();
    const [isUploadFilePicked, setIsUploadFilePicked] = useState(false);
    const [canEvaluateExercise, setCanEvaluateExercise] = useState(true);

    // Get the client for the WebUser API
    const [client] = useState(
        () => new FigCopClient(baseUrlWebUserApi)
    );

    // React when an image was rendered
    useEffect(() => {
        if (!renderedExerciseAsImage)
            return;

        saveImage(renderedExerciseAsImage);
    }, [renderedExerciseAsImage]);

    // React to changes in the authenticated state in Auth0
    useEffect(
        () => {
            async function getAccessToken() {
                const token = await getAccessTokenSilently();
                setAccessToken(token);
            }

            if (isAuthenticated) {
                getAccessToken();
            }
            else {
                setAccessToken(null);
            }

        },
        [isAuthenticated, getAccessTokenSilently]
    );

    // React to changes in the WAGML document
    useEffect(
        () => {
            if (!wagml) {
                dispatchWagml({ type: 'CREATE_NEW_FX_EXERCISE' });
            } else {
                // Check if the exercise could be evaluated
                if (CannotEvaluateBecauseOfMissingLicense === true) {
                    setCanEvaluateExercise(false);
                }
                else {
                    setCanEvaluateExercise(true);
                }
            }
        }
        , [CannotEvaluateBecauseOfMissingLicense, dispatchWagml, wagml]
    );

    // React when a file has been opened from disk
    useEffect(
        () => {
            if (isUploadFilePicked && uploadedFile) {
                var fr = new FileReader();
                fr.onload = function () {
                    let wagmlFromDisk = fr.result;

                    // Since the wagml comes from storage, make sure it is up-to-date with the latest application standards.
                    (async () => {
                        // Prepare (upgrade) the wagml document for use in the most up-to-date application version. Adds id's to elements and much more ...
                        const selectedCopCode = selectedCop.CopVersion;
                        const xml = ml.prepareWagmlFromFile(wagmlFromDisk, selectedCopCode);

                        // Call the internet to evaluate the exercise
                        const ProcessWagml = async (accessToken, wagml) => {
                            const client = new FigCopClient(baseUrlWebUserApi);

                            return await client.ProcessExercise(accessToken, wagml);
                        }

                        const evaluatedExercise = await ProcessWagml(accessToken, xml);

                        console.log("Evaluated exercise:", evaluatedExercise);

                        const evaluationSuccess = ml.getEvaluationSuccess(evaluatedExercise);
                        const evaluationFailureReason = ml.getEvaluationFailureReason(evaluatedExercise);

                        const missingLicense = evaluationSuccess === false && evaluationFailureReason === "No license";

                        dispatchWagml({
                            type: 'LOADED_FROM_FILE',
                            wagml: evaluatedExercise,
                            CannotEvaluateBecauseOfMissingLicense: missingLicense
                        });
                    })();
                }

                fr.readAsText(uploadedFile);
            }

        }, [uploadedFile, isUploadFilePicked, accessToken, client, dispatchWagml, selectedCop]
    );

    // This event is fired by the keyboard component. Add the clicked keystroke to the wagml document.
    const onKeyboardClicked = (keystroke) => {
        dispatchWagml({ type: 'WAGML_KEYBOARD_CLICKED', keystroke: keystroke });
    }

    // Show 'login for full access to license'
    let ShowLogonAlert = null;
    if (!isAuthenticated && showLogonAlert) {
        ShowLogonAlert = <Alert variant="info" onClose={() => setShowLogonAlert(false)} dismissible>Logon to get access to all functionality if you have a valid license.</Alert>
    }

    // Show a warning if someone tries to evaluate an exercise with elements (DV > C) for which the user has no licene.
    let ShowCannotEvaluateAlert = null;
    if (!canEvaluateExercise) {
        ShowCannotEvaluateAlert = <Alert variant="warning">This exercise cannot be evaluated because it contains elements with a DV higher than 'C'. You need a license to evaluate this exercise.</Alert>
    }

    const changeHandler = (event) => {
        setUploadedFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
        setIsUploadFilePicked(true);
    };

    /** Create a new, empty document */
    const newDocument = () => {
        dispatchWagml({ type: 'CREATE_NEW_FX_EXERCISE' });
    }

    const openFile = () => {
        inputOpenFileRef.current?.click();
    }

    let title = "Floor Exercise";
    if (fileName && fileName !== '') {
        title += ` - [${fileName}]`;
    }
    else {
        title += ` - [new]`;
    }

    const saveAsDocument = () => {
        (
            async () => {
                async function getNewFileHandle() {
                    const options = {
                        types: [
                            {
                                description: 'WAG files',
                                accept: {
                                    'text/plain': ['.wagml'],
                                },
                            },
                        ],
                    };
                    const handle = await window.showSaveFilePicker(options);
                    return handle;
                };

                async function writeFile(fileHandle, contents) {
                    // Create a FileSystemWritableFileStream to write to.
                    const writable = await fileHandle.createWritable();
                    // Write the contents of the file to the stream.
                    await writable.write(contents);
                    // Close the file and write the contents to disk.
                    await writable.close();
                };

                let fileHandle = await getNewFileHandle();

                await writeFile(fileHandle, wagml);
            })();
    }

    const saveImage = (data) => {
        const contentType = data.split(',')[0].split(':')[1].split(';')[0];
        const imageBase64 = data.replace(/^data:image\/[a-z]+;base64,/, "");
        const byteCharacters = atob(imageBase64);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: contentType });

        (
            async () => {
                async function getNewFileHandle() {
                    const options = {
                        types: [
                            {
                                description: 'Image files',
                                accept: {
                                    'image/png': ['.png'],
                                },
                            },
                        ],
                    };
                    const handle = await window.showSaveFilePicker(options);
                    return handle;
                };

                async function writeFile(fileHandle, contents) {
                    // Create a FileSystemWritableFileStream to write to.
                    const writable = await fileHandle.createWritable();
                    // Write the contents of the file to the stream.
                    await writable.write(contents);
                    // Close the file and write the contents to disk.
                    await writable.close();
                };

                let fileHandle = await getNewFileHandle();

                await writeFile(fileHandle, blob);
            })();
    }

    const requestRenderExerciseAsImage = () => {
        dispatchWagml({ type: 'RENDER_EXERCISE_AS_IMAGE' });
        /*        
                // Get the canvas data
                var image_png = this.canvas.current.toDataURL("image/jpeg");
        
                (
                    async () => {
                        async function getNewFileHandle() {
                            const options = {
                                types: [
                                    {
                                        description: 'Image files',
                                        accept: {
                                            'image/jpeg': ['.jpeg'],
                                        },
                                    },
                                ],
                            };
                            const handle = await window.showSaveFilePicker(options);
                            return handle;
                        };
        
                        async function writeFile(fileHandle, contents) {
                            // Create a FileSystemWritableFileStream to write to.
                            const writable = await fileHandle.createWritable();
                            // Write the contents of the file to the stream.
                            await writable.write(contents);
                            // Close the file and write the contents to disk.
                            await writable.close();
                        };
        
                        let fileHandle = await getNewFileHandle();
                        // await writeFile(fileHandle, processedWagml);
                        await writeFile(fileHandle, image_png);
                    })();
          */
    }

    return (
        <>
            <h1><span style={{ fontFamily: "WAG Symbols", fontSize: "72px" }}></span>{title}</h1>
            {ShowLogonAlert}
            {ShowCannotEvaluateAlert}
            <ButtonToolbar>
                <ButtonGroup>
                    <Button onClick={() => newDocument()} variant="secondary"><FontAwesomeIcon icon={faFile} /> New</Button>
                    <Button onClick={() => openFile()} variant="secondary"><FontAwesomeIcon icon={faFolderOpen} /> Open</Button>
                    <input ref={inputOpenFileRef} onChange={changeHandler} type="file" name="file" className="d-none" />

                    <Dropdown>
                        <Dropdown.Toggle variant="secondary" id="saveAsDropdown">
                            <FontAwesomeIcon icon={faSave} /> Save As...
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => saveAsDocument()}><img src='/favicon.ico' height='24' alt='wagml-logo' />Save as document (*.wagml)</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => requestRenderExerciseAsImage()}><FontAwesomeIcon icon={faFileImage} /> Save as image (*.png)</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </ButtonGroup>
                <ButtonGroup>
                    <CopDropdown />
                </ButtonGroup>
                <ButtonGroup>
                    {/* <WagEditorToolbar onToolbarButtonClicked={onToolbarButtonClicked} /> */}
                    <WagEditorToolbar />
                </ButtonGroup>
            </ButtonToolbar>


            <WagCanvasFx width={window.screen.width} height='300px' />
            <WagKeyboardFx onKeyboardClicked={onKeyboardClicked} />
        </>
    );
}

export default FigWagFx;