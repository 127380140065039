import React from 'react';

const Package = (props) => {
    const { data } = props;

    if (!data)
        return null;

    return (
        <div>
            <label>Code: {data.code}</label>
            <label>Start date:: {data.startDate}</label>
            <label>End date:: {data.endDate}</label>
            <label>Enabled features</label>
            <ul>
                <li key="1">Feature 1</li>
                <li key="2">Feature 2</li>
            </ul>
        </div>
    );
};

export default Package;