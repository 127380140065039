export const Transparent = 'transparent';
export const AliceBlue = '#f0f8ff';
export const AntiqueWhite = '#faebd7';
export const Aqua = '#00ffff';
export const Aquamarine = '#7fffd4';
export const Azure = '#f0ffff';
export const Beige = '#f5f5dc';
export const Bisque = '#ffe4c4';
export const Black = '#000000';
export const Blue = '#0000ff';
export const CadetBlue = '#5f9ea0';
export const CornflowerBlue = '#6495ed';
export const Cyan = '#00ffff';
export const DarkBlue = '#00008b';
export const DarkCyan = '#008b8b';
export const DarkGrey = '#a9a9a9';
export const DarkSlateBlue = '#483d8b';
export const DarkTurquoise = '#00ced1';
export const DeepSkyBlue = '#00bfff';
export const DodgerBlue = '#1e90ff';
export const FloralWhite = '#fffaf0';
export const LawnGreen = '#7cfc00';
export const LightBlue = '#add8e6';
export const LightGrey = '#d3d3d3';
export const LightSeaGreen = '#20b2aa';
export const MediumBlue = '#0000cd';
export const MediumTurquoise = '#48d1cc';
export const MidnightBlue = '#191970';
export const Navy = '#000080';
export const Red = '#ff0000';
export const RoyalBlue = '#4169e1';
export const SteelBlue = '#4682b4';
export const Teal = '#008080';
export const Turquoise = '#40e0d0';
export const White = '#ffffff';
export const Yellow = '#ffff00';
