import React from 'react';
import { initialWebUserState } from './WebUserState';

// const stub = () => {
//     throw new Error('You forgot to wrap your component in <WebUserProvider>.');
// };

const initialContext = {
    ...initialWebUserState,
};

const WebUserContext = React.createContext(initialContext);

export default WebUserContext;