import './Cop.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

const FigWagBbCop = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col className="col-sm-3 sidenav position-fixed">
                        <div id="toc_container">
                            <p className="toc_title">Contents</p>
                            <p className="toc_title"><a href="#CoP_2022_2024">CoP 2022 - 2024</a></p>
                            <p className="toc_title"><a href="#beam_2022">Section 12 - Balance Beam</a></p>
                            <ul className="toc_list">
                                <li><a href="#artistry_2022">Artistry</a>
                                    <ul>
                                        <li><a href="#12_composition_2022">Composition</a></li>
                                        <li><a href="#12_rythm_2022">Rhythm &amp; Tempo</a></li>
                                        <li><a href="#12_artistic_2022">Artistic performance</a></li>
                                    </ul>
                                </li>
                                <li><a href="#12_1_General_2022">12.1 General</a>
                                    <ol type="a">
                                        <li><a href="#12_1_General_mounts_2022">Mounts</a></li>
                                        <li><a href="#12_1_General_timing_2022">Timing</a></li>
                                        <li><a href="#12_1_General_fall_timing_2022">Fall Timing</a></li>
                                    </ol>
                                </li>
                                <li><a href="#12_2_contents_2022">12.2 Content of the Exercise</a>
                                </li>
                                <li><a href="#12_3_CR_2022">12.3 Composition Requirements (CR)</a>
                                </li>
                                <li><a href="#12_4_CV_2022">12.4 Connection Value (CV) and Series Bonus (SB)</a>
                                </li>
                                <li><a href="#12_5_artistry_deductions">12.5 Artistry &amp; Composition Deductions</a>
                                </li>
                                <li><a href="#12_6_Specific_apparatus_Deductions_2022">12.6 Specific Apparatus Deductions</a>
                                </li>
                                <li><a href="#12_7_Notes_2022">12.7 Notes</a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col className="offset-3">
                        <div class="anchor" id="CoP_2022_2024" />
                        <h1><span style={{ fontFamily: "WAG Symbols", fontSize: "72px" }}></span>Code of Points Balance Beam</h1>

                        <div class="anchor" id="beam_2022" />
                        <h2>CoP 2022 - 2024, Section 12 - Balance Beam</h2>

                        <div class="anchor" id="artistry_2022" />
                        <h3><i>ARTISTRY</i></h3>

                        <div class="anchor" id="12_composition_2022" />
                        <h4>Composition</h4>
                        <Alert variant="warning">Formulation differs slightly from previous CoP<br />Former title used to be "Composition and Choreography"</Alert>
                        <p>
                            The composition of a Balance Beam exercise is based on the movement
                            vocabulary of the gymnast, as well as the choreography of these elements in
                            relationship to the Balance Beam, while establishing a strong sense of variation
                in rhythm, strength and pace of the movements. It is the balancing of:</p>
                        <ul>
                            <li>dance elements</li>
                            <li>acro elements</li>
                            <li>choreography</li>
                        </ul>
                in order to create a continuous flow, a cohesive whole.
            <p>A well-structured composition of the exercise includes: </p>
                        <ul>
                            <li>a rich and varied selection of elements from different structure groups in the Table of Elements</li>
                            <li>changes of level <i>(up and down)</i></li>
                            <li>changes of direction <i>(fwd, bwd, swd)</i></li>
                            <li>changes of rhythm &amp; tempo</li>
                            <li>creative movements and transitions</li>
                        </ul>
                in order to create a continuous flow, a cohesive whole.

            <p><i>This is <b>"what"</b> she performs.</i></p>

                        <div class="anchor" id="12_rythm_2022" />
                        <h4>Rhythm &amp; Tempo</h4>
                        <p>The rhythm and tempo (speed/pace) must be varied, sometimes lively,
            sometimes slow, but predominately dynamic and above all uninterrupted.</p>
                        <p>The transition between the movements and elements should be smooth and fluent:</p>
                        <ul>
                            <li>without unnecessary stops or</li>
                            <li>prolonged preparatory movements before elements</li>
                        </ul>
                The exercise should not be a series of disconnected elements.

                <div class="anchor" id="12_artistic_2022" />
                        <h4>Artistic Performance</h4>
                        <p>When a gymnast demonstrates creativity, confidence of performance,
                        personal style, perfect technique and varied rhythm and tempo, she
                transforms a well-structured composition into an artistic performance.</p>
                        <p><i>This is not <b>“what”</b> the gymnast performs, but <b>“how”</b> she performs.</i></p>

                        <div class="anchor" id="12_1_General_2022" />
                        <h3>12.1 General</h3>
                        <p>The evaluation of the exercise begins with the take-off from the board or the
                mat. Additional supports under the board (i.e. an extra board) are not permitted.</p>

                        <div class="anchor" id="12_1_General_mounts_2022" />
                        <h4>a) Mounts</h4>
                        <ul>
                            <li>If the gymnast on her first attempt touched the springboard, or the apparatus.
                    <ul>
                                    <li>Deduction <span className="deduction">– 1.00</span></li>
                                    <li>She must start her exercise</li>
                                    <li>No value will be awarded for the mount</li>
                                    <li>Deduction for “Mount without DV” will be applied (E-Panel)</li>
                                </ul>
                            </li>
                            <li>
                                A gymnast is permitted a 2nd attempt to mount <i>(with a penalty)</i> if she has not touched the springboard or the apparatus.
                    <ul>
                                    <li>Deduction <span className="deduction">– 1.00</span></li>
                                </ul>
                            </li>
                            <li>A 3rd attempt is not permitted.</li>
                        </ul>
                        <p>D-Panel will take the deduction from the Final Score.</p>

                        <div class="anchor" id="12_1_General_timing_2022" />
                        <h4>b) Timing</h4>
                        <p>The duration of the exercise on the Balance Beam may not exceed 1:30 minutes <i>(90 seconds)</i>.</p>
                        <ul>
                            <li>The Time Judge 1 begins timing when the gymnast takes-off from the springboard or mat. She stops the clock, when the gymnast touches the mat upon completion of her Beam exercise.</li>
                            <li>A signal <i>(gong)</i> will be communicated ten (10) seconds prior to the maximum time limit and again at the maximum time limit <i>(1:30)</i> to indicate that the exercise is to be finished.</li>
                            <li>If the dismount lands at the sound of the second signal, there is <b><u>no</u></b> deduction.</li>
                            <li>If the dismount lands after the sound of the second signal, there is a deduction for exceeding the time limit.</li>
                            <li>The deduction for overtime will be taken, if the exercise is 1:31 minutes (91 seconds) or more.
                    <ul>
                                    <li>– 0.10</li>
                                </ul>
                            </li>
                            <li>Elements performed after the 90 second time limit will be recognised by the D-Panel and evaluated by the E-Panel.</li>
                            <li>Time violations are reported in writing by the respective Time Judge to the D-Panel Judges, who then take the deduction from the Final Score.</li>
                        </ul>

                        <div class="anchor" id="12_1_General_fall_timing_2022" />
                        <h4>c) Fall Timing:</h4>
                        <p>For interruption of the exercise due to a fall from the apparatus, an intermediate time period of 10 seconds is allowed.</p>
                        <ul>
                            <li>If the gymnast exceeds the allowable time to resume her exercise a – 0.30 neutral deduction for excessive time will be applied if the gymnast continues her exercise.</li>
                            <li>Time Judge 2 begins timing when the gymnast is on her feet after the fall.</li>
                            <li>The duration of the fall is timed separately; it will <b><u>not</u></b> be calculated in the total time of the exercise.</li>
                            <li>The elapsed time during the fall will be displayed in seconds on the scoreboard.</li>
                            <li>The fall period ends when the gymnast takes-off from the mat to remount the Beam.</li>
                            <li>A signal <i>(gong)</i> will be communicated at the 10 second time limit.</li>
                            <li>If the gymnast has not resumed within 60 seconds, the exercise will be terminated.</li>
                            <li>No salute is necessary to resume an exercise after a fall.</li>
                            <li>After remounting the Beam the resumption of timing by Time Judge 1 begins with the first movement to continue the exercise.</li>
                        </ul>

                        <div class="anchor" id="12_2_contents_2022" />
                        <h3>12.2 Content of the Exercise</h3>
                        <p>The maximum 8 highest difficulties including the dismount are counted for DV.</p>
                        <ul><li>No DMT <span className="deduction">– 0.50</span> from Final Score (D-Panel)</li></ul>

                        <p>Within the 8 counting elements there must be a minimum of:</p>
                        <ul>
                            <li>3 Dance</li>
                            <li>2 Acro</li>
                        </ul>
            and 2 optional elements

            <div class="anchor" id="12_3_CR_2022" />
                        <h3>12.3 Composition Requirements (CR) – D-Panel 2.00 P.</h3>
                        <ol>
                            <li>One connection of at least 2 different dance elements, 1 being a leap or jump with 180° split (cross or side), or straddle position. <span className="award">award 0.50</span></li>
                            <li>Turn (Gr. 3) or Roll/Flairs*. <span className="award">award 0.50</span></li>
                            <li>One Acro series, min. of 2 flight** elements 1 being a salto <i>(elements may be the same)</i>. <span className="award">award 0.50</span></li>
                            <li>Acro elements in different directions <i>(fwd/swd and bwd)</i>. <span className="award">award 0.50</span></li>
                        </ol>
                        <ul type="none">
                            <li>* Elements:1.303 (<span className="wag"></span>), 1.403 (<span className="wag">, </span>), 1.304 (<span className="wag"></span>), 1.207 (<span className="wag">, </span>),
                1.308 (<span className="wag">, </span>), 1.514 (<span className="wag"></span>), 4.203 (<span className="wag"></span>), 4.204 (<span className="wag"></span>),
                4.304 (<span className="wag"></span>), 4.305 (<span className="wag"></span>), 4.206 (<span className="wag">, , </span>), 4.306 (<span className="wag"></span>),
                4.307 (<span className="wag"></span>), 5.405 (<span className="wag">, , </span>), 5.505 (<span className="wag"></span>) can fulfill CR # 2</li>
                            <li>** Flight elements with or without hand support</li>
                        </ul>
                        <b><i>Note:</i></b>
                        <ul>
                            <li><i>CR 1, 2 (turn), 3 &amp; 4 must be performed on the Beam</i></li>
                            <li><i>Handstands and holds may <u><b>not</b></u> be used to fulfill CR</i></li>
                            <li>Rolls can be used <u><b>only</b></u> to fulfill CR # 2</li>
                        </ul>

                        <div class="anchor" id="12_4_CV_2022" />
                        <h3>12.4 Connection Value (CV) and Series Bonus (SB) – D-Panel</h3>
                        <ol type="a">
                            <li>CV can be awarded for direct connections.</li>
                            <li>The CV will be added into the D-Score.</li>
                            <li>Series Bonus will be awarded for the connection of 3 or more elements.</li>
                            <li>No CV will be awarded when grasp of the Beam.</li>
                        </ol>
                        <strong>Formulas for direct connections</strong>
                        <Table className="center fit" bordered>
                            <tbody>
                                <tr className="inversed">
                                    <th colSpan="2">ACROBATIC</th>
                                </tr>
                                <tr className="headerRow">
                                    <th>0.10</th>
                                    <th>0.20</th>
                                </tr>
                                <tr className="">
                                    <td colSpan="2"><strong>2 acro flight elements, including mount, excluding dismount</strong><br />
                    All connections must be rebounding*</td>
                                </tr>
                                <tr className="">
                                    <td>C + C<br />B + D <i>(bwd)(this order only)<br />B + E</i></td>
                                    <td>C/D + D or more<br />B + D <i>(both elements fwd)</i><br />B + F</td>
                                </tr>
                                <tr className="left">
                                    <td colSpan="2">
                                        <p><strong>Series Bonus (SB) of + 0.10 P.</strong></p>
                        Will be awarded to dance/mixed and acro series <i>(*rebounding and non-<br />rebounding)</i>
                        of B + B + C <i>in any order, including MT &amp; DMT (min. C)</i>, or <br />
                        more in addition to CV
                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><i>Example 1:</i></td>
                                                    <td><i>B + B + C = +0.10 series bonus<br />
                                        D + B + C = +0.10 series bonus</i></td>
                                                </tr>
                                                <tr>
                                                    <td><i>Example 2:</i></td>
                                                    <td>C + C + C = <i>+0.10 series bonus <b>Total CV + SB = 0.30</b></i><br />
                                CV+0.1 - CV+0.1</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <p><strong>To receive the SB:</strong></p>
                                        <ul>
                                            <li><i>Non-flight acro elements min. B (except “Holds”) may be used</i></li>
                                            <li><i>The same (flight or non-flight) acro element may be repeated<br /> within connection)</i></li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <i>
                            * Connections with rebounding effect utilise the elasticity of the apparatus and develop speed in one direction.
                <ul>
                                <li>Landing (on 2 feet) from the 1st flight element with hand support followed by an <u><b>immediate</b></u> take-off/rebound into the 2nd element, or</li>
                                <li>Landing from the 1st flight element (with/without hand support) on one leg and placing the free leg with an immediate rebound from both legs into the 2nd element.</li>
                            </ul>
                        </i>
                        <Table className="center fit" bordered>
                            <tbody>
                                <tr className="inversed">
                                    <th colSpan="2">DANCE &amp; MIXED<br />
                                        <i>(acro – flight elements only), excluding dismount</i></th>
                                </tr>
                                <tr className="headerRow">
                                    <th>0.10</th>
                                    <th>0.20</th>
                                </tr>
                                <tr className="">
                                    <td>C + C <i>or more (dance)</i><br />A + C <i>(turns only)</i><br />B + D <i>(mixed)</i></td>
                                    <td>D + D <i>or more</i></td>
                                </tr>
                            </tbody>
                        </Table>
                        <p><i><b>Note:</b> Turns may be performed with step into turn on opposite leg (demi-plié on one or both feet is NOT permitted).</i></p>
                        <p>The following B acro elements with hand support and flight:</p>
                        <ul>
                            <li>flic-flac with legs together (<span className="wag"></span>)</li>
                            <li>flic-flac with step-out (<span className="wag"></span>)</li>
                            <li>gainer flic-flac (<span className="wag"></span>)</li>
                            <li>round-off (<span className="wag"></span>)</li>
                            <li>handspring forward (<span className="wag"></span>)</li>
                        </ul>
                may be performed a 2nd time in the exercise to receive CV and SB, but may not be performed a 2nd time to receive CR.

                <div class="anchor" id="12_5_artistry_deductions" />
                        <h3>12.5 Artistry &amp; Composition Deductions – E-Panel</h3>
                        <Table className="fit" striped bordered hover>
                            <tbody>
                                <tr className="headerRow">
                                    <th>Faults</th>
                                    <th className="center">0.10</th>
                                </tr>
                                <tr className="headerRow">
                                    <th colSpan="2">Artistry of Performance</th>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Insufficient artistry of performance throughout the exercise:</li></ul></td>
                                    <td />
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Poor body posture (head, shoulders, trunk)</li></ul></td>
                                    <td className="narrowCol center">X</td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Insufficient amplitude (maximum elongation of the<br /> movements)</li></ul></td>
                                    <td className="narrowCol center">X</td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Insufficient amplitude of leg swings or kicks</li></ul></td>
                                    <td className="narrowCol center">X</td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Poor foot work
                                    <ul><li>Feet not pointed/relaxed/turned in</li>
                                            <li>Lack of work in relevé</li></ul>
                                    </li></ul></td>
                                    <td className="narrowCol center"><br />X<br />X</td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Insufficient involvement of the body parts</li></ul></td>
                                    <td className="narrowCol center">X</td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Rhythm &amp; Tempo</li></ul></td>
                                    <td />
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Insufficient variation in rhythm &amp; tempo in movements<br /> <i>(no DV)</i></li></ul></td>
                                    <td className="narrowCol center">X</td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Performance of the entire exercise as a series of<br />
                                disconnected elements &amp; movements <i>(lack of fluency)</i></li></ul></td>
                                    <td className="narrowCol center">X</td>
                                </tr>
                                <tr className="headerRow">
                                    <th colSpan="2">Composition</th>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Mount without DV<br />
                                        <i>(All mounts without DV will be commonly recognised as “A”<br />
                                        except lifting one leg over to sit, squat on, simple jump to sit or <br />
                                        kneel or to land on two feet)</i></li></ul></td>
                                    <td className="narrowCol center">X</td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Insufficient use of entire apparatus:</li></ul></td>
                                    <td className="narrowCol center"></td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Lack of complex movements sideways <i>(no DV)</i></li></ul></td>
                                    <td className="narrowCol center">X</td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>Missing combination of movements/elements close to the<br />
                                    beam with a part of the torso (including thigh, knee or <br />
                                    head) touching the beam <i>(element not necessary)</i> <br />
                                        <i>(A complex and creative movement is one that requires <br />
                                    training time, coordination and previous preparation)</i></li></ul></td>
                                    <td className="narrowCol center">X</td>
                                </tr>
                                <tr className="headerRow">
                                    <th colSpan="2"></th>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>One-sided use of elements:</li></ul></td>
                                    <td className="narrowCol center"></td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level2Indent" ><li>More than one ½ turn on 2 feet with straight legs (<span className="wag"></span>) <br />throughout exercise</li></ul></td>
                                    <td className="narrowCol center">X</td>
                                </tr>
                            </tbody>
                        </Table>

                        <div class="anchor" id="12_6_Specific_apparatus_Deductions_2022" />
                        <h3>12.6 Specific Apparatus Deductions – E-Panel</h3>
                        <Table className="fit" bordered>
                            <tbody>
                                <tr className="headerRow center">
                                    <th>Faults</th>
                                    <th>0.10</th>
                                    <th>0.30</th>
                                    <th>0.50</th>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Poor rhythm in connection <i>(with DV)</i></li></ul></td>
                                    <td className="narrowCol center">ea X</td>
                                    <td className="narrowCol center"></td>
                                    <td className="narrowCol center"></td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><strong>Excessive preparation</strong></td>
                                    <td className="narrowCol center"></td>
                                    <td className="narrowCol center"></td>
                                    <td className="narrowCol center"></td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Adjustment <i>(steps without choreography &amp;<br />
                        unnecessary movements)</i></li></ul></td>
                                    <td className="narrowCol center">ea X</td>
                                    <td className="narrowCol center"></td>
                                    <td className="narrowCol center"></td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Excessive arm swing before dance elements</li></ul></td>
                                    <td className="narrowCol center">ea X</td>
                                    <td className="narrowCol center"></td>
                                    <td className="narrowCol center"></td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Pause (apply at 2 sec.)</li></ul></td>
                                    <td className="narrowCol center">ea X</td>
                                    <td className="narrowCol center"></td>
                                    <td className="narrowCol center"></td>
                                </tr>
                                <tr className="headerRow">
                                    <th colSpan="4"></th>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Additional support of leg against the side <br />
                        surface of the Beam</li></ul></td>
                                    <td className="narrowCol center"></td>
                                    <td className="narrowCol center">X</td>
                                    <td className="narrowCol center"></td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Grasp of Beam in order to avoid a fall</li></ul></td>
                                    <td className="narrowCol center"></td>
                                    <td className="narrowCol center"></td>
                                    <td className="narrowCol center">X</td>
                                </tr>
                                <tr className="narrowRow">
                                    <td className="narrowCol"><ul className="narrowListItem level1Indent"><li>Additional movements to maintain balance</li></ul></td>
                                    <td className="narrowCol center">X</td>
                                    <td className="narrowCol center">X</td>
                                    <td className="narrowCol center">X</td>
                                </tr>
                            </tbody>
                        </Table>
                        <strong>Dismount:</strong>
                        <ol type="a">
                            <li>If the salto for the dismount has not begun (no initiation of rotation) and a fall occurs:<br />
                                <b>Example 1:</b> <span className="wag"></span> jump off BB<br />
                    Evaluation:
                    <ul>
                                    <li>No DV: count 7 elements only <i>(D-Panel)</i></li>
                                    <li>No DMT: – 0.50 <i>(D-Panel)</i></li>
                                    <li>Fall: – 1.00 <i>(E-Panel)</i></li>
                                    <li>If she remounts to perform DMT, no deduction for “No DMT”</li>
                                </ul>
                            </li>
                            <li>If the salto for the dismount has begun and then a fall occurs:<br />
                                <b>Example 2:</b> <span className="wag"></span> begin salto, but failure to land feet first<br />
                    Evaluation:
                    <ul>
                                    <li>No DV: count 7 elements only <i>(D-Panel)</i></li>
                                    <li>Fall: – 1.00 <i>(E-Panel)</i></li>
                                </ul>
                            </li>
                        </ol>

                        <div class="anchor" id="12_7_Notes_2022" />
                        <h3>12.7 NOTES</h3>
                        <h4>Falls – Acro and Dance Elements</h4>
                        <p>Acro and Dance Elements must return to the Beam with foot or torso in order to be awarded difficulty.</p>
                        <ol type="a">
                            <li>with a landing on 1 or 2 feet or in prescribed position on the Beam<br />
                    – the DV is awarded.</li>
                            <li>without a landing on 1 or 2 feet or in prescribed position on the Beam<br />
                    – <b><u>No</u></b> DV is awarded <i>(element may be performed again to receive DV)</i>.</li>
                        </ol>
                        <h4>Mounts</h4>
                        <ol type="a">
                            <li>Only one acro element may precede the mount.</li>
                            <li>Some elements listed as mounts <i>(rolls, handstands and holds)</i> can be<br />
                    performed in the exercise <i>(or vice versa)</i> but receive DV only once.</li>
                        </ol>
                        <h4>Holds</h4>
                        <ol type="a">
                            <li>Handstands <i>(without turns)</i> &amp; holds must be held for 2 sec. when
                    prescribed in the Table of Elements in order to receive DV. If the
                    element is not held for 2 sec. and does not appear as another element
                    in the Code, award 1 DV lower or no DV.<br />
                                <i>(HSTD or hold position should be completed)</i>.</li>
                            <li>For CR, CV and SB “Hold” acro elements <i>(with flight)</i> may be used as
                    the last element in an acro series or mixed connection.</li>
                            <li><span className="wag"></span> and <span className="wag"></span> will be credited 1 DV lower if the handstand is <b><u>NOT</u></b> held for 2 sec.
                    <ul>
                                    <li>If the gymnast performs the same element again in the exercise and holds the HSTD position for 2 sec. – No DV awarded</li>
                                </ul>
                            </li>
                        </ol>
                        <h4>Onodi Tic Toc <span className="wag"></span></h4>
                        <ul>
                            <li>Can only be used as the last element to fulfill CR of acro series and for CV.</li>
                            <li>Can be used anywhere for Series Bonus.</li>
                            <li>Will be considered the same element as Onodi.</li>
                        </ul>
                        <h4>Specific Elements</h4>
                        <ul>
                            <li><span className="wag"></span> hands must land in cross position.
                            <Table>
                                    <tbody>
                                        <tr>
                                            <td><img src="images\hands_cross_position.png" alt="Both hands in cross position" /></td>
                                            <td>Hands placed together in Cross position – Correct</td>
                                        </tr>
                                        <tr>
                                            <td><img src="images\hands_one_cross_one_side.png" alt="One hand in side position" /></td>
                                            <td>If front hand completed turn – Apply deductions for Precision</td>
                                        </tr>
                                        <tr>
                                            <td><img src="images\hands_side_position.png" alt="Both hands in side position" /></td>
                                            <td>Hands in Side Position – Apply deductions for Precision</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default FigWagBbCop;