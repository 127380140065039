import * as ml from './Wagml';

/**
 * Handles how state changes in the `useWagml` hook.
 */
export const wagmlReducer = (oldState, action) => {

    // Reset some flags
    oldState.renderExerciseToImageRequested = false;
    oldState.renderedExerciseAsImage = null;

    switch (action.type) {
        case 'CREATE_NEW_FX_EXERCISE': {
            let wagml = ml.getEmptyExercise('FX');
            return {
                ...oldState,
                wagml: wagml
            };
        }
        case 'RENDER_EXERCISE_AS_IMAGE':
            return {
                ...oldState,
                renderExerciseToImageRequested: true
            };
        case 'RENDERED_EXERCISE_AS_IMAGE':
            return {
                ...oldState,
                renderedExerciseAsImage: action.renderedImage
            };
        case 'LOADED_FROM_FILE':
            return {
                ...oldState,
                wagml: action.wagml,
                CannotEvaluateBecauseOfMissingLicense: action.CannotEvaluateBecauseOfMissingLicense
            };
        case 'MOVE_CURSOR_HOME':
            return {
                ...oldState
            };
        case 'MOVE_CURSOR_END':
            return {
                ...oldState
            };
        case 'MOVE_CURSOR_LEFT':
            let wagml = ml.moveCursorLeft(oldState.wagml);

            return {
                ...oldState,
                wagml: wagml
            };
        case 'MOVE_CURSOR_RIGHT': {
            let wagml = ml.moveCursorRight(oldState.wagml);

            return {
                ...oldState,
                wagml: wagml
            };
        }
        case 'WAGML_KEYBOARD_CLICKED': {
            const originalWagml = oldState.wagml;
            const keystroke = action.keystroke;
            let wagml = ml.addKeyStroke(originalWagml, keystroke);
            return {
                ...oldState,
                wagml: wagml
            }
        }
        case 'MOVE_CURSOR_AFTER_ELEMENT': {
            // Given the id of the element, move the cursor after that element and stay in the same connection.
            const originalWagml = oldState.wagml;
            const idElement = action.idElement;
            let wagml = ml.moveCursorAfterElement(originalWagml, idElement);
            return {
                ...oldState,
                wagml: wagml
            }
        }
        case 'MOVE_CURSOR_AFTER_ELEMENT_CONNECTION': {
            // Given the id of the element, move the cursor after that elements connection it is in.
            const originalWagml = oldState.wagml;
            const idElement = action.idElement;
            let wagml = ml.moveCursorAfterElementConnection(originalWagml, idElement);
            return {
                ...oldState,
                wagml: wagml
            }
        }
        case 'MOVE_CURSOR_BEFORE_ELEMENT': {
            // Given the id of the element, move the cursor before that element and stay in the same connection.
            const originalWagml = oldState.wagml;
            const idElement = action.idElement;
            let wagml = ml.moveCursorBeforeElement(originalWagml, idElement);
            return {
                ...oldState,
                wagml: wagml
            }
        }
        case 'MOVE_CURSOR_BEFORE_ELEMENT_CONNECTION': {
            // Given the id of the element, move the cursor before that element but not in its connection.
            const originalWagml = oldState.wagml;
            const idElement = action.idElement;
            let wagml = ml.moveCursorBeforeElementConnection(originalWagml, idElement);
            return {
                ...oldState,
                wagml: wagml
            }
        }

        default:
            return {
                ...oldState,
            };
    }
};
