export default class GsShopClient {

    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    async AddNewPackage(accessToken, packageCode) {
        if (!accessToken)
            throw new Error('Access token is required.');

        if (!packageCode)
            throw new Error('Package code is missing.');

        let url = this.baseUrl + `AddNewPackage?packageCode=${packageCode}`;

        let data = {
            method: 'POST',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        try {
            const response = await fetch(url, data);
            if (response.ok) {
                const json = await response.text();
                return JSON.parse(json);
            } else {
                throw response;
            }
        } catch (e) {
            console.log('ERROR: ' + e);
            throw e;
        }
    }
}