import { Visibility } from '../WindowsBase/Visibility';
import SymbolUIElement from './SymbolUIElement';
import Spacer from './Spacer';
import StartOfExerciseSpacer from './StartOfExerciseSpacer';
import EndOfExerciseSpacer from './EndOfExerciseSpacer';
import NonConnectedElementSpacer from './NonConnectedElementSpacer';
import ConnectedElementSpacer from './ConnectedElementSpacer';
import Cursor from './Cursor';
import RenderOptions from './RenderOptions';
import RenderStyle from './RenderStyle';
import ConnectionIndicator from './ConnectionIndicator';

/** The part of the canvas that represents the exercise. */
export default class CanvasExercise {
    constructor(dispatchWagml) {
        this._dispatchWagml = dispatchWagml;

        this._performanceDom = null;
        this._canvasContext = null;

        // An array of CanvasUIElement objects
        this._components = [];

        // Some panel information
        this._maxSymbolAscent = 0;
        this._maxSymbolDescent = 0;

        this._showCursor = true;
        this._isCursorVisible = true;

        // Timer to make the cursor blink
        this._cursorBlinkTimer = null;
    }

    get dispatchWagml() { return this._dispatchWagml; }

    get performanceDom() { return this._performanceDom; }
    set performanceDom(performanceDom) {
        console.log('CanvasExercise.setPerformanceDom(): ', performanceDom);
        this._performanceDom = performanceDom;
    }

    get ctx() { return this._canvasContext; }
    set ctx(context) { this._canvasContext = context; }

    get layoutSettings() { return this._layoutSettings; }
    set layoutSettings(settings) { this._layoutSettings = settings; }

    get components() { return this._components; }

    /** Gets or sets a value indicating whether or not the cursor needs to be visible. */
    get showCursor() { return this._showCursor; }
    set showCursor(show) {
        this._showCursor = show;

        if (show) {
            // If a timer was already started, kill it.
            if (null != this._cursorBlinkTimer)
                clearInterval(this._cursorBlinkTimer);

            // Start the blink timer
            this.isCursorVisible = false;
            this.onCursorBlinkTimer();
            this._cursorBlinkTimer = setInterval(() => this.onCursorBlinkTimer(), 500);
        } else {
            if (null !== this._cursorBlinkTimer)
                clearInterval(this._cursorBlinkTimer);

            this._cursorBlinkTimer = null;

            // Stopping the time is not enough. You need to clear the cursor because it might be visible the moment you stop the timer.
            // Therefor blink one more time
            this.isCursorVisible = true;
            this.onCursorBlinkTimer();
        }
    }

    /** Gets or sets the state of the blinking cursor: visible or not. */
    get isCursorVisible() { return this._isCursorVisible; }
    set isCursorVisible(visible) { this._isCursorVisible = visible; }

    get maxSymbolAscent() { return this._maxSymbolAscent; }
    set maxSymbolAscent(ascent) { this._maxSymbolAscent = ascent; }

    get maxSymbolDescent() { return this._maxSymbolDescent; }
    set maxSymbolDescent(descent) { this._maxSymbolDescent = descent; }

    /** Delete all existing components and create new ones */
    createAllComponents() {
        while (0 < this.components.length) {
            this.components.pop();
        }

        if (!this.performanceDom)
            return;

        console.log('createAllComponents() DOM = ', this.performanceDom);

        let elementRenderId = 0;
        let idConnection = 0;
        let newComponent = null;
        let setCursorOnNextSpacer = false;
        let isCursorLastOfConnection = false;
        let isCursorBetweenTwoConnections = false;
        let isCursorFirstOfConnection = false;

        const renderPerformanceElement = (performanceElement, inConnection) => {
            // nodeType(1) is an element. nodeType(3) is text
            if (1 !== performanceElement.nodeType)
                return;

            // The xml node name can be 'element', 'connection'
            if (performanceElement.nodeName === "we:connection") {
                // Increment the connection id
                idConnection++;

                // Process all child nodes in the connection
                for (let i = 0; i < performanceElement.childNodes.length; i++) {
                    renderPerformanceElement(performanceElement.childNodes[i], true);
                }

                return;
            }

            if (performanceElement.nodeName === "we:cursor") {
                const cursorPosition = performanceElement.getAttribute('editor:cursorPosition');
                
                // If the cursor is the first element in the exercise, set it on the start spacer
                // else set it on the next spacer that is created
                let previous = this.components[this.components.length - 1];
                if (previous instanceof StartOfExerciseSpacer) {
                    previous.hasCursor = true;

                    // Cursor can be in the middle of the start spacer (meaning before the first element/connection)
                    // or at the end of the start spacer (meaning inside of the first connection)
                    // Where the cursor is at, can be seen by evaluating the cursor xml attribute 'editor:cursorPosition'
                    // Possible values for this attribute:
                    //  startOfSpacer (not possible for the start spacer)
                    //  middleOfSpacer
                    //  endOfSpacer
                    if (!cursorPosition) {
                        previous.cursorInTheMiddle = true;
                        previous.cursorAfterSpacer = false;
                    } else {
                        previous.cursorInTheMiddle = cursorPosition === 'middleOfSpacer';
                        previous.cursorAfterSpacer = !previous.cursorInTheMiddle;
                    }
                } else {
                    setCursorOnNextSpacer = true;

                    // Cursor can be in the middle of the start spacer (meaning before the first element/connection)
                    // or at the end of the start spacer (meaning inside of the first connection)
                    // Where the cursor is at, can be seen by evaluating the cursor xml attribute 'editor:cursorPosition'
                    // Possible values for this attribute:
                    //  startOfSpacer
                    //  middleOfSpacer
                    //  endOfSpacer
                    isCursorBetweenTwoConnections = cursorPosition === 'middleOfSpacer';
                    isCursorLastOfConnection = cursorPosition === 'startOfSpacer';
                    isCursorFirstOfConnection = cursorPosition === 'endOfSpacer';

                    // // 3 possibilities: 
                    // //  1) cursor is at the end of a connection
                    // //  2) cursor is between two connections
                    // //  3) cursor is first of a connection
                    // if (!inConnection) {
                    //     // Between connections
                    //     console.log('Cursor element is between connections');
                    //     isCursorBetweenTwoConnections = true;
                    //     isCursorLastOfConnection = false;
                    //     isCursorFirstOfConnection = false;
                    // } else if (!performanceElement.previousElementSibling) {
                    //     // First in a connection
                    //     console.log('Cursor element is first in connection');
                    //     isCursorBetweenTwoConnections = false;
                    //     isCursorLastOfConnection = false;
                    //     isCursorFirstOfConnection = true;
                    // } else {
                    //     // Last in a connection
                    //     console.log('Cursor element is last in connection');
                    //     isCursorBetweenTwoConnections = false;
                    //     isCursorLastOfConnection = true;
                    //     isCursorFirstOfConnection = false;
                    // }
                }

                return;
            }

            // The xml node name can only be 'element' at this point
            if (performanceElement.nodeName !== "we:element") {
                return;
            }

            // What was the previous element? There is always minimum one element (StartOfExerciseSpacer) already in the collection 
            const previousComponent = this.components[this.components.length - 1];

            // If the previous component was a symbol, add a spacer
            if (previousComponent instanceof SymbolUIElement) {
                if (inConnection) {
                    const sameConnection = previousComponent.idConnection === idConnection;

                    if (sameConnection)
                        newComponent = new ConnectedElementSpacer(++elementRenderId);
                    else
                        newComponent = new NonConnectedElementSpacer(++elementRenderId);

                    newComponent.idConnection = idConnection;
                    this.components.push(newComponent);
                } else {
                    newComponent = new NonConnectedElementSpacer(++elementRenderId);
                    this.components.push(newComponent);
                }

                // Deal with the cursor if one is set
                if (setCursorOnNextSpacer) {
                    newComponent.hasCursor = true;
                    setCursorOnNextSpacer = false;

                    // The non-connected spacer can show the cursor at 3 different locations
                    if (newComponent instanceof NonConnectedElementSpacer) {
                        if (isCursorBetweenTwoConnections) {
                            newComponent.cursorInTheMiddle = true;
                            newComponent.cursorAfterSpacer = false;
                            newComponent.cursorBeforeSpacer = false;
                        } else if (isCursorFirstOfConnection) {
                            newComponent.cursorAfterSpacer = true;
                            newComponent.cursorInTheMiddle = false;
                            newComponent.cursorBeforeSpacer = false;
                        } else if (isCursorLastOfConnection) {
                            newComponent.cursorBeforeSpacer = true;
                            newComponent.cursorInTheMiddle = false;
                            newComponent.cursorAfterSpacer = false;
                        }
                    }
                }
            }

            // Add an entry in the map for this element
            const text = performanceElement.getAttribute("code");
            const elementId = performanceElement.getAttribute("id");
            newComponent = new SymbolUIElement(elementId, text);
            newComponent.idConnection = inConnection ? idConnection : 0;

            this.components.push(newComponent);
        }

        // Every exercise starts with the StartOfExerciseSpace
        this.components.push(new StartOfExerciseSpacer(++elementRenderId));

        // Loop over every element in the performance section and create the necessary components
        for (let i = 0; i < this.performanceDom.childNodes.length; i++) {
            if (1 === this.performanceDom.childNodes[i].nodeType) {
                renderPerformanceElement(this.performanceDom.childNodes[i], false);
            }
        }

        // Every exercise ends with the EndOfExerciseSpace
        const endSpacer = new EndOfExerciseSpacer(++elementRenderId);
        if (setCursorOnNextSpacer) {
            endSpacer.hasCursor = true;

            if (isCursorBetweenTwoConnections) {
                endSpacer.cursorInTheMiddle = true;
                endSpacer.cursorBeforeSpacer = false;
            } else if (isCursorLastOfConnection) {
                endSpacer.cursorBeforeSpacer = true;
                endSpacer.cursorInTheMiddle = false;
            }

            setCursorOnNextSpacer = false;
        }

        this.components.push(endSpacer);

        // For every element in a connection, create a ConnectionIndicator
        let connectionIndicators = [];
        let lastConnectionId = 0;
        for (let i = 0; i < this.components.length; i++) {
            const component = this.components[i];

            // Only look at symbol elements
            if (!(component instanceof SymbolUIElement)) {
                continue;
            }

            // Only look at elements that are in a connection
            if (0 === component.idConnection) {
                continue;
            }

            // If the connection id differs from the last one, create a new ConnectionIndicator
            if (component.idConnection !== lastConnectionId) {
                lastConnectionId = component.idConnection;

                const connectionIndicator = new ConnectionIndicator(++elementRenderId);
                connectionIndicators.push(connectionIndicator);
            }

            // Add the element to the list of connectedElements
            const indicator = connectionIndicators[connectionIndicators.length - 1];
            indicator.connectedElements.push(component);
        }

        // Add the indicators to the components list
        for (let i = 0; i < connectionIndicators.length; i++) {
            this.components.push(connectionIndicators[i]);
        }

        this.logComponents('Components after createAllComponents()');
    }

    /** Positions all controls in this.components and display them. */
    render() {
        const oldShowCursor = this.showCursor;

        this.showCursor = false;
        this.measure();
        this.arrange();
        this.draw();

        // For debugging only
        if (RenderOptions.showDebugInfo)
            this.drawComponentBounds();

        this.showCursor = oldShowCursor;
    }

    /** Not the content has changed, but maybe the screen was resized. */
    updateLayout() {
        // Set all components dirty flag to true
        for (let i = 0; i < this.components.length; i++) {
            this.components[i].isDirty = true;
        }

        this.render();
    }

    /** Measure the requested size of all components. This will result in all properties constraintSize to be set to the desired/required size. */
    measure() {
        this.maxSymbolHeight = 0;

        this.maxSymbolAscent = 0;
        this.maxSymbolDescent = 0;

        for (let i = 0; i < this.components.length; i++)
            this.components[i].measure(this.ctx);

        // Loop over the components again and get the maximum text ascent and descent
        for (let i = 0; i < this.components.length; i++) {
            const component = this.components[i];
            if (component instanceof SymbolUIElement) {
                if (component.fontBoundingBoxAscent > this.maxSymbolAscent) {
                    this.maxSymbolAscent = component.fontBoundingBoxAscent;
                }

                if (component.fontBoundingBoxDescent > this.maxSymbolDescent) {
                    this.maxSymbolDescent = component.fontBoundingBoxDescent;
                }
            }
        }

        this.maxSymbolHeight = this.maxSymbolDescent + this.maxSymbolAscent;

        // An empty document has no symbols so the maxHeight is still 0.
        if (0 === this.maxSymbolHeight) {
            this.maxSymbolHeight = SymbolUIElement.getDefaultFontHeight(this.ctx);
        }

        // Next, set the actualHeight and actualBaseline property
        for (let i = 0; i < this.components.length; i++) {
            const component = this.components[i];
            if (component instanceof SymbolUIElement) {
                component.actualSize.width = component.constraintSize.width;
                component.actualSize.height = this.maxSymbolHeight;
                component.actualTextBaseline = this.maxSymbolDescent;

            } else if (component instanceof Spacer) {
                component.actualSize.width = component.constraintSize.width;
                component.actualSize.height = this.maxSymbolHeight;

            } else if (component instanceof Cursor) {
                component.actualSize.width = component.constraintSize.width;
                component.actualSize.height = this.maxSymbolHeight;
            }
        }
    }

    /** Size and position all components. */
    arrange() {
        let baseLine = 100;

        // Start with arranging the main element symbols
        let posX = RenderStyle.leftMargin;
        let bottomOfSymbols = 0;

        for (let i = 0; i < this.components.length; i++) {
            const component = this.components[i];

            if (component instanceof SymbolUIElement) {
                component.position.x = posX;
                component.position.y = baseLine;
                posX += component.constraintSize.width;

                bottomOfSymbols = Math.max(component.position.y + component.constraintSize.height, bottomOfSymbols);
            } else if (component instanceof Spacer) {
                component.position.x = posX;
                component.position.y = baseLine;
                posX += component.constraintSize.width;
            } else if (component instanceof Cursor) {
                component.position.x = posX;
                component.position.y = baseLine;

                posX += component.constraintSize.width;
            }
        }

        // Next arrange the connection indicators
        for (let i = 0; i < this.components.length; i++) {
            const component = this.components[i];

            if (component instanceof ConnectionIndicator) {
                component.position.x = component.connectedElements[0].position.x;
                component.position.y = bottomOfSymbols;

                component.actualSize.width = component.constraintSize.width;
                component.actualSize.height = component.constraintSize.height;
            }
        }
    }

    /** Draw all components onto the canvas */
    draw() {
        for (let i = 0; i < this.components.length; i++) {
            this.components[i].draw(this.ctx);
        }
    }

    drawComponentBounds() {
        for (let i = 0; i < this.components.length; i++) {
            this.components[i].drawComponentBounds(this.ctx);
        }
    }

    onCursorBlinkTimer() {
        if (this.isCursorVisible) {
            // Cursor was visible, hide it now
            for (let i = 0; i < this.components.length; i++) {
                if (this.components[i].hasCursor) {
                    this.components[i].hideCursor(this.ctx);
                }
            }

            this.isCursorVisible = !this.isCursorVisible;
        } else {
            // Cursor was not visible, show it now
            for (let i = 0; i < this.components.length; i++) {
                if (this.components[i].hasCursor) {
                    this.components[i].showCursor(this.ctx);
                }
            }

            this.isCursorVisible = !this.isCursorVisible;
        }
    }

    /** Deal with the mouse being over an element in the exercise. Redraw if necessary. 
     *  Return value: true if the mouse event was processed by a UI element; otherwise false.
    */
    onMouseMove(mousePosition, e) {
        let isHovered = false;

        for (let i = 0; i < this.components.length; i++) {
            const component = this.components[i];
            if (component.visibility === Visibility.Visible && !component.actualSize.isEmpty) {
                if (component.isPointInElement(mousePosition)) {
                    component.isHovered = true;
                    isHovered = true;

                    // Update the mouse cursor
                    e.target.style.cursor = component.mouseCursorStyle;
                } else {
                    component.isHovered = false;
                }
            }
        }

        // Re-render the dirty components
        for (let i = 0; i < this.components.length; i++) {
            const component = this.components[i];
            if (component.visibility === Visibility.Visible && !component.actualSize.isEmpty) {
                if (component.isDirty) {
                    component.draw(this.ctx);
                }
            }
        }

        return isHovered;
    }

    /** Clicking a component select/deselect it */
    onMouseLeftButtonClick(mousePosition) {
        // Invert the selected state of the component(s) under the cursor . Deselect the other components
        for (let i = 0; i < this.components.length; i++) {
            const component = this.components[i];
            if (component.visibility === Visibility.Visible && !component.actualSize.isEmpty) {
                if (component.isPointInElement(mousePosition)) {
                    if (component instanceof (SymbolUIElement)) {
                        component.isSelected = !component.isSelected;
                    } else if (component instanceof ConnectedElementSpacer) {
                        // The user clicked on the space between two elements. Move the cursor to this location.
                        let index = i - 1;
                        let previousSymbol = this.components[index];
                        while (index >= 1 && !(previousSymbol instanceof SymbolUIElement)) {
                            index--;
                            previousSymbol = this.components[index];
                        }

                        const idElement = previousSymbol.id;
                        this.dispatchWagml({ type: 'MOVE_CURSOR_AFTER_ELEMENT', idElement: idElement });
                    } else if (component instanceof NonConnectedElementSpacer) {
                        // The user clicked on the space between two non-connected elements. Move the cursor to this location.
                        // User can click in the left region, the middle region or the right region.
                        const region = component.getRegionPointIsIn(mousePosition);

                        switch (region) {
                            case 'left': {
                                let index = i - 1;
                                let previousSymbol = this.components[index];
                                while (index >= 1 && !(previousSymbol instanceof SymbolUIElement)) {
                                    index--;
                                    previousSymbol = this.components[index];
                                }

                                const idElement = previousSymbol.id;
                                this.dispatchWagml({ type: 'MOVE_CURSOR_AFTER_ELEMENT', idElement: idElement });
                            } break;
                            case 'middle': {
                                let index = i - 1;
                                let previousSymbol = this.components[index];
                                while (index >= 1 && !(previousSymbol instanceof SymbolUIElement)) {
                                    index--;
                                    previousSymbol = this.components[index];
                                }

                                const idElement = previousSymbol.id;
                                this.dispatchWagml({ type: 'MOVE_CURSOR_AFTER_ELEMENT_CONNECTION', idElement: idElement });
                            } break;
                            case 'right':
                                if (i + 1 < this.components.length) {
                                    let index = i + 1;
                                    let nextSymbol = this.components[index];
                                    while (index < this.components.length && !(nextSymbol instanceof SymbolUIElement)) {
                                        index++;
                                        nextSymbol = this.components[index];
                                    }
                                    const idElement = nextSymbol.id;
                                    this.dispatchWagml({ type: 'MOVE_CURSOR_BEFORE_ELEMENT', idElement: idElement });
                                }
                                break;
                            default: break;
                        }
                        let index = i - 1;
                        let previousSymbol = this.components[index];
                        while (index >= 1 && !(previousSymbol instanceof SymbolUIElement)) {
                            index--;
                            previousSymbol = this.components[index];
                        }
                    } else if (component instanceof StartOfExerciseSpacer) {
                        // The user clicked in the space before the first element. Move the cursor to this location.
                        // User can click in the the middle region or the right region.
                        const region = component.getRegionPointIsIn(mousePosition);

                        switch (region) {
                            case 'middle': {
                                // Find the first symbol element
                                let index = 0;
                                let nextSymbol = this.components[index];
                                while (index < this.components.length && !(nextSymbol instanceof SymbolUIElement)) {
                                    index++;
                                    nextSymbol = this.components[index];
                                }

                                // If no elements are found (an empty document), then do nothing
                                if (nextSymbol && (nextSymbol instanceof SymbolUIElement)) {
                                    const idElement = nextSymbol.id;
                                    this.dispatchWagml({ type: 'MOVE_CURSOR_BEFORE_ELEMENT_CONNECTION', idElement: idElement });
                                }
                            } break;
                            case 'right': {
                                // Find the first symbol element
                                let index = 0;
                                let nextSymbol = this.components[index];
                                while (index < this.components.length && !(nextSymbol instanceof SymbolUIElement)) {
                                    index++;
                                    nextSymbol = this.components[index];
                                }

                                // If no elements are found (an empty document), then do nothing
                                if (nextSymbol && (nextSymbol instanceof SymbolUIElement)) {
                                    const idElement = nextSymbol.id;
                                    this.dispatchWagml({ type: 'MOVE_CURSOR_BEFORE_ELEMENT', idElement: idElement });
                                }
                            } break;
                            default: break;
                        }
                        let index = i - 1;
                        let previousSymbol = this.components[index];
                        while (index >= 1 && !(previousSymbol instanceof SymbolUIElement)) {
                            index--;
                            previousSymbol = this.components[index];
                        }
                    } else if (component instanceof EndOfExerciseSpacer) {
                        // The user clicked in the space after the last element. Move the cursor to this location.
                        // User can click in the middle region or the left region.
                        const region = component.getRegionPointIsIn(mousePosition);

                        switch (region) {
                            case 'left': {
                                let index = i - 1;
                                let previousSymbol = this.components[index];
                                while (index >= 1 && !(previousSymbol instanceof SymbolUIElement)) {
                                    index--;
                                    previousSymbol = this.components[index];
                                }

                                const idElement = previousSymbol.id;
                                this.dispatchWagml({ type: 'MOVE_CURSOR_AFTER_ELEMENT', idElement: idElement });
                            } break;
                            case 'middle': {
                                let index = i - 1;
                                let previousSymbol = this.components[index];
                                while (index >= 1 && !(previousSymbol instanceof SymbolUIElement)) {
                                    index--;
                                    previousSymbol = this.components[index];
                                }

                                const idElement = previousSymbol.id;
                                this.dispatchWagml({ type: 'MOVE_CURSOR_AFTER_ELEMENT_CONNECTION', idElement: idElement });
                            } break;
                            default: break;
                        }
                        let index = i - 1;
                        let previousSymbol = this.components[index];
                        while (index >= 1 && !(previousSymbol instanceof SymbolUIElement)) {
                            index--;
                            previousSymbol = this.components[index];
                        }

                    }
                } else {
                    if (component instanceof (SymbolUIElement)) {
                        component.isSelected = false;
                    }
                }
            }
        }

        // Re-render the dirty components
        for (let i = 0; i < this.components.length; i++) {
            const component = this.components[i];
            if (component.visibility === Visibility.Visible && !component.actualSize.isEmpty) {
                if (component.isDirty) {
                    component.draw(this.ctx);
                }
            }
        }
    }

    onMouseMiddleButtonClick(mousePosition) {

    }

    onMouseRightButtonClick(mousePosition) {

    }

    /** Check if the point is over an element in the exercise */
    isPointInElement(point) {
        for (let i = 0; i < this.components.length; i++) {
            const component = this.components[i];
            if (component.isPointInElement(point)) {
                return true;
            }
        }
        return false;
    }

    /** Debug: shows the components in the console */
    logComponents(title) {
        if (title) {
            console.log(`START OF: ${title}`);
        }

        if (!this.components || 0 === this.components.length) {
            console.log('logComponents(): No components');
            return;
        }

        console.log('Components in the CanvasExercise:');
        for (let i = 0; i < this.components.length; i++)
            console.log(this.components[i]);

        if (title) {
            console.log(`END OF: ${title}`);
        }
    }

}
