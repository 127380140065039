export default class CanvasScoreSlip {
    constructor(layoutSettings) {
        this._layoutSettings = layoutSettings;
        this._exercisePropertiesDom = null;
        this._evaluationDom = null;
        this._canvasContext = null;

        this.createAllComponents();
    }

    get layoutSettings() { return this._layoutSettings; }

    get ctx() { return this._canvasContext; }
    set ctx(context) { this._canvasContext = context; }

    get exercisePropertiesDom() { return this._exercisePropertiesDom; }
    set exercisePropertiesDom(dom) { this._exercisePropertiesDom = dom; }

    get evaluationDom() { return this._evaluationDom; }
    set evaluationDom(dom) { this._evaluationDom = dom; }

    /** Since the scoreslip never changes its components after they have been created, this method only creates the elements the first time it is called. */
    createAllComponents() {
    }

    /** Positions all controls and display them. */
    render() {
    }

    /** Deal with the mouse being over an element in the scoreslip. Redraw if necessary. */
    onMouseMove(mousePosition) {
        let isProcessed = false;

        return isProcessed;
    }

    onMouseLeftButtonClick(mousePosition) {

    }

    onMouseMiddleButtonClick(mousePosition) {

    }

    onMouseRightButtonClick(mousePosition) {

    }

    /** Check if the point is over an element in the scoreslip */
    isPointInElement(point) {
        return false;
    }
}