import React from 'react';

const Support = () => {
    return (
        <>
            <h1>Support</h1>
        </>
    );
}

export default Support;