import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GsNav from './Components/GsNav/GsNav';
import Home from './Pages/Home';
import Profile from './Pages/Profile';
import Downloads from './Pages/Downloads';
import Contact from './Pages/Contact';
import Logon from './Pages/Logon';
import Account from './Pages/Account';
import WagVt from './Pages/WagVt';
import WagUb from './Pages/WagUb';
import WagBb from './Pages/WagBb';
import FigWagFxParent from './Pages/FigWagFxParent';
import FigWagVtLibrary from './Pages/FigWagVtLibrary';
import FigWagUbLibrary from './Pages/FigWagUbLibrary';
import FigWagBbLibrary from './Pages/FigWagBbLibrary';
import FigWagFxLibrary from './Pages/FigWagFxLibrary';
import FigWagVtCop from './Pages/FigWagVtCop';
import FigWagUbCop from './Pages/FigWagUbCop';
import FigWagBbCop from './Pages/FigWagBbCop';
import FigWagFxCop from './Pages/FigWagFxCop';
import Products from './Pages/Products';
import Checkout from './Pages/Checkout';
import Support from './Pages/Support';

function App() {
    return (
        <div className="body">
            <GsNav />
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/profile" component={Profile} />
                <Route path="/downloads" component={Downloads} />
                <Route path="/support" component={Support} />
                <Route path="/contact" component={Contact} />
                <Route path="/logon" component={Logon} />
                <Route path="/account" component={Account} />
                <Route path="/wag-vt" component={WagVt} />
                <Route path="/wag-ub" component={WagUb} />
                <Route path="/wag-bb" component={WagBb} />
                <Route path="/fig-wag-fx" component={FigWagFxParent} />
                <Route path="/fig-wag-vt-library" component={FigWagVtLibrary} />
                <Route path="/fig-wag-ub-library" component={FigWagUbLibrary} />
                <Route path="/fig-wag-bb-library" component={FigWagBbLibrary} />
                <Route path="/fig-wag-fx-library" component={FigWagFxLibrary} />
                <Route path="/fig-wag-vt-cop" component={FigWagVtCop} />
                <Route path="/fig-wag-ub-cop" component={FigWagUbCop} />
                <Route path="/fig-wag-bb-cop" component={FigWagBbCop} />
                <Route path="/fig-wag-fx-cop" component={FigWagFxCop} />
                <Route path="/products" component={Products} />
                <Route path="/checkout" render={() => { return <Checkout /> }} />
            </Switch>
        </div>
    );
}

export default App;
