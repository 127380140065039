import RenderOptions from './RenderOptions';
import * as Colors from '../Utilities/Colors';
import Rect from '../WindowsBase/Rect';
import Thickness from '../WindowsBase/Thickness';
import Point from '../WindowsBase/Point';
import Size from '../WindowsBase/Size';
import { Visibility } from '../WindowsBase/Visibility';


/** Class representing an individual element on the canvas. */
export default class CanvasUIElement {
    constructor(id) {
        this._id = id;

        // Properties that can be set in the designer
        this._width = Number.MIN_SAFE_INTEGER;
        this._height = Number.MIN_SAFE_INTEGER;
        this._margin = new Thickness(0);
        this._padding = new Thickness(0);

        this._isHovered = false;
        this._isSelected = false;
        this._isDirty = true;

        this._position = new Point(0, 0);

        this._constraintSize = Size.empty;
        this._actualSize = Size.empty;

        this._visibility = Visibility.Visible;
        this._mouseCursorStyle = null;

        this._hasCursor = false;
        this._cursorCurrentlyVisible = false;

        this._extraWidthForClickableArea = 0;
    }

    get id() { return this._id; }
    set id(id) { this._id = id; }

    get position() { return this._position; }

    /** Gets or sets a flag indicating whether or not this component has the cursor or not. */
    get hasCursor() { return this._hasCursor; }
    set hasCursor(cursor) { this._hasCursor = cursor; }

    /** Gets or sets a value showing whether the cursor is currently visible (color = black) or not (color = white) */
    get cursorCurrentlyVisible() { return this._cursorCurrentlyVisible; }
    set cursorCurrentlyVisible(visible) { this._cursorCurrentlyVisible = visible; }

    /** Gets or sets the shape of the mouse pointer.
     *  https://www.w3schools.com/jsref/prop_style_cursor.asp
     */
    get mouseCursorStyle() { return this._mouseCursorStyle ? this._mouseCursorStyle : 'text'; }
    set mouseCursorStyle(style) { this._mouseCursorStyle = style; }

    get isDirty() { return this._isDirty; }
    set isDirty(dirty) { this._isDirty = dirty; }

    get hasFixedWidth() { return this._width !== Number.MIN_SAFE_INTEGER }
    get hasFixedHeight() { return this._height !== Number.MIN_SAFE_INTEGER }

    get constraintSize() { return this._constraintSize; }
    set constraintSize(size) { this._constraintSize = size; }

    get actualSize() { return this._actualSize; }
    set actualSize(size) { this._actualSize = size; }

    get visibility() { return this._visibility; }
    set visibility(visibility) { this._visibility = visibility; }

    get extraWidthForClickableArea() { return this._extraWidthForClickableArea; }

    get renderStyle() { return this._renderStyle; }
    set renderStyle(renderStyle) { this._renderStyle = renderStyle; }

    get isSelected() { return this._isSelected; }
    set isSelected(flag) {
        const oldValue = this._isSelected;
        this._isSelected = flag;
        this.isDirty = oldValue !== flag;
    }

    get isHovered() { return this._isHovered; }
    set isHovered(flag) {
        const oldValue = this._isHovered;
        this._isHovered = flag;

        // If the dirty flag was already set by another method, leave it set.
        if (!this.isDirty)
            this.isDirty = oldValue !== flag;
    }

    /** When set, a specific width is requested. Otherwise the layout system takes care of the width. */
    get width() { return this._width; }
    set width(width) { this._width = width; }

    /** When set, a specific height is requested. Otherwise the layout system takes care of the height. */
    get height() { return this._height; }
    set height(height) { this._height = height; }

    get padding() { return this._padding; }
    set padiing(padding) { this._padding = padding; }

    get margin() { return this._margin; }
    set margin(margin) { this._margin = margin; }

    toString() {
        return `${this.constructor.name} (${this.id})`;
    }

    drawCursor(ctx, color) {
        if (!ctx)
            return;

        if (this.actualSize.isEmpty)
            return;

        if (this.visibility !== Visibility.Visible)
            return;

        const cursorWidth = 2;
        const x = this.position.x + (this.actualSize.width / 2);
        const y = this.position.y;

        ctx.fillStyle = Colors.White;
        ctx.strokeStyle = Colors.White;
        ctx.beginPath();
        ctx.rect(x - 2, y, cursorWidth + 4, this.actualSize.height);
        ctx.closePath();
        ctx.fill();

        ctx.lineWidth = cursorWidth;
        ctx.strokeStyle = color;
        ctx.beginPath();
        ctx.moveTo(x, this.position.y);
        ctx.lineTo(x, this.position.y + this.actualSize.height);
        ctx.stroke();
    }

    showCursor(ctx) {
        this.drawCursor(ctx, Colors.Black);
        this.cursorCurrentlyVisible = true;
    }

    hideCursor(ctx) {
        this.drawCursor(ctx, Colors.White);
        this.cursorCurrentlyVisible = false;
    }

    /** Implements common behaviour that is used by all derived classes. Derived classes should first call this measure() method
     *  and only continue processing if the returned value is true; 
     */
    measure(ctx) {
        this.constraintSize = Size.empty;
        this.actualSize = Size.empty;

        if (this.visibility === Visibility.Hidden)
            return false;

        this.constraintSize.width = 0;
        this.constraintSize.height = 0;

        if (this.hasFixedWidth)
            this.constraintSize = this._width;
        if (this.hasFixedHeight)
            this.constraintSize.height = this._height;

        // Add space for the margin (margin is not part of the actualWidth and actualHeight)
        this.constraintSize.width += this.margin.left + this.margin.right;
        this.constraintSize.height += this.margin.top + this.margin.bottom;

        // If both width and height are fixed, stop here and return false
        if (this.hasFixedHeight && this.hasFixedWidth)
            return false;

        // Add space for the padding (padding is part of the actualWidth and actualHeight)
        this.constraintSize.width += this.padding.left + this.padding.right;
        this.constraintSize.height += this.padding.top + this.padding.bottom;

        return true;
    }

    draw(ctx) {
    }

    drawComponentBounds(ctx) {
        if (!ctx)
            return;

        if (this.actualSize.isEmpty)
            return;

        // Show the margins
        if (RenderOptions.showMargins) {
            ctx.beginPath();
            ctx.strokeStyle = Colors.Yellow;
            ctx.lineWidth = 1;

            // Top margin
            ctx.moveTo(this.position.x, this.position.y + this.margin.top);
            ctx.lineTo(this.position.x + this.actualSize.width, this.position.y + this.margin.top);

            // Right margin
            ctx.moveTo(this.position.x + this.actualSize.width - this.margin.right, this.position.y);
            ctx.lineTo(this.position.x + this.actualSize.width - this.margin.right, this.position.y + this.actualSize.height);

            // Bottom margin
            ctx.moveTo(this.position.x, this.position.y + this.actualSize.height - this.margin.bottom);
            ctx.lineTo(this.position.x + this.actualSize.width, this.position.y + this.actualSize.height - this.margin.bottom);

            // Left margin
            ctx.moveTo(this.position.x + this.margin.left, this.position.y);
            ctx.lineTo(this.position.x + this.margin.left, this.position.y + this.actualSize.height);

            ctx.stroke();
        }

        // Show the position
        if (RenderOptions.showComponentCorners) {
            ctx.beginPath();
            ctx.strokeStyle = Colors.Red;
            ctx.lineWidth = 1;

            // Upper left corner
            ctx.moveTo(this.position.x - 8, this.position.y);
            ctx.lineTo(this.position.x, this.position.y);
            ctx.lineTo(this.position.x, this.position.y - 8);

            // Lower right corner
            ctx.moveTo(this.position.x + this.actualSize.width + 8, this.position.y + this.actualSize.height);
            ctx.lineTo(this.position.x + this.actualSize.width, this.position.y + this.actualSize.height);
            ctx.lineTo(this.position.x + this.actualSize.width, this.position.y + this.actualSize.height + 8);

            // Upper right corner
            ctx.moveTo(this.position.x + this.actualSize.width + 8, this.position.y);
            ctx.lineTo(this.position.x + this.actualSize.width, this.position.y);
            ctx.lineTo(this.position.x + this.actualSize.width, this.position.y - 8);

            // Lower left corner
            ctx.moveTo(this.position.x - 8, this.position.y + this.actualSize.height);
            ctx.lineTo(this.position.x, this.position.y + this.actualSize.height);
            ctx.lineTo(this.position.x, this.position.y + this.actualSize.height + 8);

            ctx.stroke();
        }

        // ctx.strokeStyle = Colors.Black;
        // ctx.strokeRect(this.position.x, this.position.y, this.actualSize.width, this.actualSize.height);
        // ctx.strokeStyle = Colors.Yellow;
        // ctx.moveTo(this.position.x, this.position.y);
        // ctx.lineTo(this.position.x + this.actualSize.height, this.position.y + this.actualSize.height);
        ctx.stroke();
    }

    /** Indicates whether the component contains the specified point. */
    isPointInElement(point) {
        const rect = new Rect(this.position.x - this.extraWidthForClickableArea, this.position.y, this.actualSize.width + (2 * this.extraWidthForClickableArea), this.actualSize.height);
        return rect.containsPoint(point);
    }
}
