import CanvasUIElement from './CanvasUIElement';
import RenderStyle from './RenderStyle';
import * as Colors from '../Utilities/Colors';

export default class SymbolUIElement extends CanvasUIElement {
    constructor(id, symbolText) {
        super(id);
        
        this._symbolText = symbolText;
        this.mouseCursorStyle = 'pointer';

        // Reduce the clickable area for symbols in favour of the spacers around the symbols
        this._extraWidthForClickableArea = (-1) * RenderStyle.extraWidthForClickableAreaSpacers;

        // Elements that are in the same connection, have the same idConnection
        this._idConnection = 0;

        this._fontBoundingBoxAscent = 0;
        this._fontBoundingBoxDescent = 0;

        this._actualTextBaseline = 0;
    }

    get symbolText() { return this._symbolText; }
    set symbolText(symbolText) { this._symbolText = symbolText; }

    /** Gets or sets the id of the connection the elements belongs to. When 0 the element is not in a connection.
     *  Every connection has its own unique id. To know if two elements belong to the same connection, compare their idConnection.
     */
    get idConnection() { return this._idConnection; }
    set idConnection(id) { this._idConnection = id; }

    get fontBoundingBoxAscent() { return this._fontBoundingBoxAscent };
    set fontBoundingBoxAscent(ascent) { this._fontBoundingBoxAscent = ascent; }

    get fontBoundingBoxDescent() { return this._fontBoundingBoxDescent; }
    set fontBoundingBoxDescent(descent) { this._fontBoundingBoxDescent = descent; }

    get actualTextBaseline() { return this._actualTextBaseline; }
    set actualTextBaseline(baseline) { this._actualTextBaseline = baseline; }

    static getDefaultFontHeight(ctx) {
        if(!ctx)
            return 0;

        ctx.font = RenderStyle.exerciseFont;
        ctx.textBaseline = "alphabetic";
        const measurement = ctx.measureText('');
        return measurement.fontBoundingBoxAscent + measurement.fontBoundingBoxDescent;
    }

    draw(ctx) {
        if (!ctx)
            return;

        if (this.actualSize.isEmpty)
            return;

        if (!this.isDirty)
            return;
        this.isDirty = false;

        // Symbols background
        if (this.isSelected) {
            ctx.fillStyle = RenderStyle.selectedElementBackgroundColor;
            ctx.fillRect(this.position.x, this.position.y, this.actualSize.width, this.actualSize.height);
        } else if (this.isHovered) {
            ctx.fillStyle = RenderStyle.hoverBackgroundNonSelectedElementColor;
            ctx.fillRect(this.position.x, this.position.y, this.actualSize.width, this.actualSize.height);
        }
        else {
            ctx.fillStyle = RenderStyle.elementBackground;
            ctx.fillRect(this.position.x, this.position.y, this.actualSize.width, this.actualSize.height);
        }

        // Symbols
        ctx.font = RenderStyle.exerciseFont;
        ctx.textBaseline = "alphabetic";
        ctx.fillStyle = RenderStyle.exerciseSymbolsColor;
        ctx.fillText(this.symbolText, this.position.x + this.margin.left, this.position.y + this.actualSize.height - this.margin.bottom - this.actualTextBaseline);
    }

    drawComponentBounds(ctx) {
        if (!ctx)
            return;

        if (this.actualSize.isEmpty)
            return;

        super.drawComponentBounds(ctx);

        ctx.save();

        // Draw the text baseline
        ctx.beginPath();
        ctx.strokeStyle = Colors.LawnGreen;
        ctx.lineWidth = 1;
        ctx.setLineDash([5, 3]); /* dashes are 5px and spaces are 3px */
        ctx.moveTo(this.position.x, this.position.y + this.actualSize.height - this.margin.bottom - this.actualTextBaseline);
        ctx.lineTo(this.position.x + this.actualSize.width, this.position.y + this.actualSize.height - this.margin.bottom - this.actualTextBaseline);
        ctx.stroke();

        ctx.restore();
    }

    /** Measures the box size that surrounds the text. After this call property constraintSize is set to the required size. */
    measure(ctx) {
        if (!ctx)
            return;

        // Call base class for common functionality. If it returns false, the component is hidden or has a fixed size.
        // If it returns true, then constraintSize is already set with values for padding and margins.
        if (!super.measure())
            return;

        // Measure what size the text requires
        ctx.font = RenderStyle.exerciseFont;
        ctx.textBaseline = "alphabetic";
        const measurement = ctx.measureText(this.symbolText);

        if (!this.hasFixedWidth)
            this.constraintSize.width += measurement.width;

        if (!this.hasFixedHeight)
            this.constraintSize.height += measurement.fontBoundingBoxAscent + measurement.fontBoundingBoxDescent;

        this.fontBoundingBoxAscent = measurement.fontBoundingBoxAscent;
        this.fontBoundingBoxDescent = measurement.fontBoundingBoxDescent;
    }
}
