import React, { useState, useMemo, useEffect, useCallback } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

const UbLibFilter = (props) => {
    const { filterChanged } = props;

    const noPredefinedFilter = useMemo(() => { return { code: "", name: "None", description: "No predefined filter selected." }; }, []);
    const [predefinedFilter, setPredefinedFilter] = useState(noPredefinedFilter);

    const initialFilterSettings = {
        cop: '2022',
        showDiffOnly: false,

        showGroup0: false,
        showGroup1: true,
        showGroup2: true,
        showGroup3: true,
        showGroup4: true,
        showGroup5: true,
        showGroup6: true,
        showGroupAll: false,

        showDvNone: true,
        showDvA: true,
        showDvB: true,
        showDvC: true,
        showDvD: true,
        showDvE: true,
        showDvF: true,
        showDvG: true,
        showDvAll: true,

        showTypeKip: false,
        showTypeCast: false,
        showTypeClearHip: false,
        showTypeGiant: false,
        showTypeStalder: false,
        showTypePike: false,
        showTypeShapo: false,
        showTypeTkatchev: false,
        showTypeAll: false,

        showRootClearHipBwd: false,
        showRootClearHipFwd: false,
        showRootStalderHipBwd: false,
        showRootStalderHipFwd: false,
        showRootSoleCircleBwd: false,
        showRootSoleCircleFwd: false,
        showRootClearPikeBwd: false,
        showRootClearPikeFwd: false,
        showRootAdlerBwd: false,
        showRootAdlerFwd: false,
        showRootAll: false,

        filter_rotation_0: false,
        filter_rotation_180: false,
        filter_rotation_360: false,
        filter_rotation_540: false,
        filter_rotation_720: false,
        filter_rotation_900: false,
        filter_rotation_1080: false,
        filter_forward: false,
        filter_backward: false
    }

    const [filterSettings, setFilterSettings] = useState(initialFilterSettings);
    const emptyFilterSettings = useMemo(() => {
        return {
            cop: '2022',
            showDiffOnly: false,

            showGroup0: false,
            showGroup1: false,
            showGroup2: false,
            showGroup3: false,
            showGroup4: false,
            showGroup5: false,
            showGroup6: false,
            showGroupAll: false,

            showDvNone: false,
            showDvA: false,
            showDvB: false,
            showDvC: false,
            showDvD: false,
            showDvE: false,
            showDvF: false,
            showDvG: false,
            showDvAll: false,

            showTypeKip: false,
            showTypeCast: false,
            showTypeClearHip: false,
            showTypeGiant: false,
            showTypeStalder: false,
            showTypePike: false,
            showTypeShapo: false,
            showTypeTkatchev: false,
            showTypeAll: false,

            showRootClearHipBwd: false,
            showRootClearHipFwd: false,
            showRootStalderHipBwd: false,
            showRootStalderHipFwd: false,
            showRootSoleCircleBwd: false,
            showRootSoleCircleFwd: false,
            showRootClearPikeBwd: false,
            showRootClearPikeFwd: false,
            showRootAdlerBwd: false,
            showRootAdlerFwd: false,
            showRootAll: false,

            filter_rotation_0: false,
            filter_rotation_180: false,
            filter_rotation_360: false,
            filter_rotation_540: false,
            filter_rotation_720: false,
            filter_rotation_900: false,
            filter_rotation_1080: false,
            filter_forward: false,
            filter_backward: false
        }
    }, []);

    const applyPredefinedFilter = (code) => {
        switch (code) {
            case 'reset': setPredefinedFilter({ code: 'reset', name: 'Clear Filter', description: 'Clears all check boxes in the filter pane.' });
                break;
            case 'mounts': setPredefinedFilter({ code: 'mounts', name: 'Mounts', description: 'Mounts (group 1 elements)' });
                break;
            case 'dismounts': setPredefinedFilter({ code: 'dismounts', name: 'Dismounts', Description: 'Dismounts (group 6 elements)' });
                break;
            case "root_skill_excemptions": setPredefinedFilter({ code: 'root_skill_excemptions', name: 'Root skill excemptions', Description: 'Elements that do not follow the rule of maximum root skill elements.' });
                break;
            case "root_skills": setPredefinedFilter({ code: 'root_skills', name: 'Elements with root skill', Description: 'Elements that have a root skill and therefore are counted in the maximum root-skill rule.' });
                break;

            default: setPredefinedFilter(noPredefinedFilter);
                break;
        }
    }

    // React when the filter changes
    useEffect(
        () => {
            filterChanged(filterSettings);
        }, [filterSettings, filterChanged]
    );

    // React when the predefined filter is changed
    useEffect(
        () => {
            switch (predefinedFilter.code) {
                case 'reset':
                    setFilterSettings(
                        {
                            ...emptyFilterSettings
                        }
                    ); break;
                case 'mounts':
                    setFilterSettings(
                        {
                            ...emptyFilterSettings,
                            showGroup1: true
                        }
                    ); break;
                case 'dismounts':
                    setFilterSettings(
                        {
                            ...emptyFilterSettings,
                            showGroup6: true
                        }
                    ); break;
                case "root_skill_excemptions":
                    setFilterSettings(
                        {
                            ...emptyFilterSettings,
                            showTypeKip: true,
                            showTypeCast: true,
                            showTypeGiant: true
                        }
                    ); break;
                    case "root_skills":
                        setFilterSettings(
                            {
                                ...emptyFilterSettings,
                                showRootClearHipBwd: true,
                                showRootClearHipFwd: true,
                                showRootStalderHipBwd: true,
                                showRootStalderHipFwd: true,
                                showRootSoleCircleBwd: true,
                                showRootSoleCircleFwd: true,
                                showRootClearPikeBwd: true,
                                showRootClearPikeFwd: true,
                                showRootAdlerBwd: true,
                                showRootAdlerFwd: true,
                                showRootAll: true
                            }
                        ); break;
                    default:
                    break;
            }

        }, [predefinedFilter, emptyFilterSettings]
    );

    const [filterSpecifiedInUrl] = useState('');

    // Add name and description properties given the code
    const getPredefinedFilterProps = useCallback((code) => {
        switch (code) {
            case 'mounts': return { code: 'mounts', name: 'Mounts', description: 'Mounts (group 1 elements)' };
            case 'dismounts': return { code: 'dismounts', name: 'Dismounts', Description: 'Dismounts (group 6 elements)' };
            case 'root_skill_excemptions': return { code: 'root_skill_excemptions', name: 'Root skill excemptions', Description: 'Elements that are excempted from the limit imposed by rule of maximum the root skill elements.' };

            default: return noPredefinedFilter;
        }
    }, [noPredefinedFilter]);

    // Load the url query string and see if a predefined filter is specified. Apply filter when required.
    useEffect(
        () => {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            const filterFromQueryString = params.get('filter');

            // We only use this parameter when it changes. Because otherwise we would not be able to use the custom filter settings
            // since the page would always go back to the predefined filter during each render
            if (filterFromQueryString && filterSpecifiedInUrl !== filterFromQueryString) {
                setPredefinedFilter(getPredefinedFilterProps(filterFromQueryString))
            }
        }, [filterSpecifiedInUrl, getPredefinedFilterProps]
    );

    const cop2017 = (e) => {
        if (e.target.checked) {
            setFilterSettings({ ...filterSettings, cop: '2017' });
        }
        setPredefinedFilter(noPredefinedFilter);
    }

    const cop2022 = (e) => {
        if (e.target.checked) {
            setFilterSettings({ ...filterSettings, cop: '2022' });
        }
        setPredefinedFilter(noPredefinedFilter);
    }

    const showDiffOnly = (e) => {
        setFilterSettings({ ...filterSettings, showDiffOnly: e.target.checked });
        setPredefinedFilter(noPredefinedFilter);
    }

    const showGroup0 = (e) => { setFilterSettings({ ...filterSettings, showGroup0: e.target.checked, showGroupAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showGroup1 = (e) => { setFilterSettings({ ...filterSettings, showGroup1: e.target.checked, showGroupAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showGroup2 = (e) => { setFilterSettings({ ...filterSettings, showGroup2: e.target.checked, showGroupAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showGroup3 = (e) => { setFilterSettings({ ...filterSettings, showGroup3: e.target.checked, showGroupAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showGroup4 = (e) => { setFilterSettings({ ...filterSettings, showGroup4: e.target.checked, showGroupAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showGroup5 = (e) => { setFilterSettings({ ...filterSettings, showGroup5: e.target.checked, showGroupAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showGroup6 = (e) => { setFilterSettings({ ...filterSettings, showGroup6: e.target.checked, showGroupAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showGroupAll = (e) => {
        setPredefinedFilter(noPredefinedFilter);
        const checked = e.target.checked;

        if (checked) {
            setFilterSettings(
                {
                    ...filterSettings,
                    showGroup0: true,
                    showGroup1: true,
                    showGroup2: true,
                    showGroup3: true,
                    showGroup4: true,
                    showGroup5: true,
                    showGroup6: true,
                    showGroupAll: true
                }
            );
        } else {
            setFilterSettings(
                {
                    ...filterSettings,
                    showGroup0: false,
                    showGroup1: false,
                    showGroup2: false,
                    showGroup3: false,
                    showGroup4: false,
                    showGroup5: false,
                    showGroup6: false,
                    showGroupAll: false
                }
            );
        }
    }

    const showDvNone = (e) => { setFilterSettings({ ...filterSettings, showDvNone: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvA = (e) => { setFilterSettings({ ...filterSettings, showDvA: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvB = (e) => { setFilterSettings({ ...filterSettings, showDvB: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvC = (e) => { setFilterSettings({ ...filterSettings, showDvC: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvD = (e) => { setFilterSettings({ ...filterSettings, showDvD: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvE = (e) => { setFilterSettings({ ...filterSettings, showDvE: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvF = (e) => { setFilterSettings({ ...filterSettings, showDvF: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvG = (e) => { setFilterSettings({ ...filterSettings, showDvG: e.target.checked, showDvAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showDvAll = (e) => {
        setPredefinedFilter(noPredefinedFilter);
        const checked = e.target.checked;

        if (checked) {
            setFilterSettings(
                {
                    ...filterSettings,
                    showDvNone: true,
                    showDvA: true,
                    showDvB: true,
                    showDvC: true,
                    showDvD: true,
                    showDvE: true,
                    showDvF: true,
                    showDvG: true,
                    showDvAll: true
                }
            );
        } else {
            setFilterSettings(
                {
                    ...filterSettings,
                    showDvNone: false,
                    showDvA: false,
                    showDvB: false,
                    showDvC: false,
                    showDvD: false,
                    showDvE: false,
                    showDvF: false,
                    showDvG: false,
                    showDvAll: false
                }
            );
        }
    }

    const showTypeKip = (e) => { setFilterSettings({ ...filterSettings, showTypeKip: e.target.checked, showTypeAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showTypeCast = (e) => { setFilterSettings({ ...filterSettings, showTypeCast: e.target.checked, showTypeAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showTypeClearHip = (e) => { setFilterSettings({ ...filterSettings, showTypeClearHip: e.target.checked, showTypeAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showTypeGiant = (e) => { setFilterSettings({ ...filterSettings, showTypeGiant: e.target.checked, showTypeAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showTypeStalder = (e) => { setFilterSettings({ ...filterSettings, showTypeStalder: e.target.checked, showTypeAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showTypePike = (e) => { setFilterSettings({ ...filterSettings, showTypePike: e.target.checked, showTypeAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showTypeShapo = (e) => { setFilterSettings({ ...filterSettings, showTypeShapo: e.target.checked, showTypeAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showTypeTkatchev = (e) => { setFilterSettings({ ...filterSettings, showTypeTkatchev: e.target.checked, showTypeAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showTypeAll = (e) => {
        setPredefinedFilter(noPredefinedFilter);
        const checked = e.target.checked;

        if (checked) {
            setFilterSettings(
                {
                    ...filterSettings,
                    showTypeKip: true,
                    showTypeCast: true,
                    showTypeClearHip: true,
                    showTypeGiant: true,
                    showTypeStalder: true,
                    showTypePike: true,
                    showTypeShapo: true,
                    showTypeTkatchev: true,
                    showTypeAll: true
                }
            );
        } else {
            setFilterSettings(
                {
                    ...filterSettings,
                    showTypeKip: false,
                    showTypeCast: false,
                    showTypeClearHip: false,
                    showTypeGiant: false,
                    showTypeStalder: false,
                    showTypePike: false,
                    showTypeShapo: false,
                    showTypeTkatchev: false,
                    showTypeAll: false
                }
            );
        }
    }

    const showRootClearHipBwd = (e) => { setFilterSettings({ ...filterSettings, showRootClearHipBwd: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRootClearHipFwd = (e) => { setFilterSettings({ ...filterSettings, showRootClearHipFwd: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRootStalderHipBwd = (e) => { setFilterSettings({ ...filterSettings, showRootStalderHipBwd: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRootStalderHipFwd = (e) => { setFilterSettings({ ...filterSettings, showRootStalderHipFwd: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRootSoleCircleBwd = (e) => { setFilterSettings({ ...filterSettings, showRootSoleCircleBwd: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRootSoleCircleFwd = (e) => { setFilterSettings({ ...filterSettings, showRootSoleCircleFwd: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRootClearPikeBwd = (e) => { setFilterSettings({ ...filterSettings, showRootClearPikeBwd: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRootClearPikeFwd = (e) => { setFilterSettings({ ...filterSettings, showRootClearPikeFwd: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRootAdlerBwd = (e) => { setFilterSettings({ ...filterSettings, showRootAdlerBwd: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRootAdlerFwd = (e) => { setFilterSettings({ ...filterSettings, showRootAdlerFwd: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRootAll = (e) => {
        setPredefinedFilter(noPredefinedFilter);
        const checked = e.target.checked;

        if (checked) {
            setFilterSettings(
                {
                    ...filterSettings,
                    showRootClearHipBwd: true,
                    showRootClearHipFwd: true,
                    showRootStalderHipBwd: true,
                    showRootStalderHipFwd: true,
                    showRootSoleCircleBwd: true,
                    showRootSoleCircleFwd: true,
                    showRootClearPikeBwd: true,
                    showRootClearPikeFwd: true,
                    showRootAdlerBwd: true,
                    showRootAdlerFwd: true,
                    showRootAll: true
                }
            );
        } else {
            setFilterSettings(
                {
                    ...filterSettings,
                    showRootClearHipBwd: false,
                    showRootClearHipFwd: false,
                    showRootStalderHipBwd: false,
                    showRootStalderHipFwd: false,
                    showRootSoleCircleBwd: false,
                    showRootSoleCircleFwd: false,
                    showRootClearPikeBwd: false,
                    showRootClearPikeFwd: false,
                    showRootAdlerBwd: false,
                    showRootAdlerFwd: false,
                    showRootAll: false
                }
            );
        }
    }

    const showRotation0 = (e) => { setFilterSettings({ ...filterSettings, showRotation0: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRotation180 = (e) => { setFilterSettings({ ...filterSettings, showRotation180: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRotation360 = (e) => { setFilterSettings({ ...filterSettings, showRotation360: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRotation540 = (e) => { setFilterSettings({ ...filterSettings, showRotation540: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRotation720 = (e) => { setFilterSettings({ ...filterSettings, showRotation720: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRotation900 = (e) => { setFilterSettings({ ...filterSettings, showRotation900: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRotation1080 = (e) => { setFilterSettings({ ...filterSettings, showRotation1080: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showForward = (e) => { setFilterSettings({ ...filterSettings, showForward: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showBackward = (e) => { setFilterSettings({ ...filterSettings, showBackward: e.target.checked, showRootAll: false }); setPredefinedFilter(noPredefinedFilter); }
    const showRotationAll = (e) => {
        setPredefinedFilter(noPredefinedFilter);
        const checked = e.target.checked;

        if (checked) {
            setFilterSettings(
                {
                    ...filterSettings,
                    showRotation0: true,
                    showRotation180: true,
                    showRotation360: true,
                    showRotation540: true,
                    showRotation720: true,
                    showRotation900: true,
                    showRotation1080: true,
                    showForward: true,
                    showBackward: true,
                    showRotationAll: true
                }
            );
        } else {
            setFilterSettings(
                {
                    ...filterSettings,
                    showRotation0: false,
                    showRotation180: false,
                    showRotation360: false,
                    showRotation540: false,
                    showRotation720: false,
                    showRotation900: false,
                    showRotation1080: false,
                    showForward: false,
                    showBackward: false,
                    showRotationAll: false
                }
            );
        }
    }

    const wagStyle = {
        fontFamily: 'WAG Symbols',
        fontSize: 24,
        fontWeight: 'bold'
    };

    return (
        <>
            <h3>Filters</h3>
            <h4>Predefined filters:</h4>
            <DropdownButton variant='secondary' id="predefined_filters" title={predefinedFilter.name}>
                <Dropdown.Item as="button" id="filter_reset" onClick={() => applyPredefinedFilter('reset')}>Clear filter</Dropdown.Item>
                <Dropdown.Item as="button" id="filter_mounts" onClick={() => applyPredefinedFilter('mounts')}>Mounts</Dropdown.Item>
                <Dropdown.Item as="button" id="filter_dismounts" onClick={() => applyPredefinedFilter('dismounts')}>Dismounts</Dropdown.Item>
                <Dropdown.Item as="button" id="filter_root_skill_exemptions" onClick={() => applyPredefinedFilter('root_skill_excemptions')}>Root skill excemptions</Dropdown.Item>
                <Dropdown.Item as="button" id="filter_root_skills" onClick={() => applyPredefinedFilter('root_skills')}>Root skill elements</Dropdown.Item>
            </DropdownButton>
            <p><b>Filter: </b><i>{predefinedFilter.description}</i></p>
            <h4>Custom filter:</h4>
            <i>(Filter settings apply top to bottom)</i>
            <Form>
                <Form.Group>
                    <Form.Label>Version of the CoP:</Form.Label>
                    <Form.Check type='radio' checked={filterSettings.cop === '2017'} id='filter_cop_2017' onChange={cop2017} label='CoP 2017 - 2021' />
                    <Form.Check type='radio' checked={filterSettings.cop === '2022'} id='filter_cop_2022' onChange={cop2022} label='CoP 2022 - 2024' />
                    <Form.Check type='checkbox' id='filter_showDiffOnly' checked={filterSettings.showDiffOnly} onChange={showDiffOnly} label='Changed elements  only' />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Group:</Form.Label>
                    <Form.Check type='checkbox' id='filter_group_0' checked={filterSettings.showGroup0} onChange={showGroup0} label='General purpose' />
                    <Form.Check type='checkbox' id='filter_group_1' checked={filterSettings.showGroup1} onChange={showGroup1} label='Mounts' />
                    <Form.Check type='checkbox' id='filter_group_2' checked={filterSettings.showGroup2} onChange={showGroup2} label='Casts and Clear hip circles' />
                    <Form.Check type='checkbox' id='filter_group_3' checked={filterSettings.showGroup3} onChange={showGroup3} label='Giant circles' />
                    <Form.Check type='checkbox' id='filter_group_4' checked={filterSettings.showGroup4} onChange={showGroup4} label='Stalder circles' />
                    <Form.Check type='checkbox' id='filter_group_5' checked={filterSettings.showGroup5} onChange={showGroup5} label='Pike circles' />
                    <Form.Check type='checkbox' id='filter_group_6' checked={filterSettings.showGroup6} onChange={showGroup6} label='Dismounts' />
                    <Form.Check type='checkbox' id='filter_group_all' checked={filterSettings.showGroupAll} onChange={showGroupAll} label='All' />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Difficulty Value (select CoP version above):</Form.Label>
                    <Form.Row>
                        <Col>
                            <Form.Check type='checkbox' id='filter_dv_none' checked={filterSettings.showDvNone} onChange={showDvNone} label='No DV' />
                            <Form.Check type='checkbox' id='filter_dv_a' checked={filterSettings.showDvA} onChange={showDvA} label='A' />
                            <Form.Check type='checkbox' id='filter_dv_b' checked={filterSettings.showDvB} onChange={showDvB} label='B' />
                        </Col>
                        <Col>
                            <Form.Check type='checkbox' id='filter_dv_c' checked={filterSettings.showDvC} onChange={showDvC} label='C' />
                            <Form.Check type='checkbox' id='filter_dv_d' checked={filterSettings.showDvD} onChange={showDvD} label='D' />
                            <Form.Check type='checkbox' id='filter_dv_e' checked={filterSettings.showDvE} onChange={showDvE} label='E' />
                        </Col>
                        <Col>
                            <Form.Check type='checkbox' id='filter_dv_f' checked={filterSettings.showDvF} onChange={showDvF} label='F' />
                            <Form.Check type='checkbox' id='filter_dv_g' checked={filterSettings.showDvG} onChange={showDvG} label='G' />
                            <Form.Check type='checkbox' id='filter_dv_all' checked={filterSettings.showDvAll} onChange={showDvAll} label='All' />
                        </Col>
                    </Form.Row>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Type of element:</Form.Label>
                    <Form.Row>
                        <Col>
                            <Form.Check type='checkbox' id='filter_type_kip' checked={filterSettings.showTypeKip} onChange={showTypeKip} label='Kip' />
                            <Form.Check type='checkbox' id='filter_type_cast' checked={filterSettings.showTypeCast} onChange={showTypeCast} label='Cast to handstand' />
                            <Form.Check type='checkbox' id='filter_type_clear_hip' checked={filterSettings.showTypeClearHip} onChange={showTypeClearHip} label='Clear hip circle' />
                            <Form.Check type='checkbox' id='filter_type_giant' checked={filterSettings.showTypeGiant} onChange={showTypeGiant} label='Giant' />
                            <Form.Check type='checkbox' id='filter_type_stalder' checked={filterSettings.showTypeStalder} onChange={showTypeStalder} label='Stalder' />
                        </Col>
                        <Col>
                            <Form.Check type='checkbox' id='filter_type_pike' checked={filterSettings.showTypePike} onChange={showTypePike} label='Pike circle' />
                            <Form.Check type='checkbox' id='filter_type_shapo' checked={filterSettings.showTypeShapo} onChange={showTypeShapo} label='Shaposnikova' />
                            <Form.Check type='checkbox' id='filter_type_tkatchev' checked={filterSettings.showTypeTkatchev} onChange={showTypeTkatchev} label='Tkatchev' />
                            <Form.Check type='checkbox' id='filter_type_all' checked={filterSettings.showTypeAll} onChange={showTypeAll} label='All' />
                        </Col>
                    </Form.Row>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Root skill entry:</Form.Label>
                    <Form.Row>
                        <Col>
                            <Form.Check type='checkbox' id='root_skill_clear_hip_bwd' checked={filterSettings.showRootClearHipBwd} onChange={showRootClearHipBwd} style={wagStyle} label='' />
                            <Form.Check type='checkbox' id='root_skill_clear_hip_fwd' checked={filterSettings.showRootClearHipFwd} onChange={showRootClearHipFwd} style={wagStyle} label='' />
                            <Form.Check type='checkbox' id='root_skill_stalder_bwd' checked={filterSettings.showRootStalderHipBwd} onChange={showRootStalderHipBwd} style={wagStyle} label='' />
                            <Form.Check type='checkbox' id='root_skill_stalder_fwd' checked={filterSettings.showRootStalderHipFwd} onChange={showRootStalderHipFwd} style={wagStyle} label='' />
                            <Form.Check type='checkbox' id='root_skill_sole_circle_bwd' checked={filterSettings.showRootSoleCircleBwd} onChange={showRootSoleCircleBwd} style={wagStyle} label='' />
                            <Form.Check type='checkbox' id='root_skill_sole_circle_fwd' checked={filterSettings.showRootSoleCircleFwd} onChange={showRootSoleCircleFwd} style={wagStyle} label='' />
                        </Col>
                        <Col>
                            <Form.Check type='checkbox' id='root_skill_clear_pike_bwd' checked={filterSettings.showRootClearPikeBwd} onChange={showRootClearPikeBwd} style={wagStyle} label='' />
                            <Form.Check type='checkbox' id='root_skill_clear_pike_fwd' checked={filterSettings.showRootClearPikeFwd} onChange={showRootClearPikeFwd} style={wagStyle} label='' />
                            <Form.Check type='checkbox' id='root_skill_adler_bwd' checked={filterSettings.showRootAdlerBwd} onChange={showRootAdlerBwd} style={wagStyle} label='' />
                            <Form.Check type='checkbox' id='root_skill_adler_fwd' checked={filterSettings.showRootAdlerFwd} onChange={showRootAdlerFwd} style={wagStyle} label='' />
                            <Form.Check type='checkbox' id='root_skill_all' checked={filterSettings.showRootAll} onChange={showRootAll} label='All' />
                        </Col>
                    </Form.Row>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Rotation and Direction:</Form.Label>
                    <Form.Row>
                        <Col>
                            <Form.Check type='checkbox' id='filter_rotation_0' checked={filterSettings.showRotation0} onChange={showRotation0} label='None' />
                            <Form.Check type='checkbox' id='filter_rotation_180' checked={filterSettings.showRotation180} onChange={showRotation180} label='1/2 Turn (180°)' />
                            <Form.Check type='checkbox' id='filter_rotation_360' checked={filterSettings.showRotation360} onChange={showRotation360} label='1/1 Turn (360°)' />
                            <Form.Check type='checkbox' id='filter_rotation_540' checked={filterSettings.showRotation540} onChange={showRotation540} label='1 1/2 Turn (540°)' />
                            <Form.Check type='checkbox' id='filter_rotation_720' checked={filterSettings.showRotation720} onChange={showRotation720} label='2/1 Turn (720°)' />
                        </Col>
                        <Col>
                            <Form.Check type='checkbox' id='filter_rotation_900' checked={filterSettings.showRotation900} onChange={showRotation900} label='2 1/2 Turn (900°)' />
                            <Form.Check type='checkbox' id='filter_rotation_1080' checked={filterSettings.showRotation1080} onChange={showRotation1080} label='3/1 Turn (1080°)' />
                            <Form.Check type='checkbox' id='filter_forward' checked={filterSettings.showForward} onChange={showForward} label='Forward' />
                            <Form.Check type='checkbox' id='filter_backward' checked={filterSettings.showBackward} onChange={showBackward} label='Backward' />
                            <Form.Check type='checkbox' id='filter_rotation_all' checked={filterSettings.showRotationAll} onChange={showRotationAll} label='All' />
                        </Col>
                    </Form.Row>
                </Form.Group>
            </Form>
        </>
    );
}

export default UbLibFilter;