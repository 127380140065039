export function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

export function groupFromNumber(n) {
    if(!n) {
        return 0;
    }

    const firstChar = n.substring(0, 1);
    const firstDigit = parseFloat(firstChar);

    return firstDigit;
}

export function rowNumberFromNumber(n) {
    if(!n) {
        return 0;
    }

    const firstChar = n.substring(2, 3); 
    const firstDigit = parseFloat(firstChar);

    return firstDigit;
}