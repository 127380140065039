import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useWebUser from '../Services/WebUser/useWebUser';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import GsShopClient from '../Services/GsShop/GsShopClient';

const Products = () => {
    const baseUrlGsShopApi = process.env.REACT_APP_API_BASE_URL;
    const [error, setError] = useState(null);
    const [showLogonAlert, setShowLogonAlert] = useState(true);
    const { shoppingCart, dispatch } = useWebUser();

    // Get the client for the WebUser API
    const [client] = useState(
        () => new GsShopClient(baseUrlGsShopApi)
    );

    // Authentication stuff
    const {
        getAccessTokenSilently,
        isAuthenticated,
    } = useAuth0();

    // The access token
    const [accessToken, setAccessToken] = useState(null);

    // React to changes in the authenticated state in Auth0
    useEffect(
        () => {
            async function getAccessToken() {
                const token = await getAccessTokenSilently();
                setAccessToken(token);
            }

            if (isAuthenticated) {
                getAccessToken();
            }
            else {
                setAccessToken(null);
            }

        },
        [isAuthenticated, getAccessTokenSilently]
    );

    // Call this method to buy a package
    const buyPackage = (packageCode) => {
        try {
            let cart = shoppingCart ? shoppingCart : {};
            let items = cart.items ? cart.items : [];
            items.push(packageCode);
            cart.items = items;

            dispatch({
                type: 'UPDATE_SHOPPING_CART',
                shoppingCart: cart
            });

            (async () => {
                await client.AddNewPackage(accessToken, packageCode);
            })();
        }
        catch (e) {
            setError(e);
        }
    }

    if (error)
        throw new Error(Error);

    return (
        <Container fluid>
            <Row><Col><h1>Products</h1></Col></Row>
            {!isAuthenticated && showLogonAlert && <Alert variant="warning" onClose={() => setShowLogonAlert(false)} dismissible>You must logon or create a new account before you can buy a subscription.</Alert>}
            <Row>
                <Col>
                    <Container>
                        <Row>
                            <h2>For Individuals</h2>
                        </Row>
                        <Row>
                            <Col>
                                <h2>Free FIG WAG Code of Points - Online Editor</h2>
                                <p>The online editor is free to use but ihen it is limited to only A-B-C elements in the CoP. Only for Vault there is no difference between the free version and the licensed version.</p>
                                <p>The online editor will calculate every exercise, except when it contains higher than C elements, then it will report that the free version cannot calculate the exercise.</p>
                            </Col>
                            <Col><h2>Free</h2></Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h2>1-Year Subscription for FIG WAG Code of Points - Online Editor</h2>
                                <p>Gives you one year access to the current version of the Code of Points. You will have access to the latest version of the CoP.</p>
                            </Col>
                            <Col><h2>€10/year</h2></Col>
                            <Col>
                                {isAuthenticated && <Button onClick={() => buyPackage("gs_wag_web_one_year")}>Buy 1-Year Subscription</Button>}
                                {!isAuthenticated && <Button onClick={() => buyPackage("gs_wag_web_one_year")} disabled>Buy 1-Year Subscription</Button>}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h2>4-Year Subscription for FIG WAG Code of Points - Online Editor</h2>
                                <p>Gives you four years access to the current version of the Code of Points. You will have access to the latest version of the CoP.</p>
                            </Col>
                            <Col><h2>€40/4 years</h2></Col>
                            <Col>
                                {isAuthenticated && <Button onClick={() => buyPackage("gs_wag_web_four_years")}>Buy 4-Year Subscription</Button>}
                                {!isAuthenticated && <Button onClick={() => buyPackage("gs_wag_web_four_years")} disabled>Buy 1-Year Subscription</Button>}
                            </Col>
                        </Row>                    </Container>
                </Col>
                <Col>
                    <Container>
                        <Row>
                            <h2>For Federations</h2>
                        </Row>
                        <Row>
                            <Col>
                                <h2>Bulk license for judge course</h2>
                                <p>You can buy a license that is limited in time and that can be distributed to participants of a course.</p>
                                <p>Upon purchase you will receive a unique code that you then distribute to the participants of the course. They need to create their personal account and redeem the code.</p>
                            </Col>
                            <Col><h2>€1 per week per user</h2></Col>
                            <Col>
                                {isAuthenticated && <Button onClick={() => buyPackage("gs_wag_web_one_year")}>Buy Judge Course License</Button>}
                                {!isAuthenticated && <Button onClick={() => buyPackage("gs_wag_web_one_year")} disabled>Buy Judge Course License</Button>}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h2>Custom Code of Points</h2>
                                <p>Every federation has its own program which has different levels of difficulty and a different set of rules.</p>
                                <p>Just like the FIG code of points, these custom programs can also be developed for use with the online WAG editor.</p>
                            </Col>
                            <Col><h2>Contact us for pricing</h2></Col>
                            <Col>
                                <Button>Contact us</Button>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default Products;